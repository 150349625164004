import { Chip } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ISubUserResult } from 'src/types/user';
import { fDate } from 'src/utils/format-time';





// ----------------------------------------------------------------------

type Props = {
  selected: boolean;
  onEditRow: VoidFunction;
  row: ISubUserResult;
  id: any
};

export default function SubUserTableRow({
  row,
  selected,
  onEditRow,
  id
}: Props) {


  const renderRoles = () => {
    const roles = row.role?.roles;
    if (!roles) return "";

    if (roles.length <= 2) {
      return roles.map((role: { name: string }, index: number) => (
        <Chip key={index} sx={{ mr: 0.5 }} size='small' variant='filled' color='primary' label={role.name} />
      ));
    }
    // Display the first two roles and indicate the remaining count
    return (
      <>
        {roles.slice(0, 2).map((role: { name: string }, index: number) => (
          <Chip sx={{ mr: 0.5 }} key={index} size='small' variant='filled' color='primary' label={role.name} />
        ))}
        <Chip
          size='small'
          variant='soft'
          color={roles.length > 3 ? 'secondary' : 'primary'}
          label={`+${roles.length - 2}`}
        />
      </>
    );

  };

  return (
    <TableRow hover selected={selected} >
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}

      <TableCell
        sx={{
          whiteSpace: 'nowrap', cursor: 'pointer', textDecoration: 'underline'
        }}
        onClick={onEditRow}>
        {row?.user?.phone_number ? row?.user?.phone_number.slice(4) : ""}
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {row.user.name}
      </TableCell>


      <TableCell sx={{ whiteSpace: 'nowrap' }}>

        {renderRoles()}

      </TableCell>

      {"created_at" in row &&
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {fDate(row.created_at)}
        </TableCell>
      }

      {"updated_at" in row &&
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {fDate(row.updated_at)}
        </TableCell>
      }



    </TableRow>
  );
}
