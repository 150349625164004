import { useMemo } from "react";
import { ITransactionApiResponse } from "src/types/transaction";
import { endpoints, fetcher } from "src/utils/axios";
import useSWR from "swr";
// get transaction list with URLSearchParams for query parameters
export function useGetTransactions(
    page: number,
    page_size: number,
    ordering: "asc" | "desc",
    search: string | undefined
) {
    const URL = endpoints.transactions.list;
    const queryParams = new URLSearchParams({
        page: page.toString(),
        page_size: page_size.toString(),
        ordering
    });

    if (search && search.length > 0) {
        queryParams.set('search', search);
    }

    const URL_WITH_PARAMS = `${URL}?${queryParams.toString()}`;

    const { data, isLoading, error, isValidating } = useSWR(URL_WITH_PARAMS, fetcher);

    const memoizedValue = useMemo(
        () => ({
            transactions: data as ITransactionApiResponse,
            transactionsLoading: isLoading,
            transactionsError: error,
            transactionsValidating: isValidating,
            transactionsEmpty: !isLoading && !data?.length,
        }),
        [data, error, isLoading, isValidating]
    );

    return memoizedValue;
}
