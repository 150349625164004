
import { Button } from '@mui/material';
import Container from '@mui/material/Container';
import { useGetMyRestaurants } from 'src/api/restaurant';
import { RoleBasedGuard } from 'src/auth/guard';
import PermissionBasedGuard from 'src/auth/guard/permission-based-guard';
import { useAuthContext } from 'src/auth/hooks';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import RestaurantPlansListView from './restaurant-plans-list-view';


const TABLES = {
    plans: {
        id: "plan",
        header: [
            { id: 'title', label: 'Plan', alignRight: true },
            { id: 'subscriptions', label: 'Subscriptions', width: 180 },
            { id: 'activeSubscriptions', label: 'Active Subscriptions', width: 220 },
            { id: 'meals', label: 'Meals', width: 180 },
            { id: 'packages', label: 'Packages' },
            { id: 'action', label: '',   alignRight: false},
        ],
        data: [
            { id: '1', href: "edit-plan/1", title: 'Extreme Plan', subscriptions: '2', activeSubscriptions: '44', meals: 'meals (3)', packages: 'packages (0)' },
            { id: '2', href: "edit-plan/2", title: 'Normal Plan', subscriptions: '32', activeSubscriptions: '34', meals: 'meals (3)', packages: 'packages (0)' },
        ]
    },
}

// ----------------------------------------------------------------------

export default function RestaurantPlanPageView() {
    const { user } = useAuthContext();

    const { restaurants, restaurantsLoading } = useGetMyRestaurants();

    if (restaurantsLoading) {
        return <div>Loading...</div>
    }
    return (
        <RoleBasedGuard hasContent roles={["Subadmin", "Sub-User"]} sx={{ py: 10 }}>

            <PermissionBasedGuard permissions={['44']} hasContent>


                <Container maxWidth={false}>

                    <CustomBreadcrumbs
                        heading='Plans'
                        sx={{ mb: 8 }}
                        links={[
                            {},

                        ]}
                        action={
                            ((user?.type === "Sub-User") || (user?.is_allowed_to_make_changes && restaurants.length > 0 && restaurants[0].id)) && (

                                <PermissionBasedGuard permissions={['22']}>

                                    <Button
                                        component={RouterLink}
                                        href={paths.dashboard.restaurant.plan.add(restaurants[0]?.id)}
                                        variant="contained"
                                        startIcon={<Iconify icon="mingcute:add-line" />}
                                    >
                                        Add Plan
                                    </Button>

                                </PermissionBasedGuard>

                            )
                        }
                    />


                    <RestaurantPlansListView
                        id={restaurants[0]?.id}
                        tableConfig={TABLES.plans}
                    />

                </Container>

            </PermissionBasedGuard>

        </RoleBasedGuard>
    );
}
