import 'src/global.css';

// ----------------------------------------------------------------------

import Router from 'src/routes/sections';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

import ThemeProvider from 'src/theme';

// eslint-disable-next-line import/no-extraneous-dependencies
import { LicenseInfo } from '@mui/x-license-pro';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import ProgressBar from 'src/components/progress-bar';
import { SettingsDrawer, SettingsProvider } from 'src/components/settings';

import { AuthProvider } from 'src/auth/context/jwt';
import { SnackbarProvider } from './components/snackbar';
import { LocalizationProvider } from './locales';

// ----------------------------------------------------------------------

const { VITE_LICENSE_KEY } = import.meta.env;
LicenseInfo.setLicenseKey(VITE_LICENSE_KEY);


export default function App() {

  // useEffect(() => {
  //   // Function to clear Local Storage
  //   const clearLocalStorage = () => {
  //     localStorage.removeItem('userFilters');
  //     localStorage.removeItem('userPagination');
  //   };

  //   // Add event listener for beforeunload
  //   window.addEventListener('beforeunload', clearLocalStorage);

  //   // Cleanup function to remove the event listener
  //   return () => {
  //     window.removeEventListener('beforeunload', clearLocalStorage);
  //   };
  // }, []);

  useScrollToTop();
  return (
    <AuthProvider>
      <LocalizationProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <SettingsDrawer />
                <ProgressBar />
                <Router />
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
}
