import { useCallback, useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';

import { useBoolean } from 'src/hooks/use-boolean';

import { ConfirmDialog } from 'src/components/custom-dialog';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {
  getComparator,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  TableSkeleton,
  useTable,
} from 'src/components/table';

import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router';
import { deleteRestaurantFood, useGetRestaurantFoods } from 'src/api/restaurant';
import { useAuthContext } from 'src/auth/hooks';
import { paths } from 'src/routes/paths';
import {
  IRestaurantFoodCard1,
  IRestaurantTableFilters,
  IRestaurantTableFilterValue,
} from 'src/types/user';
import RestaurantTableRow from '../restaurant-table-row';
import RestaurantTableToolbar from '../restaurant-table-toolbar';

// ----------------------------------------------------------------------
const defaultFilters: IRestaurantTableFilters = {
  title: '',
  is_available: false,
};

// ----------------------------------------------------------------------

type TableProps = {
  tableConfig: TableConfig;
  id: string | undefined;
};

export default function RestaurantFoodsListView({ tableConfig, id }: TableProps) {
  const table = useTable({
    defaultRowsPerPage: 25,
    defaultOrder: 'asc',
  });

  const [filters, setFilters] = useState(defaultFilters);

  const [pagination, setPagination] = useState({
    page: table.page,
    rowsPerPage: table.rowsPerPage,
  });

  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [isDeleteProcessing, setIsDeleteProcessing] = useState(false);

  const { restaurantFoods, restaurantFoodsLoading } = useGetRestaurantFoods(
    id,
    pagination.page + 1,
    pagination.rowsPerPage,
    filters.title
  );

  const confirm = useBoolean();

  const [tableData, setTableData] = useState(restaurantFoods?.results || []);

  const hasAllPermissions = ['22'].every((permission) => user?.permissionIds?.includes(permission));

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(0, table.rowsPerPage);

  const denseHeight = table.dense ? 52 : 72;

  const canReset = true;

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleDeleteRow = useCallback(
    async (uid: number) => {
      await deleteRestaurantFood(`${uid}`);
      const deleteRow = tableData.filter((row) => row.id !== uid);
      setTableData(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table, tableData]
  );

  const handleDeleteRows = useCallback(async () => {
    setIsDeleteProcessing(true);
    const deleteRows = tableData.filter(async (row) => {
      if (table.selected.includes(`${row.id}`)) {
        await deleteRestaurantFood(`${row.id}`);
      }
      return !table.selected.includes(`${row.id}`);
    });
    setTableData(deleteRows);

    table.onUpdatePageDeleteRows({
      totalRows: tableData.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    });

    setIsDeleteProcessing(false);
  }, [dataFiltered.length, dataInPage.length, table, tableData]);

  const handleEditRow = useCallback(
    (uid: number) => {
      if (user?.type !== 'Sub-User') {
        navigate(paths.dashboard.restaurant.food.edit(uid.toString()));
      } else if (hasAllPermissions) {
        navigate(paths.dashboard.restaurant.food.edit(uid.toString()));
      }
    },
    [user?.type, hasAllPermissions, navigate]
  );

  const handleFilters = useCallback(
    (name: string, value: IRestaurantTableFilterValue) => {
      const newFilters = { ...filters, [name]: value };
      setFilters(newFilters);

      const newPagination = { ...pagination, page: 0 }; // Assuming your page indexing starts at 0
      setPagination(newPagination);
      table.setPage(0);

      localStorage.setItem('restaurantFoodFilters', JSON.stringify(newFilters));
      // Reset pagination in localStorage when filters change
      localStorage.setItem('restaurantFoodPagination', JSON.stringify(newPagination));
    },
    [filters, pagination, table]
  );

  const handlePageChange = useCallback(
    (event: any, newPage: number) => {
      table.setPage(newPage);
      const newPagination = { ...pagination, page: newPage };
      setPagination(newPagination);
      localStorage.setItem('restaurantFoodPagination', JSON.stringify(newPagination));
    },
    [pagination, table]
  );

  const handleRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      table.setRowsPerPage(newRowsPerPage);
      const newPagination = { ...pagination, page: 0, rowsPerPage: newRowsPerPage };
      setPagination(newPagination);
      localStorage.setItem('restaurantFoodPagination', JSON.stringify(newPagination));
    },
    [pagination, table]
  );

  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem('restaurantFoodFilters') || '{}');
    const savedPagination = JSON.parse(localStorage.getItem('restaurantFoodPagination') || '{}');

    if (Object.keys(savedFilters).length > 0) {
      setFilters(savedFilters);
    }

    if (savedPagination.page !== undefined && savedPagination.rowsPerPage !== undefined) {
      setPagination(savedPagination);
      table.setPage(savedPagination.page);
      table.setRowsPerPage(savedPagination.rowsPerPage);
    }
  }, [table]);

  useEffect(() => {
    if (restaurantFoods?.results && !restaurantFoodsLoading) {
      setTableData(restaurantFoods?.results);
    }
  }, [restaurantFoods, restaurantFoodsLoading]);

  return (
    <>
      <Container maxWidth={false}>
        <Card>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <RestaurantTableToolbar filters={filters} onFilters={handleFilters} />

            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id.toString())
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 500 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={tableConfig.header}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                />

                <TableBody>
                  {restaurantFoodsLoading
                    ? [...Array(table.rowsPerPage)].map((i, index) => (
                        <TableSkeleton key={index} sx={{ height: denseHeight }} />
                      ))
                    : dataFiltered
                        .slice(0, table.rowsPerPage)
                        .map((row) => (
                          <RestaurantTableRow
                            table={tableConfig.id}
                            key={row.id}
                            row={row as any}
                            id={row.id}
                            selected={table.selected.includes(row.id.toString())}
                            onDeleteRow={() => handleDeleteRow(row.id)}
                            onEditRow={() => handleEditRow(row.id)}
                          />
                        ))}

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={restaurantFoods?.count || 0}
            page={pagination.page}
            rowsPerPage={pagination.rowsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <LoadingButton
            loading={isDeleteProcessing}
            startIcon={<Iconify icon="mdi:trash-can-outline" />}
            variant="contained"
            color="error"
            onClick={async () => {
              await handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
}: {
  inputData: IRestaurantFoodCard1[];
  comparator: (a: any, b: any) => number;
  filters: IRestaurantTableFilters;
}) {
  const stabilizedThis = inputData?.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  return inputData;
}
