import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';


import { useBoolean } from 'src/hooks/use-boolean';


import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { ConfirmDialog } from 'src/components/custom-dialog';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {
  getComparator,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  TableSkeleton,
  useTable,
} from 'src/components/table';

import { ISubAdminResult, IUserTableFilters, IUserTableFilterValue } from 'src/types/user';

import { useNavigate } from 'react-router';
import { useGetCustomers } from 'src/api/customer';
import { RoleBasedGuard } from 'src/auth/guard';
import PermissionBasedGuard from 'src/auth/guard/permission-based-guard';
import { useAuthContext } from 'src/auth/hooks';
import { paths } from 'src/routes/paths';
import UserTableRow from '../customer-table-row';
import UserTableToolbar from '../customer-table-toolbar';

// ----------------------------------------------------------------------

const CUSTOMERS_TYPE_OPTIONS__SUPER_ADMIN_VIEW = [
  { value: 'all', label: 'All' },
  { value: 'subscribed', label: 'Subscribed' },
  { value: 'normal', label: 'Normal' },
];

const CUSTOMERS_STATUS_OPTIONS__SUB_ADMIN_VIEW = [
  { value: 'all', label: 'All' },
  { value: 'old', label: 'Old' },
  { value: 'active', label: 'Active' },
];


const TABLE_HEAD_SUPER_ADMIN = [
  { id: 'phoneNumber', label: 'Phone', width: 180 },
  { id: 'name', label: 'Name', width: 220 },
  { id: 'type', label: 'Type', width: 180 },
  { id: 'subscription', label: 'Subscription' },
  { id: 'restaurant', label: 'Restaurant' },
  { id: 'comment', label: 'Comment' },
];


const TABLE_HEAD_SUB_ADMIN = [
  { id: 'phoneNumber', label: 'Phone', width: 180 },
  { id: 'name', label: 'Name', width: 220 },
  { id: 'status', label: 'Status', width: 180 },
  { id: 'plan', label: 'Plan' },
  { id: 'period', label: 'Period' },
  { id: 'start_date', label: 'Start Date' },
  { id: 'end_date', label: 'End Date' },
];


const defaultFilters: IUserTableFilters = {
  name: "",
  status: 'all',
};

// ----------------------------------------------------------------------

export default function CustomerAdminListView() {
  const table = useTable({
    defaultRowsPerPage: 25,
    defaultOrder: 'asc',
    defaultOrderBy: 'phoneNumber'
  });

  const [filters, setFilters] = useState(defaultFilters);

  const { user } = useAuthContext();
  const [pagination, setPagination] = useState({ page: table.page, rowsPerPage: table.rowsPerPage });


  const { customers, customersLoading } = useGetCustomers(pagination.page + 1, pagination.rowsPerPage, filters.name, user?.type === "Superuser" ? filters.status : undefined, (user?.type === "Subadmin" || user?.type === "Sub-User") ? filters.status : undefined);

  const navigate = useNavigate();
  const confirm = useBoolean();

  const [tableData, setTableData] = useState(customers?.results || { results: [] });


  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    0,
    table.rowsPerPage
  );

  const TABLE_HEAD = user?.type === "Superuser" ? TABLE_HEAD_SUPER_ADMIN : TABLE_HEAD_SUB_ADMIN;
  const FILTER_OPTIONS = user?.type === "Superuser" ? CUSTOMERS_TYPE_OPTIONS__SUPER_ADMIN_VIEW : CUSTOMERS_STATUS_OPTIONS__SUB_ADMIN_VIEW;


  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback((name: string, value: IUserTableFilterValue) => {
    const newFilters = { ...filters, [name]: value };
    setFilters(newFilters);

    // Reset pagination to the first page
    const newPagination = { ...pagination, page: 0 }; // Assuming your page indexing starts at 0
    setPagination(newPagination);
    table.setPage(0);

    // Save the updated filters and pagination state to local storage
    localStorage.setItem('customerFilters', JSON.stringify(newFilters));
    localStorage.setItem('customerPagination', JSON.stringify(newPagination));
  }, [filters, pagination, table]);


  const handlePageChange = useCallback((event: any, newPage: number) => {
    table.setPage(newPage);
    const newPagination = { ...pagination, page: newPage };
    setPagination(newPagination);
    localStorage.setItem('customerPagination', JSON.stringify(newPagination));
  }, [pagination, table]);

  const handleRowsPerPageChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    table.setRowsPerPage(newRowsPerPage);
    const newPagination = { ...pagination, rowsPerPage: newRowsPerPage };
    setPagination(newPagination);
    localStorage.setItem('customerPagination', JSON.stringify(newPagination));
  }, [pagination, table]);

  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem('customerFilters') || '{}');
    const savedPagination = JSON.parse(localStorage.getItem('customerPagination') || '{}');

    if (Object.keys(savedFilters).length > 0) {
      setFilters(savedFilters);
    }

    if (savedPagination.page !== undefined && savedPagination.rowsPerPage !== undefined) {
      setPagination(savedPagination);
      table.setPage(savedPagination.page);
      table.setRowsPerPage(savedPagination.rowsPerPage);
    }
  }, [table]);



  const handleDeleteRow = useCallback(
    (id: string) => {
      const deleteRow = tableData.filter((row: any) => row.id.toString() !== id);
      setTableData(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table, tableData]
  );

  const handleDeleteRows = useCallback(() => {
    const deleteRows = tableData.filter((row: any) => !table.selected.includes(row.id.toString()));
    setTableData(deleteRows);

    table.onUpdatePageDeleteRows({
      totalRows: customers?.count || 0,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    });
  }, [customers?.count, dataFiltered.length, dataInPage.length, table, tableData]);

  const handleEditRow = useCallback(
    (row: any) => {
      if (row) {
        navigate(paths.dashboard.customers.profile(row.id));
      }

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );


  useEffect(() => {
    if (customers?.results && !customersLoading) {
      setTableData(customers?.results);
    }
  }, [customers?.results, customersLoading]);

  return (
    <RoleBasedGuard hasContent roles={["Subadmin", "Superuser", "Sub-User"]} sx={{ py: 10 }}>
      <PermissionBasedGuard permissions={['41']} hasContent>

        <Container maxWidth={false}>
          <CustomBreadcrumbs
            heading="Customers"
            links={[
              {},
            ]}

            sx={{
              mb: { xs: 3, md: 5 },
            }}
          />

          <Card>
            <Tabs
              value={filters.status}
              onChange={handleFilterStatus}
              sx={{
                px: 2.5,
                boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
              }}
            >
              {FILTER_OPTIONS.map((tab) => (
                <Tab
                  key={tab.value}
                  iconPosition="end"
                  value={tab.value}
                  label={tab.label}
                />
              ))}
            </Tabs>

            <UserTableToolbar
              filters={filters}
              onFilters={handleFilters}
              roleOptions={[]}
            />

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <TableSelectedAction
                dense={table.dense}
                numSelected={table.selected.length}
                rowCount={tableData.length}
                onSelectAllRows={(checked) =>
                  table.onSelectAllRows(
                    checked,
                    tableData.map((row: any) => row.id.toString())
                  )
                }
                action={
                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={confirm.onTrue}>
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                }
              />

              <Scrollbar>
                <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData.length}
                    numSelected={table.selected.length}
                    onSort={table.onSort}

                  />


                  <TableBody>
                    {
                      customersLoading
                        ?
                        (
                          [...Array(table.rowsPerPage)].map((i, index) => (
                            <TableSkeleton key={index} sx={{ height: denseHeight }} />
                          ))
                        )
                        :
                        dataFiltered
                          .slice(
                            0,
                            table.rowsPerPage
                          )
                          .map((row) => (
                            <UserTableRow
                              key={row.id}
                              row={row}
                              id={row.id}
                              selected={table.selected.includes(row.id.toString())}
                              onSelectRow={() => table.onSelectRow(row.id.toString())}
                              onDeleteRow={() => handleDeleteRow(row.id.toString())}
                              onEditRow={() => handleEditRow(row)}
                            />
                          ))}
                    <TableNoData notFound={notFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={customers?.count || 0}
              page={pagination.page}
              rowsPerPage={pagination.rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              dense={table.dense}
              onChangeDense={table.onChangeDense}
            />
          </Card>
        </Container>

        <ConfirmDialog
          open={confirm.value}
          onClose={confirm.onFalse}
          title="Delete"
          content={
            <>
              Are you sure want to delete <strong> {table.selected.length} </strong> items?
            </>
          }
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleDeleteRows();
                confirm.onFalse();
              }}
            >
              Delete
            </Button>
          }
        />

      </PermissionBasedGuard>
    </RoleBasedGuard>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
}: {
  inputData: ISubAdminResult[];
  comparator: (a: any, b: any) => number;
  filters: IUserTableFilters;
}) {

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);




  return inputData;
}
