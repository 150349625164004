import * as Sentry from "@sentry/react";
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ENVIROMENT, SENTRY_DSN } from 'src/config-global';
import App from './app';


// Possible Environments 
// const DEVELOPMENT = 'development';
const STAGING = 'staging';
const PRODUCTION = 'production';
const DEMO = 'demo';

const possibleEnvironments = [STAGING, PRODUCTION, DEMO ]; // Add DEVELOPMENT to the array if you want to test Sentry in development


if (ENVIROMENT && possibleEnvironments.includes(ENVIROMENT)) {
  // Initialize Sentry
  Sentry.init({
      dsn: SENTRY_DSN,
      environment: ENVIROMENT,
      integrations: [
          Sentry.replayIntegration({
              maskAllText: false,
              blockAllMedia: false,
          }),
      ],
      // Performance Monitoring
      tracesSampleRate: ENVIROMENT === PRODUCTION ? 0.1 : 0.05,
      // Session Replay
      replaysSessionSampleRate: 1.0, 
      replaysOnErrorSampleRate: 1.0, 
  });
}

// ----------------------------------------------------------------------
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
