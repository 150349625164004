import { Button, Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import RestaurantAccountGeneral from 'src/sections/restaurant/restaurant-account-general';


// ----------------------------------------------------------------------

export default function RestaurantCreate() {

    return (
        <>
            <Helmet>
                <title> Dashboard: Restaurant Create</title>
            </Helmet>

            <Container>

                <Button
                    size='small'
                    variant='soft'
                    component={RouterLink}
                    href={paths.dashboard.restaurants}
                    startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
                >
                    Back to Restaurants List
                </Button>

                <Typography variant="h4" gutterBottom sx={{ mt: 2 }}>
                    Create Restaurant
                </Typography>



                {/* <Stack
                spacing={1.5}
                direction="row"

            >
                <Button
                    component={RouterLink}
                    href={paths.dashboard.restaurants}
                    startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
                >
                    Back to List
                </Button>

                <Box sx={{ flexGrow: 1 }} />

            </Stack> */}





                <RestaurantAccountGeneral id={undefined} />


            </Container>

        </>
    );
}
