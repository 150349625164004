import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useAuthContext } from 'src/auth/hooks';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import {
  PATH_AFTER_LOGIN,
  PATH_AFTER_LOGIN_SUBUSER,
  PHONE_REGEX_EXPRESSION,
} from 'src/config-global';
import { useBoolean } from 'src/hooks/use-boolean';
import { useRouter, useSearchParams } from 'src/routes/hooks';

import { convertArabicToWestern } from 'src/utils/arabic-to-western';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
export default function JwtLoginView() {
  const { login } = useAuthContext();

  const router = useRouter();

  const [errorMsg, setErrorMsg] = useState('');

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const password = useBoolean();

  const LoginSchema = Yup.object().shape({
    phone: Yup.string()
      .required('Phone number is required')
      .matches(PHONE_REGEX_EXPRESSION, 'Invalid phone number')
      .min(8, 'Phone number is too short')
      .max(8, 'Phone number is too long'),

    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    phone: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const watchedPhone = watch('phone');

  useEffect(() => {
    if (watchedPhone) {
      const convertedPhone = convertArabicToWestern(watchedPhone);
      if (watchedPhone !== convertedPhone) {
        setValue('phone', convertedPhone);
      }
    }
  }, [watchedPhone, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await login(data.phone, data.password);
      router.push(
        response && response?.toLowerCase() === 'sub-user'
          ? PATH_AFTER_LOGIN_SUBUSER
          : returnTo || PATH_AFTER_LOGIN
      );
    } catch (error) {
      console.error(error);
      reset({ phone: data.phone, password: '' });
      setErrorMsg(typeof error === 'string' ? error : error.detail);
    }
  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5, mt: 10 }}>
      <Typography variant="h4">Sign in to Eshtarakat</Typography>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

      <RHFTextField autoComplete="" type="tel" name="phone" label="Phone Number" />

      <RHFTextField
        name="password"
        label="Password"
        autoComplete=""
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {renderForm}
    </FormProvider>
  );
}
