import Container from '@mui/material/Container';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  getRestaurantDeliveryTime,
  updateRestaurantDeliveryTimeSubadmin,
  useGetMyRestaurants,
} from 'src/api/restaurant';
import { RoleBasedGuard } from 'src/auth/guard';
import { useAuthContext } from 'src/auth/hooks';

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { RHFSwitch, RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import Iconify from 'src/components/iconify';
import { fData } from 'src/utils/format-number';
import * as Yup from 'yup';

interface DeliveryTimeRange {
  id: number;
  start_time: string;
  end_time: string;
}

export default function RestaurantProfilePageView() {
  const { user } = useAuthContext();

  const { restaurants, restaurantsLoading } = useGetMyRestaurants();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingDeliveryTime, setLoadingDeliveryTime] = useState(false);

  const UpdatePasswordSchema = Yup.object({
    contact_number: Yup.string(),
    isEdittable: Yup.boolean(),
    is_allowed_to_make_changes: Yup.boolean(),
    phone_number: Yup.string(),
  }).defined();

  const [deliveryTimeRanges, setDeliveryTimeRanges] = useState<
    {
      id: number | null;
      start: Date | null;
      end: Date | null;
    }[]
  >([{ id: null, start: null, end: null }]);

  const addTimeRange = () => {
    setDeliveryTimeRanges([...deliveryTimeRanges, { id: null, start: null, end: null }]);
  };

  // Function to update a time range
  const updateTimeRange = (index: number, field: 'start' | 'end', newValue: Date | null) => {
    const updatedRanges = [...deliveryTimeRanges];
    updatedRanges[index][field] = newValue;
    setDeliveryTimeRanges(updatedRanges);
  };

  // Function to remove a time range
  const removeTimeRange = (index: number) => {
    const updatedRanges = [...deliveryTimeRanges];
    updatedRanges.splice(index, 1);
    setDeliveryTimeRanges(updatedRanges);
  };

  const defaultValues = useMemo(() => {
    const restaurant = restaurants[0];
    return {
      name: restaurant?.name || '',
      name_arabic: restaurant?.name_arabic || '',
      logo: restaurant?.logo || '',
      is_available: Boolean(restaurant?.is_available) || false,
      cover: restaurant?.cover || '',
      contact_number: restaurant?.contact_number?.slice(4) || '',
      email: restaurant?.email || '',
      phone_number: user?.phone_number?.slice(4) || '',
      isEdittable: false,
      is_allowed_to_make_changes: Boolean(user?.is_allowed_to_make_changes) || false,
    };
  }, [restaurants, user]);

  const fillData = useCallback(async () => {
    setLoadingDeliveryTime(true);
    try {
      if (restaurants.length > 0) {
        // Make sure there is at least one restaurant
        const data = await getRestaurantDeliveryTime(restaurants[0]?.slug);
        if (data) {
          const mappingRanges = data.map((deliverytime: DeliveryTimeRange) => {
            // Use moment to parse the start_time and end_time strings
            const startTime = moment(deliverytime.start_time, 'HH:mm:ss').toDate();
            const endTime = moment(deliverytime.end_time, 'HH:mm:ss').toDate();
            return { id: deliverytime.id, start: startTime, end: endTime };
          });

          setDeliveryTimeRanges(
            mappingRanges.length > 0 ? mappingRanges : [{ id: null, start: null, end: null }]
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
    setLoadingDeliveryTime(false);
  }, [restaurants, setDeliveryTimeRanges]); // Added dependencies

  useEffect(() => {
    if (restaurants[0]?.slug) {
      fillData();
    }
  }, [restaurants, fillData]);

  const IS_EDIT =
    (user?.type !== 'Sub-User' && defaultValues.is_allowed_to_make_changes) || user?.is_manager;

  const methods = useForm({
    resolver: yupResolver(UpdatePasswordSchema),
    defaultValues: {
      isEdittable: IS_EDIT,
      contact_number: defaultValues.contact_number || '',
      is_allowed_to_make_changes: defaultValues.is_allowed_to_make_changes || false,
      phone_number: defaultValues.phone_number || '',
    },
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (!restaurantsLoading && restaurants.length) {
      const restaurant = restaurants[0];

      reset({
        ...restaurant,
        isEdittable: IS_EDIT,
        contact_number: restaurant?.contact_number?.slice(4),
        phone_number: user?.phone_number?.slice(4),
      });
    }
  }, [reset, restaurants, restaurantsLoading, user?.phone_number, IS_EDIT]);

  const onSubmit = handleSubmit(async (data) => {
    const filteredDeliveryTimeRanges: { id: number | null; start: Date; end: Date }[] =
      deliveryTimeRanges.filter((range) => range.start !== null && range.end !== null) as {
        id: number | null;
        start: Date;
        end: Date;
      }[];
    let isValid = true;
    // Validate each delivery time range
    filteredDeliveryTimeRanges.forEach((range, index) => {
      const startMinutes = range.start.getMinutes();
      const endMinutes = range.end.getMinutes();
      const startValid = startMinutes % 10 === 0;
      const endValid = endMinutes % 10 === 0;

      if (!startValid || !endValid) {
        isValid = false;
      }
    });

    if (!isValid) {
      enqueueSnackbar('Delivery times must be in 10 minute intervals', { variant: 'error' });
      return; // Stop form submission if there are validation errors
    }

    const formattedDeliveryTimes = deliveryTimeRanges
      .filter((range) => range.start !== null && range.end !== null) // Exclude ranges with null start or end
      .map((range) => ({
        ...(range.id !== null && { id: range.id }),
        start_time: moment(range.start).format('HH:mm:ss'),
        end_time: moment(range.end).format('HH:mm:ss'),
      }));

    try {
      const response = await updateRestaurantDeliveryTimeSubadmin(
        restaurants[0]?.slug,
        formattedDeliveryTimes
      );

      if (response && response.status >= 200 && response.status < 300) {
        enqueueSnackbar('Delivery times updated successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Error updating delivery times', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(error?.detail || 'Something went wrong', { variant: 'error' });
    } finally {
      await fillData();
      // setValue('isEdittable', false);
    }
  });

  if (restaurantsLoading) {
    return <div>Loading...</div>;
  }

  if (user?.type === 'Sub-User' && user?.permissionIds.length === 0) {
    return (
      <RoleBasedGuard hasContent roles={['']} sx={{ py: 10 }}>
        Unauthorized
      </RoleBasedGuard>
    );
  }

  return (
    <RoleBasedGuard hasContent roles={['Subadmin', 'Sub-User']} sx={{ py: 10 }}>
      <Container maxWidth={false}>
        <CustomBreadcrumbs heading={defaultValues?.name || ''} sx={{ mb: 5 }} links={[{}]} />

        <Container maxWidth={false}>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Grid container spacing={3}>
              {/* <Grid xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {((user?.type !== 'Sub-User' && values.is_allowed_to_make_changes) ||
                  user?.is_manager) && (
                  <RHFSwitch
                    name="isEdittable"
                    labelPlacement="start"
                    label="Allow Edit"
                    sx={{ py: 2 }}
                  />
                )}
              </Grid> */}

              <Grid xs={12} md={3}>
                <Card sx={{ pt: 10, pb: 5, px: 3, textAlign: 'center' }}>
                  <RHFUploadAvatar
                    name="logo"
                    disabled
                    maxSize={5000000}
                    // onDrop={handleDropLogo}
                    multiple={false}
                    helperText={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 3,
                          mb: 10,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.disabled',
                        }}
                      >
                        <strong>Logo</strong>
                        <br />
                        Allowed *.jpeg, *.jpg, *.png, *.gif
                        <br /> max size of {fData(5000000)}
                      </Typography>
                    }
                  />

                  <Divider />

                  <RHFUploadAvatar
                    sx={{ mt: 8 }}
                    disabled
                    name="cover"
                    multiple={false}
                    maxSize={5000000}
                    // onDrop={handleDropCover}
                    helperText={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 5,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.disabled',
                        }}
                      >
                        Cover Image
                      </Typography>
                    }
                  />

                  <RHFSwitch
                    disabled
                    name="is_available"
                    labelPlacement="start"
                    label="Availablility"
                    sx={{ mt: 5 }}
                  />
                </Card>
              </Grid>

              <Grid xs={12} md={9}>
                <Card sx={{ p: 3, ml: 3 }}>
                  <Box
                    rowGap={3}
                    columnGap={2}
                    display="grid"
                    gridTemplateColumns={{
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(1, 1fr)',
                    }}
                  >
                    <RHFTextField name="email" label="Email" sx={{ mt: 0 }} disabled />

                    <RHFTextField name="name" label="Name" disabled />

                    <RHFTextField name="name_arabic" label="Name Ar" disabled />

                    <RHFTextField
                      disabled
                      name="contact_number"
                      label="Contact Number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify icon="twemoji:flag-kuwait" sx={{ color: 'text.disabled' }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Card>

                <Card sx={{ p: 3, mt: 3, ml: 3 }}>
                  <Box sx={{ mb: 1 }}>
                    <Typography variant="h6" style={{ marginBottom: '30px' }}>
                      Delivery Time
                    </Typography>

                    {loadingDeliveryTime ? (
                      <div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      deliveryTimeRanges?.map((range, index) => (
                        <Grid
                          container
                          rowSpacing={0.5}
                          key={index}
                          justifyContent="space-around"
                          alignItems="center"
                        >
                          <Grid xs={4} md={4.5}>
                            <TimePicker
                              open={false}
                              readOnly={false}
                              disabled={!values?.isEdittable}
                              label="Start Time"
                              disableOpenPicker
                              componentsProps={{ actionBar: { actions: [] } }}
                              value={range.start}
                              onChange={(newValue) => updateTimeRange(index, 'start', newValue)}
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  margin: 'normal',
                                },
                              }}
                              views={['hours', 'minutes']}
                              format="HH:mm"
                              timeSteps={{ minutes: 10 }}
                              ampm={false}
                              minutesStep={10}
                            />
                          </Grid>
                          <Grid xs={4} md={4.5}>
                            <TimePicker
                              readOnly={false}
                              open={false}
                              componentsProps={{ actionBar: { actions: [] } }}
                              disabled={!values?.isEdittable}
                              label="End Time"
                              disableOpenPicker
                              value={range.end}
                              onChange={(newValue) => updateTimeRange(index, 'end', newValue)}
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  margin: 'normal',
                                },
                              }}
                              views={['hours', 'minutes']}
                              format="HH:mm"
                              timeSteps={{ minutes: 10 }}
                              ampm={false}
                              minutesStep={10}
                            />
                          </Grid>

                          <Grid xs={1} md={0.5}>
                            <IconButton
                              onClick={addTimeRange}
                              disabled={
                                !(
                                  index === deliveryTimeRanges.length - 1 &&
                                  range.start &&
                                  range.end
                                ) || !values?.isEdittable
                              }
                            >
                              <Iconify icon="basil:add-solid" />
                            </IconButton>
                          </Grid>

                          <Grid xs={1} md={0.5}>
                            <IconButton
                              onClick={() => removeTimeRange(index)}
                              disabled={deliveryTimeRanges.length === 1 || !values?.isEdittable}
                            >
                              <Iconify icon="eva:trash-2-outline" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))
                    )}
                  </Box>

                  {values?.isEdittable && (
                    <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                      <div style={{ display: 'flex' }}>
                        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                          Update Delivery Time
                        </LoadingButton>
                      </div>
                    </Stack>
                  )}
                </Card>
              </Grid>
            </Grid>
          </FormProvider>
        </Container>
      </Container>
    </RoleBasedGuard>
  );
}
