import { m } from 'framer-motion';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import { SeverErrorIllustration } from 'src/assets/illustrations';

import { MotionContainer, varBounce } from 'src/components/animate';
import Iconify from '../iconify/iconify';

// ----------------------------------------------------------------------

export default function Page500() {

  const url = window.location.href;
  const isAuth = url.includes("auth");


  const handleRefreshPage = () => {
    window.location.reload();
  }
  return (
    <MotionContainer>
      <m.div variants={varBounce().in}>
        <Typography variant="h3" sx={{ mt: isAuth ? 13 : 2, mb: 2, textAlign: 'center' }}>
          Internal Error
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary', textAlign: 'center' }}>
          There was an error, please try again later.
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
      </m.div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={handleRefreshPage}
          startIcon={<Iconify icon="material-symbols:refresh" />}
          sx={{ textAlign: 'center', mx: 'auto' }}
          size="large"
          variant="contained">
          Refresh Page
        </Button>
      </div>
    </MotionContainer>
  );
}
