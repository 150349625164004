import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';

import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';


import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { updateRestaurantPassword } from 'src/api/restaurant';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function JWTNewPasswordView() {
  const { enqueueSnackbar } = useSnackbar();


  const router = useRouter();



  const password = useBoolean();
  const newPassword = useBoolean();


  const VerifySchema = Yup.object().shape({

    current_password: Yup.string()
      .required('Current Password is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
  });

  const defaultValues = {
    current_password: '',
    password: '',
    confirmPassword: '',
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(VerifySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  const onSubmit = handleSubmit(async (data) => {
    try {
      // await newPassword?.(data.phone_number, data.current_password, data.password);

      try {
        const response = await updateRestaurantPassword({
          current_password: data.current_password,
          new_password: data.password,
        })

        if (response) {
          if (response.detail) {
            enqueueSnackbar(response.detail, { variant: 'success' });
          } else {
            enqueueSnackbar('Password changed successfully!', { variant: 'success' });
          }
        }

        router.back();

      } catch (error) {
        if (typeof error.detail === "string") {
          enqueueSnackbar(error.detail, { variant: 'error' });
        }
        else {
          enqueueSnackbar('Error changing the password!', { variant: 'error' });
        }
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  });



  const renderForm = (
    <Stack spacing={3} alignItems="center">


      <RHFTextField
        name="current_password"
        label="Current Password"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          autoComplete: "new-password",
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <RHFTextField
        name="password"
        label="Password"
        type={newPassword.value ? 'text' : 'password'}
        InputProps={{
          autoComplete: "new-password",
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={newPassword.onToggle} edge="end">
                <Iconify icon={newPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),

        }}
      />

      <RHFTextField
        name="confirmPassword"
        label="Confirm New Password"
        type='password'
        InputProps={{
          autoComplete: "new-password"
        }}
      />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Update Password
      </LoadingButton>




    </Stack>
  );


  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>

      <Typography variant="h3" sx={{ mb: 5, textAlign: 'center' }}>
        Change Password
      </Typography>

      {renderForm}
    </FormProvider>
  );
}
