import { useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import useSWR from 'swr';

import dayjs from 'dayjs';
import {
  IPerformanceSubadminSummary,
  IPerformanceSubadminSummaryDetails,
  IPerformanceSubadminSummaryListResponse,
  IPerformanceSummary,
  IPerformanceSummaryDetails,
} from 'src/types/performance';
import { endpoints, fetcher } from 'src/utils/axios';

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const getLast12MonthsNames = () => {
  const now = dayjs();
  return Array.from({ length: 12 }, (_, i) => now.subtract(i, 'month').format('MMM')).reverse();
};

// interface Data {
//   data: {
//     sales: number[];
//     subscriptions: number[];
//   };
//   labels: string[];
// }

// interface TransformedData {
//   data: {
//     sales: number[];
//     subscriptions: number[];
//   };
//   labels: string[];
// }

// // Extract and format data dynamically by years
// function transformData(data: Data): TransformedData {
//   const transformedData: TransformedData = {
//     data: {
//       sales: [],
//       subscriptions: [],
//     },
//     labels: [],
//   };

//   const monthYearMap: { [key: string]: { sales: number; subscriptions: number } } = {};

//   data.labels.forEach((label, index) => {
//     const [year, month] = label.split('-').slice(0, 2);
//     const monthYearKey = `${year}-${month}`;

//     if (!monthYearMap[monthYearKey]) {
//       monthYearMap[monthYearKey] = {
//         sales: 0,
//         subscriptions: 0,
//       };
//     }

//     monthYearMap[monthYearKey].sales += data.data.sales[index];
//     monthYearMap[monthYearKey].subscriptions += data.data.subscriptions[index];
//   });

//   // eslint-disable-next-line no-restricted-syntax
//   for (const [key, value] of Object.entries(monthYearMap)) {
//     const [year, month] = key.split('-');
//     const monthName = new Date(parseInt(year, 10), parseInt(month, 10) - 1).toLocaleString(
//       'default',
//       { month: 'short' }
//     );
//     transformedData.labels.push(`${monthName}`); /* , ${year} */
//     transformedData.data.sales.push(value.sales);
//     transformedData.data.subscriptions.push(value.subscriptions);
//   }

//   return transformedData;
// }

// Example usage with your data object

const formattedData = (apiResponse: { key: string; labels: string[]; data: any }) => {
  const yearDataMap = new Map();
  const last12MonthsData = new Array(12).fill(0); // Array to store last 12 months data

  // Calculate the date 12 months ago from now
  const last12MonthsStart = dayjs().subtract(12, 'month');

  apiResponse.labels.forEach((label, index) => {
    const date = dayjs(label);

    // Ensure it's within the last 12 months
    if (date.isAfter(last12MonthsStart) && date.isBefore(dayjs())) {
      const monthIndex =
        (date.year() - last12MonthsStart.year()) * 12 +
        date.month() -
        last12MonthsStart.month() -
        1;

      const value = apiResponse.data[apiResponse.key][index];

      // Only assign the value if the monthIndex is within the last 12 months
      if (monthIndex >= 0 && monthIndex < 12) {
        last12MonthsData[monthIndex] = value;

        const year = date.year();
        if (!yearDataMap.has(year)) {
          yearDataMap.set(year, new Array(12).fill(0));
        }
        yearDataMap.get(year)![date.month()] = value;
      }

      if (apiResponse.key === 'subscriptions') {
        console.log('monthIndex', apiResponse.key, monthIndex, value);

        console.log(last12MonthsData);
      }
    }
  });

  // Add the last 12 months data to the map with a special key
  yearDataMap.set('last 12 months', last12MonthsData);

  const series = Array.from(yearDataMap, ([key, data]) => ({
    year: key,
    data: [
      {
        name: apiResponse.key === 'subscriptions' ? 'Total Subscriptions' : 'Total Sales',
        data,
      },
    ],
  }));

  return series;
};

// ----------------------------------------------------------------------

export function useGetPerformanceStats() {
  const URL = endpoints.performance.performance_summary_widgets;

  const { data, isLoading, error } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      monthly_sales: data?.monthly_sales || null,
      monthly_income: data?.monthly_income || null,
      active_subscriptions: data?.active_subscriptions || null,
      total_subscriptions: data?.total_subscriptions || null,
      performanceLoading: isLoading,
      performanceError: error,
    }),
    [data, error, isLoading]
  );

  return memoizedValue;
}

export function useGetPlansStats(restaurant_id: string | null) {
  const URL = restaurant_id
    ? endpoints.performance.performance_restaurant_subs_per_plan(restaurant_id)
    : endpoints.performance.performance_subs_per_plan;

  const { data, isLoading, error } = useSWR(URL, fetcher);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const compiledData =
    data && !isLoading && !error
      ? data?.labels?.map((label: string, index: number) => ({
          label,
          value: data?.data?.subscriptions[index] || 0,
        }))
      : [];

  const memoizedValue = useMemo(
    () => ({
      subsperplanData: compiledData,
      subsperplanLoading: isLoading,
      subsperplanError: error,
    }),
    [compiledData, error, isLoading]
  );

  return memoizedValue;
}

export function useGetPeriodsStats(restaurant_id: string | null) {
  const URL = restaurant_id
    ? endpoints.performance.performance_restaurant_subs_per_period(restaurant_id)
    : endpoints.performance.performance_subs_per_period;

  const { data, isLoading, error } = useSWR(URL, fetcher);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const compiledData =
    data && !isLoading && !error
      ? data?.labels?.map((label: string, index: number) => ({
          label,
          value: data?.data?.subscriptions[index] || 0,
        }))
      : [];

  const memoizedValue = useMemo(
    () => ({
      subsperperiodData: compiledData,
      subsperperiodLoading: isLoading,
      subsperperiodError: error,
    }),
    [compiledData, error, isLoading]
  );

  return memoizedValue;
}

export function useGetOrdersStats(restaurant_id: string | null) {
  const URL = restaurant_id
    ? endpoints.performance.performance_restaurant_orders_per_food(restaurant_id)
    : endpoints.performance.performance_orders_per_food;

  const { data, isLoading, error } = useSWR(URL, fetcher);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const compiledData =
    data && !isLoading && !error
      ? data?.labels?.map((label: string, index: number) => ({
          label,
          value: data?.data?.orders[index] || 0,
        }))
      : [];

  const memoizedValue = useMemo(
    () => ({
      ordersperfoodData: compiledData,
      ordersperfoodLoading: isLoading,
      ordersperfoodError: error,
    }),
    [compiledData, error, isLoading]
  );

  return memoizedValue;
}

export function useGetSubscriptionsOverTimeStats(restaurant_id: string | null) {
  const URL = restaurant_id
    ? endpoints.performance.performance_restaurant_subs_over_time(restaurant_id)
    : endpoints.performance.performance_subs_over_time;

  const { data, isLoading, error } = useSWR(URL, fetcher);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const formattedParam = { ...data, key: 'subscriptions' };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const chartData = {
    categories: monthNames,
    series: !isLoading && !error && data ? formattedData(formattedParam) : [],
  };

  const memoizedValue = useMemo(
    () => ({
      subspertimeData: chartData,
      subspertimeLoading: isLoading,
      subspertimeError: error,
    }),
    [chartData, error, isLoading]
  );

  return memoizedValue;
}

export function useGetSalesOverTimeStats(restaurant_id: string | null) {
  const URL = restaurant_id
    ? endpoints.performance.performance_restaurant_sales_over_time(restaurant_id)
    : endpoints.performance.performance_sales_over_time;

  const { data, isLoading, error } = useSWR(URL, fetcher);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const formattedParam = { ...data, key: 'sales' };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const chartData = {
    categories: monthNames,
    series: !isLoading && !error && data ? formattedData(formattedParam) : [],
  };

  const memoizedValue = useMemo(
    () => ({
      salespertimeData: chartData,
      salespertimeLoading: isLoading,
      salespertimeError: error,
    }),
    [chartData, error, isLoading]
  );

  return memoizedValue;
}

// export function useGetSubsSalesOverTime(restaurant_id: string | null) {
//   const URL = restaurant_id
//     ? endpoints.performance.performance_restaurant_subs_sales_over_time(restaurant_id)
//     : endpoints.performance.performance_subs_sales_over_time;

//   const { data, isLoading, error } = useSWR(URL, fetcher);
//   console.log('this');
//   const modifiedData = transformData(data || { labels: [] });

//   const memoizedValue = useMemo(
//     () => ({
//       subssalesovertimeData: modifiedData,
//       subssalesovertimeLoading: isLoading,
//       subssalesovertimeError: error,
//     }),
//     [modifiedData, error, isLoading]
//   );

//   return memoizedValue;
// }

export function useGetPerformanceFilters(restaurant_id: string | null) {
  const filters = Object.values(endpoints.performance.filters);

  let URLs: string[] = [];

  if (!restaurant_id) {
    URLs = filters.filter((f): f is string => typeof f === 'string');
  } else {
    URLs = filters
      .filter((f): f is (restaurant_id: string) => string => typeof f === 'function')
      .map((f) => f(restaurant_id));
  }

  const {
    data: promiseData,
    isLoading,
    error,
  } = useSWR(URLs, async () => {
    const responses = await Promise.all(URLs?.map((url) => fetcher(url)));
    return responses;
  });

  const memoizedValue = useMemo(
    () => ({
      filters: {
        subs_over_time: promiseData?.[0].data || [],
        sales_over_time: promiseData?.[1].data || [],
        all_plans: promiseData?.[2] || [],
      },
      filtersLoading: isLoading,
      filtersError: error,
    }),
    [promiseData, isLoading, error]
  );

  return memoizedValue;
}

// get customer list
export function useGetPerformanceList(page: number, page_size: number) {
  const URL = endpoints.performance.performance_summary_list;
  const URL_WITH_PARAMS = `${URL}?page=${page}&page_size=${page_size}`;

  const { data, isLoading, error, isValidating } = useSWR(URL_WITH_PARAMS, fetcher);

  const memoizedValue = useMemo(
    () => ({
      performances: data as IPerformanceSummary,
      performancesLoading: isLoading,
      performancesError: error,
      performancesValidating: isValidating,
      performancesEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetPerformanceSubadminList(page: number, page_size: number) {
  const URL = endpoints.performance.performance_summary_list;
  const URL_WITH_PARAMS = `${URL}?page=${page}&page_size=${page_size}`;

  const { data, isLoading, error, isValidating } = useSWR(URL_WITH_PARAMS, fetcher);

  const memoizedValue = useMemo(
    () => ({
      performances: data as IPerformanceSubadminSummary,
      performancesLoading: isLoading,
      performancesError: error,
      performancesValidating: isValidating,
      performancesEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetPerformanceSubadminDetails(start_date: string, end_date: string) {
  const URL = `${endpoints.performance.performance_summary_details}?start_date=${start_date}&end_date=${end_date}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      performance: data as IPerformanceSubadminSummaryDetails[],
      performanceLoading: isLoading,
      performanceError: error,
      performanceValidating: isValidating,
      performanceEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetPerformanceDetails(start_date: string, end_date: string) {
  const URL = `${endpoints.performance.performance_summary_details}?start_date=${start_date}&end_date=${end_date}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      performance: data as IPerformanceSummaryDetails[],
      performanceLoading: isLoading,
      performanceError: error,
      performanceValidating: isValidating,
      performanceEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetPerformanceDetailsList(
  page: number,
  page_size: number,
  phone_number: string | null
) {
  const URL = `${endpoints.performance.performance_summary_list_subadmin}`;

  const queryParams = new URLSearchParams({
    page: page.toString(),
    page_size: page_size.toString(),
  });

  if (phone_number && phone_number.length > 0) {
    queryParams.set('search', phone_number);
  }
  const URL_WITH_PARAMS = `${URL}?${queryParams.toString()}`;

  const { data, isLoading, error, isValidating } = useSWR(URL_WITH_PARAMS, fetcher);

  const memoizedValue = useMemo(
    () => ({
      performance: data as IPerformanceSubadminSummaryListResponse,
      performanceLoading: isLoading,
      performanceError: error,
      performanceValidating: isValidating,
      performanceEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
