import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import TransactionsListView from 'src/sections/transactions/view/transaction-list-view';


// ----------------------------------------------------------------------

export default function Transactions() {
    return (

        <>
            <Helmet>
                <title> Dashboard: Transactions</title>
            </Helmet>
            <Container maxWidth={false}>

                <CustomBreadcrumbs
                    heading="Transactions"
                    links={[

                        {
                        },
                    ]}

                    sx={{ mb: { xs: 3, md: 5 } }}
                />

                <TransactionsListView />
            </Container>

        </>
    );
}
