import { useMemo } from 'react';
import axiosInstance, { endpoints, fetcher } from 'src/utils/axios';
import useSWR from 'swr';
import { useGetAccountCustomers } from './account';

export function useGetSingleAccountCustomer(id: string | undefined) {
  // const URL = endpoints.customers.profile(id);
  const { users } = useGetAccountCustomers();
  const returnValue = {
    customer: users.results.find((user) => user.id === Number(id)),
  };

  return returnValue;
}

// get customer list
export function useGetCustomers(
  page: number,
  page_size: number,
  search: string | undefined,
  type: string | undefined,
  status: string | undefined
) {
  const allowedValuesType = ['normal', 'subscribed'];
  const allowedValuesStatus = ['active', 'old'];

  const URL = endpoints.customers.list;
  const queryParams = new URLSearchParams({
    page: page.toString(),
    page_size: page_size.toString(),
  });

  if (type && allowedValuesType.includes(type)) {
    queryParams.set('type', type);
  }

  if (status && allowedValuesStatus.includes(status)) {
    queryParams.set('subscription_status', status);
  }

  if (search && search.length > 0) {
    queryParams.set('search', search);

    // URL_WITH_PARAMS += `&search=${search}`;
  }
  const URL_WITH_PARAMS = `${URL}?${queryParams.toString()}`;

  const { data, isLoading, error, isValidating } = useSWR(URL_WITH_PARAMS, fetcher);

  const memoizedValue = useMemo(
    () => ({
      customers: (data as any) || { results: [], count: 0 },
      customersLoading: isLoading,
      customersError: error,
      customersValidating: isValidating,
      customersEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetCustomer(customer_id: string) {
  const URL = endpoints.customers.retrieve_customer(customer_id);

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      customer: (data as any) || { results: [], count: 0 },
      customerLoading: isLoading,
      customerError: error,
      customerValidating: isValidating,
      customerEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export async function updateCustomerComment(id: string, comment: string) {
  const URL = endpoints.customers.update_comment(id);

  const response = await axiosInstance.post(URL, { comment });

  if (response.status !== 200) throw new Error(response.statusText);

  return response.data;
}

export async function updateCustomerCheckoutNote(id: string, checkout_notes: string) {
  const URL = endpoints.customers.update_checkout_note(id);

  const response = await axiosInstance.post(URL, { checkout_notes });

  if (response.status !== 200) throw new Error(response.statusText);

  return response.data;
}

export async function updateCustomerExtendSubscription(sub_id: string, days: number) {
  const URL = endpoints.customers.extend_subscription(sub_id);
  const response = await axiosInstance.post(URL, { days });
  if (response.status !== 200) throw new Error(response.statusText);
  return response.data;
}

export async function GetSubscriptionsExcel(phone_number: string | null) {
  const URL = endpoints.performance.performance_details_list;

  const queryParams = new URLSearchParams();

  if (phone_number && phone_number.length > 0) {
    queryParams.set('search', phone_number);
  }

  const URL_WITH_PARAMS = `${URL}?${queryParams.toString()}`;

  try {
    const response = await axiosInstance.get(URL_WITH_PARAMS, { responseType: 'blob' });
    return response.data;
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
}
