import { Chip } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ICustomerAdminResult, ISubAdminResult, IUserResult } from 'src/types/user';
import { fDate } from 'src/utils/format-time';





// ----------------------------------------------------------------------

type Props = {
  selected: boolean;
  onEditRow: VoidFunction;
  row: IUserResult | ISubAdminResult | ICustomerAdminResult;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  id: any
};

export default function UserTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  id
}: Props) {
  return (
    <TableRow hover selected={selected} >
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}

      <TableCell
        sx={{
          whiteSpace: 'nowrap', cursor: 'pointer', textDecoration: 'underline'
        }}
        onClick={onEditRow}>
        {"phone_number" in row ? row?.phone_number?.slice(4) : row?.user?.phone_number?.slice(4)}
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {"name" in row ? row.name : row.user.name}
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {("status" in row) && <Chip label={row?.status} color={row?.status === "verified" ? "success" : "error"} />}

      </TableCell>


      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {("roles" in row) && row.roles}

      </TableCell>



      {"created_at" in row &&
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {fDate(row.created_at)}
        </TableCell>
      }

      {"updated_at" in row &&
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {fDate(row.updated_at)}
        </TableCell>
      }



    </TableRow>
  );
}
