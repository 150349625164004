import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';

import { Typography } from '@mui/material';
import {
  useGetPerformanceFilters,
  useGetPerformanceStats,
  useGetPeriodsStats,
  useGetPlansStats,
  useGetSalesOverTimeStats,
  useGetSubscriptionsOverTimeStats,
} from 'src/api/performance';
import { RoleBasedGuard } from 'src/auth/guard';
import { useAuthContext } from 'src/auth/hooks';
import AnalyticsCurrentVisits from '../analytics-current-visits';
import EcommerceYearlySales from '../ecommerce-yearly-sales';
import PerformanceWidgetSummary from '../performance-widget-summary';

// ----------------------------------------------------------------------

export default function OverviewPerformanceView() {
  const { user } = useAuthContext();

  const {
    monthly_sales,
    monthly_income,
    active_subscriptions,
    total_subscriptions,
    performanceLoading,
    performanceError,
  } = useGetPerformanceStats();

  const { subsperplanData, subsperplanLoading, subsperplanError } = useGetPlansStats(
    user?.restaurant_id || null
  );

  const { subsperperiodData, subsperperiodLoading, subsperperiodError } = useGetPeriodsStats(
    user?.restaurant_id || null
  );

  const { subspertimeData, subspertimeLoading, subspertimeError } =
    useGetSubscriptionsOverTimeStats(user?.restaurant_id || null);

  const { salespertimeData, salespertimeLoading, salespertimeError } = useGetSalesOverTimeStats(
    user?.restaurant_id || null
  );

  const { filters, filtersLoading, filtersError } = useGetPerformanceFilters(
    user?.restaurant_id || null
  );

  const theme = useTheme();

  const isLoading =
    performanceLoading ||
    subsperplanLoading ||
    subsperperiodLoading ||
    subspertimeLoading ||
    salespertimeLoading ||
    filtersLoading;

  const isError =
    performanceError ||
    subsperplanError ||
    subsperperiodError ||
    subspertimeError ||
    salespertimeError ||
    filtersError;

  return (
    <RoleBasedGuard hasContent roles={['Superuser', 'Subadmin', 'Sub-User']} sx={{ py: 10 }}>
      {
        // eslint-disable-next-line no-nested-ternary
        isLoading ? (
          <Typography variant="h5" sx={{ my: 3 }}>
            Loading...
          </Typography>
        ) : isError ? (
          <h6>Failed to load performance stats</h6>
        ) : (
          <Grid container spacing={3}>
            {monthly_sales && (
              <Grid xs={12} md={4}>
                <PerformanceWidgetSummary
                  title="Total Sales"
                  percent={monthly_sales.percent}
                  total={monthly_sales.total}
                  chart={{
                    series: monthly_sales.series,
                  }}
                />
              </Grid>
            )}

            {monthly_income && (
              <Grid xs={12} md={4}>
                <PerformanceWidgetSummary
                  title={user?.type === 'Superuser' ? 'Commissions' : 'Monthly Income'}
                  percent={monthly_income.percent}
                  total={monthly_income.total}
                  chart={{
                    colors: [theme.palette.info.light, theme.palette.info.main],
                    series: monthly_income.series,
                  }}
                />
              </Grid>
            )}

            {active_subscriptions && (
              <Grid xs={12} md={4}>
                <PerformanceWidgetSummary
                  title="Active Subscriptions"
                  percent={active_subscriptions.percent}
                  total={active_subscriptions.total}
                  chart={{
                    colors: [theme.palette.warning.light, theme.palette.warning.main],
                    series: active_subscriptions.series,
                  }}
                />
              </Grid>
            )}

            {total_subscriptions && (
              <Grid xs={12} md={4}>
                <PerformanceWidgetSummary
                  title="Total Subscriptions"
                  percent={total_subscriptions.percent}
                  total={total_subscriptions.total}
                  chart={{
                    colors: [theme.palette.warning.light, theme.palette.warning.main],
                    series: total_subscriptions.series,
                  }}
                />
              </Grid>
            )}

            <Grid xs={12} md={12} lg={12} />

            <Grid xs={12} md={12} lg={6}>
              <EcommerceYearlySales
                title="Subscriptions"
                subheader=""
                filterKey={filters.subs_over_time}
                chart={subspertimeData}
              />
            </Grid>

            <Grid xs={12} md={12} lg={6}>
              <EcommerceYearlySales
                filterKey={filters.sales_over_time}
                title="Sales"
                subheader=""
                chart={salespertimeData}
              />
            </Grid>

            <Grid xs={12} md={12} lg={12}>
              <AnalyticsCurrentVisits
                title="Subscriptions Period"
                chart={{
                  series: subsperperiodData.map((stat: { label: any; value: number }) => ({
                    label: stat.label,
                    value: stat.value,
                  })),
                }}
              />
            </Grid>

            <Grid xs={12} md={12} lg={12}>
              <AnalyticsCurrentVisits
                title="Plans Subscriptions"
                chart={{
                  series: subsperplanData.map((stat: { label: any; value: number }) => ({
                    label: stat.label.title,
                    value: stat.value,
                  })),
                }}
              />
            </Grid>

            {/* 
            <Grid xs={12} md={12} lg={12}>
              <EcommerceYearlySales
                filterKey={filters.all_plans}
                title="Plans Subscriptions/Sales"
                subheader=""
                chart={{
                  categories: subssalesovertimeData.labels,
                  series: [
                    {
                      year: 'last 12 months',
                      data: [
                        {
                          name: 'Sales',
                          data: subssalesovertimeData.data.sales,
                        },
                        {
                          name: 'Subscriptions',
                          data: subssalesovertimeData.data.subscriptions,
                        },
                      ],
                    },
                  ],
                }}
              />
            </Grid> */}
          </Grid>
        )
      }
    </RoleBasedGuard>
  );
}
