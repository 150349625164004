import { Chip } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useAuthContext } from 'src/auth/hooks';
import { ICustomerAdminResult, ISubAdminResult, IUserResult } from 'src/types/user';
import { fDate } from 'src/utils/format-time';





// ----------------------------------------------------------------------

type Props = {
  selected: boolean;
  onEditRow: VoidFunction;
  row: IUserResult | ISubAdminResult | ICustomerAdminResult;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  id: any
};

export default function CustomerTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  id
}: Props) {
  const { user } = useAuthContext();
  return (
    <TableRow hover selected={selected} >
      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}


      <TableCell
        onClick={onEditRow}
        sx={{
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
      >

        {
          "phone_number" in row
            ?
            row.phone_number
            :
            row.user?.phone_number?.slice(4)
        }

      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {"name" in row ? row.name : row.user.name}
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {("subscriptions" in row && (user?.type === "Subadmin" || user?.type === "Sub-User")) && <Chip label={row.subscriptions[0]?.status} color={row.subscriptions[0]?.status === "active" ? "success" : "error"} />}
        {"type" in row && user?.type === "Superuser" && <Chip label={row.type} color={row.type === "normal" ? "primary" : "secondary"} />}
      </TableCell>


      {"subscriptions" in row && user?.type === "Superuser" &&
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row.subscriptions.length}
        </TableCell>
      }

      {"subscriptions" in row && (user?.type === "Subadmin" || user?.type === "Sub-User") &&
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row.subscriptions.length ? row.subscriptions[0].plan.title : ""}
        </TableCell>
      }

      {"subscriptions" in row && (user?.type === "Subadmin" || user?.type === "Sub-User") &&
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row.subscriptions.length ? row.subscriptions[0].package.period : ""}
        </TableCell>
      }

      {"subscriptions" in row && (user?.type === "Subadmin" || user?.type === "Sub-User") &&
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row.subscriptions.length ? row.subscriptions[0].start_date : ""}
        </TableCell>
      }

      {"subscriptions" in row && (user?.type === "Subadmin" || user?.type === "Sub-User") &&
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row.subscriptions.length ? row.subscriptions[0].end_date : ""}
        </TableCell>
      }



      {!("subscriptions" in row) && user?.type === "Superuser" && <TableCell sx={{ whiteSpace: 'nowrap' }}>{fDate(row.created_at)}</TableCell>}

      {"current_restaurant" in row && user?.type === "Superuser" && <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {(row.current_restaurant as string)}</TableCell>}

      {"comment" in row && user?.type === "Superuser" && <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.comment}</TableCell>}

    </TableRow>
  );
}
