import { Info } from '@mui/icons-material';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DataGridPro,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  GetSubscriptionsExcel,
  updateCustomerCheckoutNote,
  updateCustomerComment,
  updateCustomerExtendSubscription,
} from 'src/api/customer';
import { useGetPerformanceDetailsList } from 'src/api/performance';
import { getRestaurantDeliveryTime, updateRestaurantDeliveryTime } from 'src/api/restaurant';
import { RoleBasedGuard } from 'src/auth/guard';
import { useAuthContext } from 'src/auth/hooks';
import Iconify from 'src/components/iconify';
import {
  IPerformanceSubadminSummaryDetails,
  IPerformanceSuperuserSummaryDetails,
} from 'src/types/performance';
import { fDate } from 'src/utils/format-time';


const PausedDatesModal = ({ open, setOpen, datess }: { open: boolean; setOpen: (open: boolean) => void; datess: string[] }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Paused Dates</DialogTitle>
      <DialogContent>
        {datess.length > 0 ? (
          datess.map((date, index) => (
            <Typography key={index} variant="body1" gutterBottom>
              {date}
            </Typography>
          ))
        ) : (
          <Typography variant="body2">No paused dates available</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function SelectEditInputCell(props: GridRenderCellParams & { setRows: Function; rows: any[] }) {
  const { id, value, field, row, setRows, rows } = props;
  const apiRef = useGridApiContext();
  const [deliveryTimes, setDeliveryTimes] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const [selectedValue, setSelectedValue] = useState<any>(row.delivery_time_id); // Track selected value state
  const [open, setOpen] = useState(false); // State to control the opening of the dropdown

  const handleChange = async (event: SelectChangeEvent) => {
    const newValue = event.target.value;
    setSelectedValue(newValue); // Optimistically set the new value

    try {
      const selectedOption = deliveryTimes.find(
        (time) => Number(time.id) === Number(event.target.value)
      );
      if (selectedOption) {
        const response = await updateRestaurantDeliveryTime(row.delivery_time_id, {
          start_time: selectedOption.start_time,
          end_time: selectedOption.end_time,
        });

        if (response.status >= 200 && response.status < 300) {
          const updatedRows = rows.map((r) => {
            if (r.id === id) {
              return {
                ...r,
                [field]: newValue,
                start_time: selectedOption.start_time,
                end_time: selectedOption.end_time,
              };
            }
            return r;
          });
          setRows(updatedRows);
          enqueueSnackbar('Updated Successfully', { variant: 'success' });
        }
      }
    } catch (err) {
      setSelectedValue(value); // Revert to the previous value on failure
      enqueueSnackbar(err.detail || 'Failed to Update', { variant: 'error' });
    } finally {
      apiRef.current.stopCellEditMode({ id, field });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await getRestaurantDeliveryTime(row.restaurant_slug);
        setDeliveryTimes(data);

        const selectedOption = data.find(
          (option: any) => option.start_time === row.start_time && option.end_time === row.end_time
        );

        setSelectedValue(selectedOption?.id);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (row.restaurant_slug) {
      fetchData();
    }
  }, [row.end_time, row.restaurant_slug, row.start_time]);

  useEffect(() => {
    // Open the dropdown automatically once loading is complete and data is loaded
    if (!loading && deliveryTimes.length > 0) {
      setOpen(true);
    }
  }, [loading, deliveryTimes]); // Dependencies on loading and deliveryTimes

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          alignItems: 'center',
          paddingRight: '5px',
        }}
      >
        <CircularProgress size={16} />
      </div>
    );
  }

  return (
    <Select
      open={open} // Controlled open state
      onClose={() => setOpen(false)} // Handle closing
      onOpen={() => setOpen(true)} // Ensure it can open normally on user interaction
      fullWidth
      value={selectedValue}
      onChange={handleChange}
      size="small"
      sx={{ height: 1, border: 'none', outline: 'none' }}
      autoFocus
    >
      {deliveryTimes.map((time) => (
        <MenuItem key={time.id} value={time.id}>
          {dayjs(time.start_time, 'HH:mm:ss').format('hh:mm A')} -{' '}
          {dayjs(time.end_time, 'HH:mm:ss').format('hh:mm A')}
        </MenuItem>
      ))}
    </Select>
  );
}

function TextFieldInputCell(
  props: GridRenderCellParams & {
    setRows: Function;
    rows: any[];
    functiontocall: (id: string, value: any) => any;
  }
) {
  const { id, value, field, setRows, rows, functiontocall } = props;

  const [inputValue, setInputValue] = useState(value);

  const currentRow = rows.find((row) => row.id === id);
  const customerId = currentRow ? currentRow.customer_id : null;

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue); // Optimistically set the new value
  };
  // get value of customer_id from the row

  const handleCommitChange = async () => {
    if (inputValue === value) {
      return;
    }

    try {
      const response = await functiontocall(
        `${field === 'checkout_notes' ? id : `${customerId}`}`,
        inputValue
      );
      if (response) {
        const updatedRows = rows.map((r) => {
          if (r.id === id) {
            return { ...r, [field]: inputValue };
          }
          return r;
        });
        setRows(updatedRows);
        enqueueSnackbar('Updated Successfully', { variant: 'success' });
      }
    } catch (err) {
      setInputValue(value); // Revert to previous value on failure
      enqueueSnackbar(err.detail || 'Failed to Update', { variant: 'error' });
    }
  };

  return (
    <Tooltip title={inputValue}>
      <TextField
        fullWidth
        value={inputValue}
        onChange={handleChange}
        onBlur={handleCommitChange} // Handle API call when focus is lost
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleCommitChange();
          }
        }}
        size="small"
        autoFocus
      />
    </Tooltip>
  );
}

export default function PerformanceDetailListView() {
  const initialPaginationModel = JSON.parse(
    localStorage.getItem('paginationModel') || '{"pageSize": 25, "page": 0}'
  );
  const initialPhoneNumber = localStorage.getItem('phone_number');
  const [phone_number, setPhone_number] = useState<string | null>(initialPhoneNumber);
  const [paginationModel, setPaginationModel] = useState(initialPaginationModel);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const { user } = useAuthContext();

  const [openModal, setOpenModal] = useState<{ [key: string]: boolean }>({});

  // Function to handle opening the modal for a specific row
  const handleOpenModal = (id: string) => {
    setOpenModal((prevState) => ({ ...prevState, [id]: true }));
  };

  // Function to handle closing the modal for a specific row
  const handleCloseModal = (id: string) => {
    setOpenModal((prevState) => ({ ...prevState, [id]: false }));
  };

  const renderPausedDatesCell = (params: GridRenderCellParams) => {
    const isOpen = openModal[params.id] || false; // Check if this modal is open

    const hasDates = params.row.paused_dates.length > 0; // Check if paused_dates exist

    return (
      <>
        {hasDates && <Button
          variant="contained"
          startIcon={hasDates ? <Info /> : <Info />} // Conditionally render the icon
          size="small"
          onClick={() => handleOpenModal(params.row.id)} // Open modal for specific row
        >
          View 
        </Button>}
        <PausedDatesModal
          open={isOpen}
          setOpen={() => handleCloseModal(params.row.id)} // Close modal for specific row
          datess={params.row.paused_dates}
        />
      </>
    );
  };

  const { performance, performanceLoading } = useGetPerformanceDetailsList(
    paginationModel.page + 1,
    paginationModel.pageSize,
    phone_number
  );

  const [rows, setRows] = useState<any[]>([]);

  const renderSelectEditInputCell: GridColDef['renderCell'] = (params) => (
    <SelectEditInputCell {...params} setRows={setRows} rows={rows} />
  );

  const renderCommentEditInputCell: GridColDef['renderCell'] = (params) => (
    <TextFieldInputCell
      {...params}
      setRows={setRows}
      rows={rows}
      functiontocall={updateCustomerComment}
    />
  );

  const renderCheckoutNotesEditInputCell: GridColDef['renderCell'] = (params) => (
    <TextFieldInputCell
      {...params}
      setRows={setRows}
      rows={rows}
      functiontocall={updateCustomerCheckoutNote}
    />
  );

  const [openExtend, setOpenExtend] = useState({
    open: false,
    id: '',
  });

  const [extendedDays, setExtendedDays] = useState(1);

  const updatePhoneNumber = (value: string | null) => {
    setPhone_number(value);
    localStorage.setItem('phone_number', value || '');

    // Reset pagination to the first page
    const newPaginationModel = { ...paginationModel, page: 0 };
    setPaginationModel(newPaginationModel);

    // Store the updated pagination model in localStorage
    localStorage.setItem('paginationModel', JSON.stringify(newPaginationModel));
  };

  // Update localStorage when paginationModel changes
  const updatePaginationModel = (model: any, detail: any) => {
    setPaginationModel(model);
    localStorage.setItem('paginationModel', JSON.stringify(model));
  };

  const columnsConfig: {
    superuser: any[];
    subadmin: any[];
  } = {
    superuser: [
      {
        field: 'phone_number',
        headerName: 'Phone Number',
        width: 120,
        disableReorder: true,
        filterable: false,
      },
      {
        field: 'subscription_status',
        headerName: 'Status',
        width: 120,
        type: 'singleSelect',
        valueOptions: ['old', 'active', 'paused', 'cancelled'],
        filterable: false,
        pinnable: false,
        sortable: false,
        hideable: false,
        disableReorder: true,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip title={params.row.paused_from_to || ''}>
            <Chip
              label={params.row.subscription_status}
              // eslint-disable-next-line no-nested-ternary
              color={
                // eslint-disable-next-line no-nested-ternary
                params.row.subscription_status === 'active'
                  ? 'success'
                  : params.row.subscription_status === 'paused'
                  ? 'warning'
                  : 'error'
              }
              size="small"
            />
          </Tooltip>
        ),
        valueGetter: (subscription_status: string) => subscription_status,
      },
      {
        field: 'name',
        headerName: 'Name',
        width: 120,
        pinnable: false,
        sortable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
      },
      {
        field: 'restaurant',
        headerName: 'Restaurant',
        width: 150,
        pinnable: false,
        sortable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
      },
      {
        field: 'restaurant_income',
        headerName: 'Income',
        width: 100,
        type: 'number',
        pinnable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
      },
      {
        field: 'commission',
        headerName: 'Commission',
        width: 100,
        type: 'number',
        pinnable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
      },
      {
        field: 'plan_title',
        headerName: 'Plan',
        width: 130,
        type: 'string',
        pinnable: false,
        sortable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
      },
      {
        field: 'price',
        headerName: 'Price',
        width: 100,
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        pinnable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
      },
      {
        field: 'off_days',
        headerName: 'Off',
        width: 80,
        type: 'string',
        pinnable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
      },
      {
        field: 'area_name',
        headerName: 'Area',
        width: 130,
        filterable: false,
        pinnable: false,
        hideable: false,
        disableReorder: true,
        type: 'string',

        renderCell: (params: GridRenderCellParams) => (
          <Tooltip
            title={
              <>
                <div>Area: {params.row.area_name}</div>
                <div>Block: {params.row.block}</div>
                <div>Street: {params.row.street}</div>
                <div>Avenue: {params.row.avenue}</div>
                <div>House: {params.row.house}</div>
              </>
            }
          >
            <div style={{ cursor: 'pointer' }}>{params.row.area_name}</div>
          </Tooltip>
        ),
      },
      {
        field: 'checkout_notes',
        headerName: 'Notes',
        width: 120,
        type: 'string',
        editable: true,
        pinnable: false,
        sortable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
        renderCell(params: GridRenderCellParams) {
          return params.row.checkout_notes || '-';
        },
        // renderEditCell: user?.is_allowed_to_make_changes ? renderCheckoutNotesEditInputCell : null,
        renderEditCell: renderCheckoutNotesEditInputCell,
      },
      {
        field: 'customer_comment',
        headerName: 'Comment',
        width: 120,
        type: 'string',
        editable: true,
        pinnable: false,
        sortable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
        renderCell(params: GridRenderCellParams) {
          return params.row.customer_comment || '-';
        },
        // renderEditCell: user?.is_allowed_to_make_changes ? renderCommentEditInputCell : null,
        renderEditCell: renderCommentEditInputCell,
      },


      {
        field: 'paused_dates',
        headerName: 'Paused Dates',
        width: 120,
        type: 'string',
        editable: false,
        pinnable: false,
        sortable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
        renderCell: renderPausedDatesCell,
      },
  
      {
        field: 'created_at',
        headerName: 'Date Created',
        width: 120,
        type: 'date',
        pinnable: false,
        sortable: true,
        hideable: false,
        disableReorder: true,
        filterable: false,
        valueFormatter: (params: string) => fDate(params, 'dd/M/yyyy'),
      },
      {
        field: 'delivery_time',
        headerName: 'Delivery Time',
        width: 150,
        editable: true,
        pinnable: false,
        sortable: false,
        hideable: false,
        type: 'singleSelect',
        filterable: false,
        disableReorder: true,
        // renderEditCell: user?.is_allowed_to_make_changes ? renderSelectEditInputCell : null,
        renderEditCell: renderSelectEditInputCell,
        renderCell: (params: GridRenderCellParams) => (
          <div style={{ cursor: 'pointer' }}>
            {dayjs(params.row.start_time, 'HH:mm:ss').format('hh:mm A')} -{' '}
            {dayjs(params.row.end_time, 'HH:mm:ss').format('hh:mm A')}
          </div>
        ),
      },
      {
        field: 'start_date',
        headerName: 'Period',
        width: 200,
        pinnable: false,
        hideable: false,
        filterable: false,
        disableReorder: true,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip
            title={
              <>
                <div>Start Date: {params.row.start_date}</div>
                <div>End Date: {params.row.end_date}</div>
              </>
            }
          >
            <div style={{ cursor: 'pointer' }}>
              {fDate(params.row.start_date, 'dd/M/yyyy')} <b>to</b>{' '}
              {fDate(params.row.end_date, 'dd/M/yyyy')}
            </div>
          </Tooltip>
        ),
      },
      {
        field: 'extended_date_range',
        headerName: 'Extend',
        width: 200,
        type: 'actions',
        disableReorder: true,
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.row.subscription_status === 'active' ||
            params.row.subscription_status === 'paused' ? (
              <Button
                variant="soft"
                size="small"
                color="success"
                startIcon={<Iconify icon="mdi:calendar-plus" />}
                onClick={() =>
                  setOpenExtend({
                    open: true,
                    id: `${params.id}`,
                  })
                }
              >
                Extend Subscription
              </Button>
            ) : (
              ''
            )}
          </>
        ),
      },
    ],
    subadmin: [
      { field: 'phone_number', headerName: 'Phone Number', width: 120, filterable: false },
      {
        field: 'subscription_status',
        headerName: 'Status',
        width: 120,
        type: 'singleSelect',
        valueOptions: ['old', 'active', 'paused', 'cancelled'],
        filterable: false,
        pinnable: false,
        sortable: false,
        hideable: false,
        disableReorder: true,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip title={params.row.paused_from_to || ''}>
            <Chip
              label={params.row.subscription_status}
              // eslint-disable-next-line no-nested-ternary
              color={
                // eslint-disable-next-line no-nested-ternary
                params.row.subscription_status === 'active'
                  ? 'success'
                  : params.row.subscription_status === 'paused'
                  ? 'warning'
                  : 'error'
              }
              size="small"
            />
          </Tooltip>
        ),
        valueGetter: (subscription_status: string) => subscription_status,
      },
      {
        field: 'plan_title',
        headerName: 'Plan',
        width: 130,
        type: 'string',
        pinnable: false,
        sortable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
      },
      {
        field: 'period',
        headerName: 'Period',
        width: 80,
        type: 'number',
        pinnable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
      },
      {
        field: 'off_days',
        headerName: 'Off',
        width: 80,
        type: 'string',
        pinnable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
      },
      {
        field: 'start_date',
        headerName: 'Period',
        width: 200,
        pinnable: false,
        hideable: false,
        filterable: false,
        disableReorder: true,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip
            title={
              <>
                <div>Start Date: {params.row.start_date}</div>
                <div>End Date: {params.row.end_date}</div>
              </>
            }
          >
            <div style={{ cursor: 'pointer' }}>
              {fDate(params.row.start_date, 'dd/M/yyyy')} <b>to</b>{' '}
              {fDate(params.row.end_date, 'dd/M/yyyy')}
            </div>
          </Tooltip>
        ),
      },
     
      {
        field: 'delivery_time',
        headerName: 'Delivery Time',
        width: 150,
        editable: true,
        pinnable: false,
        sortable: false,
        hideable: false,
        type: 'singleSelect',
        filterable: false,
        disableReorder: true,

        // renderEditCell: user?.is_allowed_to_make_changes ? renderSelectEditInputCell : null,
        renderEditCell: renderSelectEditInputCell,

        renderCell: (params: GridRenderCellParams) => (
          <div style={{ cursor: 'pointer' }}>
            {dayjs(params.row.start_time, 'HH:mm:ss').format('hh:mm A')} -{' '}
            {dayjs(params.row.end_time, 'HH:mm:ss').format('hh:mm A')}
          </div>
        ),
      },
      {
        field: 'checkout_notes',
        headerName: 'Notes',
        width: 120,
        type: 'string',
        editable: true,
        pinnable: false,
        sortable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
        renderCell(params: GridRenderCellParams) {
          return params.row.checkout_notes || '-';
        },
        // renderEditCell: user?.is_allowed_to_make_changes ? renderCheckoutNotesEditInputCell : null,
        renderEditCell: renderCheckoutNotesEditInputCell,
      },

      {
        field: 'paused_dates',
        headerName: 'Paused Dates',
        width: 120,
        type: 'string',
        editable: false,
        pinnable: false,
        sortable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
        renderCell: renderPausedDatesCell,
      },
      {
        field: 'price',
        headerName: 'Price',
        width: 100,
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        pinnable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
      },
      {
        field: 'restaurant_income',
        headerName: 'Income',
        width: 100,
        pinnable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
      },

      // ...(user?.is_allowed_to_make_changes
      //   ? [
      {
        field: 'extended_date_range',
        headerName: 'Extend',
        width: 200,
        type: 'actions',
        disableReorder: true,
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.row.subscription_status === 'active' ||
            params.row.subscription_status === 'paused' ? (
              <Button
                variant="soft"
                size="small"
                color="success"
                startIcon={<Iconify icon="mdi:calendar-plus" />}
                onClick={() =>
                  setOpenExtend({
                    open: true,
                    id: `${params.id}`,
                  })
                }
              >
                Extend Subscription
              </Button>
            ) : (
              ''
            )}
          </>
        ),
      },
      // ]
      // : [])
    ],
  };

  const performance_detail_columns =
    user?.type === 'Superuser' ? columnsConfig.superuser : columnsConfig.subadmin;

  useEffect(() => {
    const performance_detail_rows = performance?.results?.map(
      (detail: IPerformanceSubadminSummaryDetails | IPerformanceSuperuserSummaryDetails) => ({
        id: detail.id,
        display_id: detail.id?.slice(24),
        phone_number: detail.phone_number?.slice(4),
        ...(user?.type === 'Superuser'
          ? {
              // @ts-ignore
              restaurant: detail?.restaurant || '-',
              // @ts-ignore
              restaurant_income: detail?.restaurant_income || '-',
              // @ts-ignore
              commission: detail?.commission || '-',
            }
          : {}),
        ...(user?.type === 'Subadmin' || user?.type === 'Sub-User'
          ? {
              // @ts-ignore
              restaurant_income: detail?.restaurant_income || '-',
            }
          : {}),

        plan_title: detail.plan_title || '-',
        period: detail.period || '-',
        off_days: detail.off_days || '-',
        price: detail.price || '-',
        created_at: new Date(detail?.created_at) || '-',
        start_date: detail.start_date || '-',
        end_date: detail.end_date || '-',
        area_name: detail?.personal_detail?.area_name || '-',
        block: detail?.personal_detail?.block || '-',
        street: detail?.personal_detail?.street || '-',
        avenue: detail?.personal_detail?.avenue || '-',
        house: detail?.personal_detail?.house || '-',
        start_time: detail?.delivery_time?.start_time || '-',
        end_time: detail?.delivery_time?.end_time || '-',
        restaurant_slug: detail?.restaurant_slug || '-',
        delivery_time_id: detail?.delivery_time?.id || '-',
        checkout_notes: detail?.checkout_notes || '',
        customer_comment: detail?.customer_comment || '',
        customer_id: detail?.customer_id || 0,
        subscription_status: detail?.subscription_status || '-',
        payment_fees: Number(detail?.payment_fees) || 0,
        name: detail?.name || '-',
        cum_orders: detail?.cum_orders || 0,
        paused_from_to: detail?.paused_from_to || '',
        paused_dates: detail?.paused_dates || [],
      })
    );

    setRows(performance_detail_rows);
  }, [performance, user?.type]);

  const handleExtendSubmit = async (sub_id: string, days: number) => {
    try {
      const rowIndex = rows.findIndex((row) => row.id === sub_id);
      if (rowIndex < 0) return;

      const data = await updateCustomerExtendSubscription(sub_id, days);
      const updatedRows = [...rows];
      updatedRows[rowIndex] = { ...updatedRows[rowIndex], end_date: data.end_date };

      setRows(updatedRows);
      enqueueSnackbar('Subscription extended successfully', {
        variant: 'success',
      });
      setOpenExtend({ open: false, id: '' });
    } catch (err) {
      enqueueSnackbar(err?.detail || 'Something went wrong', {
        variant: 'error',
      });
      setOpenExtend({ open: false, id: '' });
    }
  };

  const rowCountRef = useRef(performance?.count || 0);

  const rowCount = useMemo(() => {
    if (performance?.count !== undefined) {
      rowCountRef.current = performance?.count;
    }
    return rowCountRef.current;
  }, [performance?.count]);

  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const handleCellClick = useCallback((params: GridCellParams, event: React.MouseEvent) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (
      (event.target as any).nodeType === 1 &&
      !event.currentTarget.contains(event.target as Element)
    ) {
      return;
    }

    setCellModesModel((prevModel) => ({
      // Revert the mode of the other cells from other rows
      ...Object.keys(prevModel).reduce(
        (acc, id) => ({
          ...acc,
          [id]: Object.keys(prevModel[id]).reduce(
            (acc2, field) => ({
              ...acc2,
              [field]: { mode: GridCellModes.View },
            }),
            {}
          ),
        }),
        {}
      ),
      [params.id]: {
        // Revert the mode of other cells in the same row
        ...Object.keys(prevModel[params.id] || {}).reduce(
          (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
          {}
        ),
        [params.field]: { mode: GridCellModes.Edit },
      },
    }));
  }, []);

  const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  const handleDownloadExcel = async () => {
    try {
      setIsLoadingExcel(true);
      const response = await GetSubscriptionsExcel(phone_number);
      const blob = new Blob([response], { type: 'application/excel' });
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = 'SubscriptionsExcelData.xlsx';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(downloadUrl);
      a.remove();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingExcel(false);
    }
  };

  return (
    <RoleBasedGuard hasContent roles={['Subadmin', 'Superuser', 'Sub-User']} sx={{ py: 10 }}>
      <Grid display="flex" spacing={2} alignItems="center">
        <TextField
          label="Phone Number"
          sx={{ my: 2, flex: 1 }}
          variant="outlined"
          type="tel"
          value={phone_number || ''}
          onChange={(e) => updatePhoneNumber(e.target.value)}
        />

        <LoadingButton
          loading={isLoadingExcel}
          size="large"
          sx={{ mx: 1 }}
          startIcon={<CloudDownloadRoundedIcon />}
          onClick={handleDownloadExcel}
          variant="contained"
        >
          Export as Excel
        </LoadingButton>
      </Grid>

      <div style={{ height: 1200, width: '100%' }}>
        <DataGridPro
          disableRowSelectionOnClick
          pagination
          autoHeight
          initialState={{ pinnedColumns: { left: ['phone_number'] } }}
          paginationModel={paginationModel}
          onPaginationModelChange={updatePaginationModel}
          pageSizeOptions={[25, 50, 100]}
          rowCount={rowCount}
          paginationMode="server"
          columns={performance_detail_columns}
          rows={rows || []}
          loading={performanceLoading}
          checkboxSelection={false}
          rowsLoadingMode="client"
          cellModesModel={cellModesModel}
          onCellModesModelChange={handleCellModesModelChange}
          onCellClick={handleCellClick}
        />
      </div>

      <Dialog
        fullWidth
        open={openExtend.open}
        onClose={() => setOpenExtend({ open: false, id: '' })}
      >
        <DialogTitle>Extend Subscription</DialogTitle>

        <DialogContent sx={{ width: '100%', py: 1 }}>
          <DialogContentText sx={{ my: 1 }}>Extend days for this subscription</DialogContentText>
          <Select
            fullWidth
            value={extendedDays}
            onChange={(event) => setExtendedDays(event.target.value as number)}
          >
            <MenuItem value={1}>1 Day</MenuItem>
            <MenuItem value={2}>2 Days</MenuItem>
            <MenuItem value={3}>3 Days</MenuItem>
            <MenuItem value={4}>4 Days</MenuItem>
            <MenuItem value={5}>5 Days</MenuItem>
            <MenuItem value={6}>6 Days</MenuItem>
            <MenuItem value={7}>7 Days</MenuItem>
          </Select>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenExtend({ open: false, id: '' })}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => handleExtendSubmit(openExtend.id, extendedDays)}
          >
            Extend
          </Button>
        </DialogActions>
      </Dialog>
    </RoleBasedGuard>
  );
}
