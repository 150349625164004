import { useMemo } from "react";
import { IOrderApiResponse } from "src/types/order";
import { endpoints, fetcher } from "src/utils/axios";
import useSWR from "swr";

// get customer list
export function useGetOrders(
    page: number,
    page_size: number,
    area: string | null,
    start_date: string | null,
    end_date: string | null,
) {
    const URL = endpoints.orders.list;
    const queryParams = new URLSearchParams({ page: page.toString(), page_size: page_size.toString() });

    if (area) queryParams.append('area', area);
    if (start_date) queryParams.append('start_date', start_date);
    if (end_date) queryParams.append('end_date', end_date);

    const URL_WITH_PARAMS = `${URL}?${queryParams.toString()}`;

    const { data, isLoading, error, isValidating } = useSWR(URL_WITH_PARAMS, fetcher);

    const memoizedValue = useMemo(
        () => ({
            orders: data as IOrderApiResponse,
            ordersLoading: isLoading,
            ordersError: error,
            ordersValidating: isValidating,
            ordersEmpty: !isLoading && !data?.length,
        }),
        [data, error, isLoading, isValidating]
    );

    return memoizedValue;
}

export function useGetOrdersSummary() {
    const URL = endpoints.orders.summary;

    const { data, isLoading, error } = useSWR(URL, fetcher);

    const memoizedValue = useMemo(
        () => ({
            ordersSummary: data as {
                current_month: number,
                last_month: number,
            },
            ordersSummaryLoading: isLoading,
            ordersSummaryError: error,
        }),
        [data, error, isLoading]
    );

    return memoizedValue;
}