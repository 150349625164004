/* eslint-disable no-nested-ternary */
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';


import { useBoolean } from 'src/hooks/use-boolean';


import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  TableSkeleton,
  useTable,
} from 'src/components/table';

import { IUserTableFilters, IUserTableFilterValue } from 'src/types/user';

import { useGetTransactions } from 'src/api/transaction';
import { RoleBasedGuard } from 'src/auth/guard';
import TransactionTableRow from '../transaction-table-row';
import TransactionTableToolbar from '../transaction-table-toolbar';

// ----------------------------------------------------------------------


// const FILTER_OPTIONS = [
//   { value: 'all', label: 'All' },
//   // { value: 'old', label: 'Old' },
//   // { value: 'active', label: 'Active' },
// ];


const TABLE_HEAD = [
  { id: 'id', label: 'ID', width: 180 },
  { id: 'payment_method', label: 'Payment Method', width: 220 },
  { id: 'status', label: 'Status', width: 180 },
  { id: 'phone', label: 'Phone' },
  { id: 'amount', label: 'Amount' },
];



const defaultFilters: IUserTableFilters = {
  name: undefined,
  status: 'all',
};

// ----------------------------------------------------------------------

export default function TransactionsListView() {
  const table = useTable({
    defaultRowsPerPage: 25
  });
  const [filters, setFilters] = useState(defaultFilters);
  const { transactions, transactionsLoading, transactionsError } = useGetTransactions(table.page + 1, table.rowsPerPage, table.order, filters.name);

  const confirm = useBoolean();

  const [tableData, setTableData] = useState(transactions?.results || []);

  const dataFiltered = tableData || [];

  const dataInPage = dataFiltered?.slice(
    0,
    table.rowsPerPage
  );


  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name: string, value: IUserTableFilterValue) => {
      const newFilters = { ...filters, [name]: value };
      setFilters(newFilters);
      table.onResetPage(); // Reset to the first page
      // Save to Local Storage
      localStorage.setItem('transactionFilters', JSON.stringify(newFilters));
      localStorage.setItem('transactionPagination', JSON.stringify({ page: 0, rowsPerPage: table.rowsPerPage }));
    },
    [filters, table]
  );


  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem('transactionFilters') || '{}');
    const savedPagination = JSON.parse(localStorage.getItem('transactionPagination') || '{}');

    if (Object.keys(savedFilters).length > 0) {
      setFilters(savedFilters);
    }

    if (savedPagination.page !== undefined && savedPagination.rowsPerPage !== undefined) {
      table.setPage(savedPagination.page);
      table.setRowsPerPage(savedPagination.rowsPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = useCallback(
    (event: any, newPage: number) => {
      table.setPage(newPage);
      localStorage.setItem('transactionPagination', JSON.stringify({ page: newPage, rowsPerPage: table.rowsPerPage }));
    },
    [table]
  );

  const handleRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage = parseInt(event.target.value, 10);
      table.setRowsPerPage(newRowsPerPage);
      localStorage.setItem('transactionPagination', JSON.stringify({ page: table.page, rowsPerPage: newRowsPerPage }));
    },
    [table]
  );

  const handleDeleteRow = useCallback(
    (id: string) => {
      const deleteRow = tableData.filter((row: any) => row.id.toString() !== id);
      setTableData(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table, tableData]
  );




  useEffect(() => {
    if (transactions?.results && !transactionsLoading) {
      setTableData(transactions?.results);
    }
  }, [transactions?.results, transactionsLoading]);

  return (
    <RoleBasedGuard hasContent roles={["Superuser"]} sx={{ py: 10 }}>

      <Card>


        <TransactionTableToolbar
          filters={filters}
          onFilters={handleFilters}
          roleOptions={[]}
        />



        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected.length}
            rowCount={tableData.length}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked,
                tableData.map((row: any) => row.id.toString())
              )
            }
            action={
              <Tooltip title="Delete">
                <IconButton color="primary" onClick={confirm.onTrue}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            }
          />

          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
              />

              <TableBody>
                {transactionsError
                  ?
                  <h6>Failed to load transactions</h6>
                  :
                  transactionsLoading
                    ?
                    (
                      [...Array(table.rowsPerPage)].map((i, index) => (
                        <TableSkeleton key={index} sx={{ height: denseHeight }} />
                      ))
                    )
                    :
                    dataFiltered
                      .slice(
                        0,
                        table.rowsPerPage
                      )
                      .map((row) => (
                        <TransactionTableRow
                          key={row.id}
                          row={row}
                          id={row.id}
                          selected={table.selected.includes(row.id.toString())}
                          onSelectRow={() => table.onSelectRow(row.id.toString())}
                          onDeleteRow={() => handleDeleteRow(row.id.toString())}
                        />
                      ))}
                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={transactions?.count || 0}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[25, 50, 100]}
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>

    </RoleBasedGuard>

  );
}

// ----------------------------------------------------------------------

