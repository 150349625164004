import { ApexOptions } from 'apexcharts';
import { useCallback, useState } from 'react';

import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Card, { CardProps } from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import { MenuItem } from '@mui/material';
import { getLast12MonthsNames } from 'src/api/performance';
import Chart, { useChart } from 'src/components/chart';
import { usePopover } from 'src/components/custom-popover';
import CustomPopover from 'src/components/custom-popover/custom-popover';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  filterKey?: [] | [{ id: number; title: string }];
  chart: {
    categories?: string[];
    colors?: string[];
    series: {
      year: string;
      data: {
        name: string;
        data: number[];
      }[];
    }[];
    options?: ApexOptions;
  };
}

export default function EcommerceYearlySales({
  title,
  subheader,
  chart,
  filterKey,
  ...other
}: Props) {
  const { colors, categories, series, options } = chart;

  const popover = usePopover();

  const [seriesData, setSeriesData] = useState('last 12 months');
  const [, setFilterPlans] = useState<{ id: number; title: string } | null>(null);

  const chartOptions = useChart({
    colors,
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    xaxis: {
      categories: seriesData === 'last 12 months' ? getLast12MonthsNames() : categories,
    },
    yaxis: {
      labels: {
        formatter(val, opts) {
          return Math.round(val).toString();
        },
      },
    },
    ...options,
  });

  const handleChangeSeries = useCallback(
    (newValue: string) => {
      popover.onClose();
      setSeriesData(newValue);
    },
    [popover]
  );

  const handleChangePlans = useCallback(
    (newValue: { id: number; title: string }) => {
      popover.onClose();
      setFilterPlans(newValue);
    },
    [popover]
  );

  return (
    <>
      <Card {...other}>
        <CardHeader
          title={title}
          subheader={subheader}
          action={
            <ButtonBase
              onClick={popover.onOpen}
              sx={{
                pl: 1,
                py: 0.5,
                pr: 0.5,
                borderRadius: 1,
                typography: 'subtitle2',
                bgcolor: 'background.neutral',
              }}
            >
              {seriesData}

              <Iconify
                width={16}
                icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                sx={{ ml: 0.5 }}
              />
            </ButtonBase>
          }
        />

        {series.map((item) => (
          <Box key={item.year} sx={{ mt: 3, mx: 3 }}>
            {`${item.year}` === seriesData && (
              <Chart
                dir="ltr"
                type="area"
                series={item.data}
                options={chartOptions}
                width="100%"
                height={364}
              />
            )}
          </Box>
        ))}
      </Card>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140 }}>
        {filterKey?.map((option) =>
          typeof option !== 'object' ? (
            <MenuItem
              key={option}
              selected={`${option}` === seriesData}
              onClick={() => handleChangeSeries(`${option}`)}
            >
              {option}
            </MenuItem>
          ) : (
            <MenuItem
              key={option.title}
              selected={`${option.title}` === seriesData}
              onClick={() => handleChangePlans(option)}
            >
              {option.title}
            </MenuItem>
          )
        )}
      </CustomPopover>
    </>
  );
}
