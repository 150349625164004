import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

import { ConfirmDialog } from 'src/components/custom-dialog';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  TableSkeleton,
  useTable,
} from 'src/components/table';

import {
  IRestaurantPlanCard,
  IRestaurantTableFilters,
  IRestaurantTableFilterValue,
} from 'src/types/user';

import {
  deleteRestaurantPlan,
  handleDuplicatePlan,
  useGetRestaurantPlans,
} from 'src/api/restaurant';
import { useAuthContext } from 'src/auth/hooks';
import RestaurantTableRow from '../restaurant-table-row';
import RestaurantTableToolbar from '../restaurant-table-toolbar';

// ----------------------------------------------------------------------

const defaultFilters: IRestaurantTableFilters = {
  title: '',
  is_available: false,
};

// ----------------------------------------------------------------------

type TableProps = {
  id: string | undefined;
  tableConfig: TableConfig;
};

export default function RestaurantPlansListView({ id, tableConfig }: TableProps) {
  // Retrieve initial filters and pagination from localStorage
  const initialFilters = JSON.parse(
    localStorage.getItem('restaurantPlanFilters') || JSON.stringify(defaultFilters)
  );
  const initialPagination = JSON.parse(
    localStorage.getItem('restaurantPlanPagination') || '{"page": 0, "rowsPerPage": 25}'
  );

  const [filters, setFilters] = useState(initialFilters);
  const table = useTable({
    defaultRowsPerPage: initialPagination.rowsPerPage,
    defaultCurrentPage: initialPagination.page,
  });

  const { user } = useAuthContext();

  const { restaurantPlans, restaurantPlansLoading } = useGetRestaurantPlans(id, filters.title);

  const router = useRouter();

  const confirm = useBoolean();

  const [tableData, setTableData] = useState(restaurantPlans);

  useEffect(() => {
    if (!restaurantPlansLoading && restaurantPlans) {
      setTableData(restaurantPlans);
    }
  }, [restaurantPlans, restaurantPlansLoading]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = !id || (!dataFiltered.length && canReset) || !dataFiltered.length;

  const hasAllPermissions = ['22'].every((permission) => user?.permissionIds?.includes(permission));

  const handleDuplicatePlans = useCallback(
    async (uid: string) => {
      const duplicatedPlan = await handleDuplicatePlan(uid);
      if (duplicatedPlan && duplicatedPlan.id) {
        setTableData([duplicatedPlan, ...tableData]);
      }
    },
    [tableData]
  );

  const handleDeleteRow = useCallback(
    async (uid: string) => {
      await deleteRestaurantPlan(uid);
      const deleteRow = tableData.filter((row) => row.id !== uid);
      setTableData(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table, tableData]
  );

  const handleDeleteRows = useCallback(async () => {
    const deleteRows = tableData.filter(async (row) => {
      if (table.selected.includes(row.id)) {
        await deleteRestaurantPlan(row.id);
      }
      return !table.selected.includes(row.id);
    });
    setTableData(deleteRows);

    table.onUpdatePageDeleteRows({
      totalRows: tableData.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    });
  }, [dataFiltered.length, dataInPage.length, table, tableData]);

  const handleEditRow = useCallback(
    (uid: string) => {
      if (user?.type !== 'Sub-User') {
        router.push(paths.dashboard.restaurant.plan.edit(uid));
      } else if (hasAllPermissions) {
        router.push(paths.dashboard.restaurant.plan.edit(uid));
      }
    },
    [user?.type, hasAllPermissions, router]
  );

  const handleFilters = useCallback(
    (name: string, value: IRestaurantTableFilterValue) => {
      const newFilters = { ...filters, [name]: value };
      setFilters(newFilters);
      table.onResetPage();
      localStorage.setItem('restaurantPlanFilters', JSON.stringify(newFilters));
      // Reset pagination in localStorage when filters change
      localStorage.setItem(
        'restaurantPlanPagination',
        JSON.stringify({ page: 0, rowsPerPage: table.rowsPerPage })
      );
    },
    [filters, table]
  );

  return (
    <>
      <Container maxWidth={false}>
        <Card>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <RestaurantTableToolbar filters={filters} onFilters={handleFilters} />
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 940 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={tableConfig.header}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                />

                <TableBody>
                  {restaurantPlansLoading
                    ? [...Array(table.rowsPerPage)].map((i, index) => (
                        <TableSkeleton key={index} sx={{ height: denseHeight }} />
                      ))
                    : dataFiltered
                        .slice(
                          table.page * table.rowsPerPage,
                          table.page * table.rowsPerPage + table.rowsPerPage
                        )
                        .map((row) => (
                          <RestaurantTableRow
                            table={tableConfig.id}
                            key={row.id}
                            row={row}
                            id={row.id}
                            selected={table.selected.includes(row.id)}
                            onDeleteRow={() => handleDeleteRow(row.id)}
                            onEditRow={() => handleEditRow(row.id)}
                            onDuplicatePlan={() => handleDuplicatePlans(row.id)}
                          />
                        ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
}: {
  inputData: IRestaurantPlanCard[];
  comparator: (a: any, b: any) => number;
  filters: IRestaurantTableFilters;
}) {
  const { is_available } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (is_available) {
    inputData = inputData.filter((plan) => plan.is_available === is_available);
  }

  return inputData;
}
