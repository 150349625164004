
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import * as Yup from 'yup';


import { InputAdornment, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useGetAllCities } from 'src/api/account';
import { updateCustomerComment } from 'src/api/customer';
import { scrollToFirstError } from 'src/auth/context/jwt/utils';
import { useAuthContext } from 'src/auth/hooks';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import Iconify from 'src/components/iconify';
import { useParams } from 'src/routes/hooks';


// ----------------------------------------------------------------------





export default function CustomerAccountGeneral(
    { user_profile }: { user_profile: any | undefined }) {

    const { cities, citiesLoading } = useGetAllCities();
    const params = useParams();
    const { user } = useAuthContext();
    const { enqueueSnackbar } = useSnackbar();





    const UpdateRestaurantSchema = Yup.object().shape({
        name: Yup.string(),
        logo: Yup.mixed<any>().nullable(),
        cover: Yup.mixed<any>().nullable(),
        contact_number: Yup.string(),
        supplier_code: Yup.string(),
        userId: Yup.string(),
        house: Yup.string(),
        street: Yup.string(),
        area: Yup.string(),
        block: Yup.string(),
        avenue: Yup.string(),
        current_restaurant: Yup.string().nullable(),
        type: Yup.string(),
        comment: Yup.string().nullable().required("Comment is required"),
        status: Yup.string()
    });


    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(UpdateRestaurantSchema),
        defaultValues: {
            type: user_profile.type,
            name: user_profile.user.name,
            contact_number: user_profile.user.phone_number?.slice(4),
            status: user_profile.user.status,
            supplier_code: '',
            userId: user_profile.user.id,
            house: user_profile.user.personal_detail.house,
            street: user_profile.user.personal_detail.street,
            area: "",
            block: user_profile.user.personal_detail.block,
            avenue: user_profile.user.personal_detail.avenue,
            current_restaurant: user_profile.current_restaurant,
            comment: user_profile.comment,

        },
    });


    const {
        handleSubmit,
        formState: { isSubmitting },
        setValue

    } = methods;

    useEffect(() => {

        if (!citiesLoading && cities) {
            const areaName = cities?.find(city => city.id === user_profile.user.personal_detail.area)?.name || "";
            setValue("area", areaName)
        }

    }, [cities, citiesLoading, setValue, user_profile.user.personal_detail.area]);





    const onSubmit = handleSubmit(async (data) => {
        try {

            if (data.comment && params.id) {

                const response = await updateCustomerComment(params.id, data.comment);
                enqueueSnackbar(response.detail, { variant: 'success' });
            }

        } catch (error) {
            console.error(error);
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    }, (e) => {
        scrollToFirstError(e);
    });



    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>

            <Grid container mt={3} spacing={3}>

                <Grid xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                        <Typography variant="h5" sx={{ my: 3 }}>General Details</Typography>

                        <Box
                            rowGap={3}
                            columnGap={2}
                            display="grid"
                            gridTemplateColumns={{
                                xs: 'repeat(1, 1fr)',
                                sm: 'repeat(1, 1fr)',
                            }}
                        >
                            <RHFTextField disabled name="name" label="Name" />

                            <RHFTextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Iconify icon="twemoji:flag-kuwait" sx={{ color: 'text.disabled' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                disabled
                                name="contact_number"
                                label="Phone Number"
                            />

                        </Box>
                        {/* <RHFTextField disabled name="status" label="Status" sx={{ mt: 3 }} /> */}
                        {
                            user?.type === "Superuser"
                            &&
                            <RHFTextField disabled name="type" label="Type" sx={{ mt: 3 }} />
                        }

                        {
                            user?.type === "Superuser" && <RHFTextField disabled name="status" label="Status" sx={{ mt: 3 }} />
                        }

                        {
                            user?.type === "Superuser"
                            && <RHFTextField disabled name="current_restaurant" label="Current Restaurant" sx={{ mt: 3 }} />
                        }

                        <Typography variant="h5" sx={{ my: 3 }}>Personal Details</Typography>
                        {
                            user?.type === "Superuser"
                            &&
                            <RHFTextField disabled name="userId" label="User Id" sx={{ mt: 3 }} />

                        }

                        <Box
                            rowGap={3}
                            columnGap={2}
                            display="grid"
                            gridTemplateColumns={{
                                xs: 'repeat(5, 1fr)',
                                sm: 'repeat(5, 1fr)',
                            }}
                        >

                            <RHFTextField name="area" disabled label="Area" sx={{ mt: 3 }} />
                            <RHFTextField name="avenue" disabled label="Avenue" sx={{ mt: 3 }} />
                            <RHFTextField name="block" disabled label="Block" sx={{ mt: 3 }} />
                            <RHFTextField name="house" disabled label="House" sx={{ mt: 3 }} />
                            <RHFTextField name="street" disabled label="Street" sx={{ mt: 3 }} />


                        </Box>







                        {user?.type === "Superuser" && <RHFTextField
                            name="comment"
                            focused
                            label="Comments"
                            sx={{ mt: 3 }}
                        />
                        }



                        {user?.type === "Superuser" && <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>

                            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                Update Comment
                            </LoadingButton>
                        </Stack>}
                    </Card>
                </Grid>

            </Grid>

        </FormProvider>
    );
}









