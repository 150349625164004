import { Button, Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useGetMyRestaurants, useGetRestaurants, useGetSubuserRoles } from 'src/api/restaurant';
import { useAuthContext } from 'src/auth/hooks';
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import SubUserAccountGeneral from 'src/sections/user/sub-user-account-general';


// ----------------------------------------------------------------------



export default function SubUserCreateProfile() {
    const { user } = useAuthContext();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { restaurants, restaurantsLoading } = user?.type === "Superuser" ? useGetRestaurants() : { restaurants: [], restaurantsLoading: false };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { restaurants: restaurantSubadmin, restaurantsLoading: restaurantsLoadingSubadmin } = user?.type === "Subadmin" ? useGetMyRestaurants() : { restaurants: [], restaurantsLoading: false };

    const { roles, rolesLoading } = useGetSubuserRoles();

    return (
        <Container maxWidth={false}>

            <Helmet>
                <title> Subuser: Create</title>
            </Helmet>

            <Button
                size='small'
                variant='soft'
                component={RouterLink}
                href={paths.dashboard.sub_users.list}
                startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
            >
                Back to Subuser List
            </Button>

            <Typography sx={{ mt: 2 }} variant="h4">
                Create Subuser
            </Typography>

            {
                rolesLoading || (user?.type === "Superuser" ? restaurantsLoading : restaurantsLoadingSubadmin) ? <Typography sx={{ my: 3 }}>
                    Loading...
                </Typography>

                    :
                    <SubUserAccountGeneral
                        restaurants={user?.type === "Superuser" ? restaurants : restaurantSubadmin}
                        restaurantsLoading={restaurantsLoading}
                        roles={roles}
                        rolesLoading={rolesLoading}
                        user_profile={null}
                    />
            }

        </Container>
    );
}
