import { Button, Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { useGetAllCities, useGetUser } from 'src/api/account';
import Iconify from 'src/components/iconify/iconify';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import UserAccountGeneral from 'src/sections/user/user-account-general';


// ----------------------------------------------------------------------



export default function UserProfile() {

    const params = useParams();

    const { user, userLoading } = useGetUser(params.id as string);
    const { cities, citiesLoading } = useGetAllCities();




    return (
        <Container maxWidth={false}>

            <Helmet>
                <title> Dashboard: User Profile</title>
            </Helmet>

            <Button
                size='small'
                variant='soft'
                component={RouterLink}
                href={paths.dashboard.users.list}
                startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
            >
                Back to Users List
            </Button>

            {
                userLoading ? <Typography variant="h5" sx={{ my: 3 }}>
                    Loading...
                </Typography>

                    :
                    <UserAccountGeneral
                        cities={cities}
                        user_profile={user}
                        citiesLoading={citiesLoading}
                    />
            }

        </Container>
    );
}
