import { Chip } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KnetLogo from 'src/assets/icons/knet.svg';
import Iconify from 'src/components/iconify';
import Image from 'src/components/image/image';
import { ITransactionResult } from 'src/types/transaction';


// ----------------------------------------------------------------------

type Props = {
  selected: boolean;
  row: ITransactionResult;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  id: any
};

export default function TransactionTableRow({
  row,
  selected,
  onSelectRow,
  onDeleteRow,
  id
}: Props) {

  const getPaymentIcon = (paymentMethod: string) => {
    switch (paymentMethod) {
      case 'KNET':
        return <Image src={KnetLogo} sx={{ width: '40px' }} />;
      case 'APPLE PAY':
        return <Iconify sx={{ width: '50px' }} icon="logos:apple-pay" />;
      case 'GOOGLE PAY':
        return <Iconify sx={{ width: '50px' }} icon="logos:google-pay" />;
      default: // API might return other payment methods unexpectedly so default cannot be something known.
        return "-";
    }
  };

  return (
    <TableRow hover selected={selected} >
      {/* cursor: 'pointer', textDecoration: 'underline' */}
      <TableCell
        sx={{ whiteSpace: 'nowrap' }}
      >
        {row.invoice_id}
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {getPaymentIcon(row.payment_method)}
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {
          // eslint-disable-next-line no-nested-ternary
          row.status === 'Succss' ?
            <Chip size="small" label="SUCCESS" color="success" />
            :
            // eslint-disable-next-line no-nested-ternary
            row.status === 'Failed' ?

              <Chip size="small" label="FAILED" color="error" />
              :
              row.status === 'InProgress' ?
                <Chip size="small" label="PENDING" color="warning" />
                : row.status

        }
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {row.subscription.customer.user.phone_number?.slice(4)}
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {row.total_amount}
      </TableCell>

    </TableRow>
  );
}
