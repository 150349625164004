/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import * as Yup from 'yup';

import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  Button,
  Checkbox,
  Chip,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { createNewSubUser, deleteSubUser, updateSubUserDetails } from 'src/api/account';
import { scrollToFirstError } from 'src/auth/context/jwt/utils';
import { useAuthContext } from 'src/auth/hooks';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import Iconify from 'src/components/iconify';
import { PHONE_REGEX_EXPRESSION } from 'src/config-global';
import { useBoolean } from 'src/hooks/use-boolean';
import { paths } from 'src/routes/paths';
import { GroupedRoles, IRestaurant, IRestaurantCard, SubUserResponseObject } from 'src/types/user';
import { endpoints } from 'src/utils/axios';
import { useSWRConfig } from 'swr';

// ----------------------------------------------------------------------

const phoneRegExp = PHONE_REGEX_EXPRESSION;

type PermissionOption = {
  id: string;
  name: string;
  roleId: number; // The ID of the role to which this permission belongs
  roleName: string;
};

interface RoleWithPermissions {
  id: number; // or string if your role IDs are strings
  permissions: { id: string }[];
}

const getPermissionOptions = (roles: GroupedRoles[]): PermissionOption[] =>
  roles.flatMap((role) =>
    role.permissions.map((permission) => ({
      ...permission,
      roleId: role.id,
      roleName: role.name,
    }))
  );

export default function SubUserAccountGeneral({
  user_profile,
  restaurants,
  restaurantsLoading,
  roles,
  rolesLoading,
}: {
  user_profile: SubUserResponseObject | null;
  restaurants: IRestaurantCard[] | IRestaurant[];
  roles: GroupedRoles[];
  rolesLoading: boolean;
  restaurantsLoading: boolean;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const [expandedRoles /* setExpandedRoles */] = useState<string[]>([]);

  const { user } = useAuthContext();

  // const toggleRoleExpansion = (roleName: string) => {
  //     setExpandedRoles((currentExpandedRoles) => {
  //         if (currentExpandedRoles.includes(roleName)) {
  //             // Role is currently expanded, so we remove it to collapse
  //             return currentExpandedRoles.filter((name) => name !== roleName);
  //         }
  //         // Role is currently collapsed, so we add it to expand
  //         return [...currentExpandedRoles, roleName];

  //     });
  // };

  const UpdateSubUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').max(20, 'Name is too long'),
    restaurant:
      user?.type === 'Subadmin'
        ? Yup.string().optional()
        : Yup.string().required('Restaurant is required'),
    phone_number: Yup.string()
      .required('Contact number is required')
      .matches(phoneRegExp, 'Invalid phone number')
      .min(8, 'Phone number is too short')
      .max(8, 'Phone number is too long'),
    password: user_profile
      ? Yup.string()
      : Yup.string().required('Password is required').min(3, 'Password is too short'),
    confirm_password: user_profile
      ? Yup.string()
      : Yup.string()
          .required('Confirm Password is required')
          .oneOf([Yup.ref('password')], 'Passwords must match'),
    superuser: Yup.boolean(),
    isEdittable: Yup.boolean(),
  });

  const [selectedPermissions, setSelectedPermissions] = useState<PermissionOption[]>([]);

  const permissionOptions = useMemo(() => getPermissionOptions(roles), [roles]);

  // Handle permission selection
  const handleSelectPermission = (permissionOption: PermissionOption) => {
    setSelectedPermissions((currentSelectedPermissions) => {
      const isSelected = currentSelectedPermissions.some(
        (perm) => perm.id === permissionOption.id && perm.roleId === permissionOption.roleId
      );
      if (isSelected) {
        // Deselect the permission
        return currentSelectedPermissions.filter(
          (perm) => perm.id !== permissionOption.id || perm.roleId !== permissionOption.roleId
        );
      }
      // Select the permission
      return [...currentSelectedPermissions, permissionOption];
    });
  };

  // Determine if a role is fully selected
  const isRoleFullySelected = (
    roleName: string,
    roleId: number,
    argSelectedPermissions: PermissionOption[],
    argPermissionOptions: PermissionOption[]
  ): boolean => {
    const rolePermissions = argPermissionOptions.filter(
      (option) => option.roleName === roleName && option.roleId === roleId
    );
    return rolePermissions.every((permission) =>
      argSelectedPermissions.some(
        (selectedPermission) =>
          selectedPermission.id === permission.id && selectedPermission.roleId === roleId
      )
    );
  };

  // Determine if a role is partially selected
  const isRolePartiallySelected = (
    roleName: string,
    roleId: number,
    argSelectedPermissions: PermissionOption[],
    argPermissionOptions: PermissionOption[]
  ): boolean => {
    const rolePermissions = argPermissionOptions.filter(
      (option) => option.roleName === roleName && option.roleId === roleId
    );
    const isFullySelected = isRoleFullySelected(
      roleName,
      roleId,
      argSelectedPermissions,
      argPermissionOptions
    );
    return (
      rolePermissions.some((permission) =>
        argSelectedPermissions.some(
          (selectedPermission) =>
            selectedPermission.id === permission.id && selectedPermission.roleId === roleId
        )
      ) && !isFullySelected
    );
  };

  const handleSelectDeselectRole = (roleName: string) => {
    setSelectedPermissions((currentPermissions) => {
      // Find if any permission from this role is currently selected
      const isAnyPermissionFromRoleSelected = currentPermissions.some(
        (perm) => perm.roleName === roleName
      );

      if (isAnyPermissionFromRoleSelected) {
        // Deselect all permissions from this role
        return currentPermissions.filter((perm) => perm.roleName !== roleName);
      }
      // Select all permissions from this role
      const permissionsToSelect = permissionOptions.filter((perm) => perm.roleName === roleName);
      // Add these permissions, avoiding duplicates
      return [
        ...currentPermissions,
        ...permissionsToSelect.filter((perm) => !currentPermissions.includes(perm)),
      ];
    });
  };

  // Render a group of permissions with a checkbox to select/deselect all
  const renderGroup = (params: AutocompleteRenderGroupParams) => {
    const isExpanded = expandedRoles.includes(params.group);
    // Find the role ID for the current group; assuming the first permission in this group belongs to the role
    const roleId = permissionOptions.find((option) => option.roleName === params.group)?.roleId;
    const roleName = permissionOptions.find((option) => option.roleName === params.group)?.roleName;

    // Check if the role is fully selected or partially selected by considering both permission ID and role ID

    if (!roleId) return null;

    const fullySelected = isRoleFullySelected(
      params.group,
      roleId,
      selectedPermissions,
      permissionOptions
    );
    const partiallySelected = isRolePartiallySelected(
      params.group,
      roleId,
      selectedPermissions,
      permissionOptions
    );

    return (
      <li key={params.key}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Checkbox
              checked={fullySelected}
              indeterminate={!fullySelected && partiallySelected}
              onChange={() => roleName && handleSelectDeselectRole(roleName)} // Ensure roleId is valid before calling the function
            />
            <h5 style={{ textTransform: 'capitalize', margin: 0 }}>{params.group} </h5>

            <small style={{ color: 'black', fontSize: 12, opacity: 0.5, marginLeft: '5px' }}>
              {params.group === 'manager' && '(Everything)'}
              {params.group === 'content creator' && '(Plan & Food)'}

              {params.group === 'performance' && '(Finance)'}
              {params.group === 'kitchen' && '(Selected Food)'}
            </small>
          </div>
          {/* <div
                        role="button"
                        style={{ color: 'gray', cursor: 'pointer', textDecoration: 'underline', fontSize: '0.75rem', justifyContent: 'flex-start', marginLeft: '40px' }}
                        onClick={(event) => {
                            event.stopPropagation();
                            toggleRoleExpansion(params.group);
                        }}
                    >
                        {isExpanded ? 'Hide' : 'Show'}
                    </div> */}
        </div>

        {isExpanded && <div style={{ marginLeft: '40px' }}>{params.children}</div>}
      </li>
    );
  };

  const defaultValues = useMemo(
    () => ({
      name: user_profile?.user.name || '',
      phone_number: user_profile?.user.phone_number
        ? user_profile?.user.phone_number.slice(4)
        : '' || '',
      restaurant: user_profile ? user_profile.restaurant_permissions?.restaurant : '',
      password: '',
      confirm_password: '',
      userId: user_profile?.user.id || '',
      superuser: user_profile?.user.is_superuser || false,
      // isEdittable: !user_profile ,
      isEdittable: true,
    }),
    [user_profile]
  );

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(UpdateSubUserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
    setValue,
  } = methods;

  const values = watch();

  useEffect(() => {
    methods.reset(defaultValues);

    if (user_profile && user_profile.restaurant_permissions) {
      const selectedRestaurantInclude = restaurants.find(
        (restaurant) => restaurant.id === user_profile.restaurant_permissions.restaurant
      );
      if (selectedRestaurantInclude) {
        setValue('restaurant', selectedRestaurantInclude.id);
      }

      // Flatten the nested structure of roles and permissions into PermissionOption[]
      const userPermissions = user_profile.restaurant_permissions.roles.flatMap((role) =>
        role.permissions.map((permission) => ({
          id: permission.id,
          name: permission.name,
          roleId: role.id,
          roleName: role.name,
        }))
      );

      setSelectedPermissions(userPermissions);
    }
  }, [defaultValues, methods, restaurants, setValue, user_profile, user?.type]);

  const confirm = useBoolean();

  const onSubmit = handleSubmit(
    async (data) => {
      try {
        // Group selected permissions by role
        const rolesWithPermissions = selectedPermissions.reduce<RoleWithPermissions[]>(
          (acc, curr) => {
            const roleIndex = acc.findIndex((role) => role.id === curr.roleId);

            if (roleIndex > -1) {
              // Role already exists in the accumulator, push the current permission into its permissions array
              acc[roleIndex].permissions.push({ id: curr.id });
            } else {
              // Role not found, add a new role with the current permission
              acc.push({
                id: curr.roleId,
                permissions: [{ id: curr.id }],
              });
            }

            return acc;
          },
          []
        );

        // Construct the form_data with user details, selected restaurant, and grouped roles with permissions
        const form_data = {
          user: {
            phone_number: `+965${data.phone_number}`,
            name: data.name,
            password: data.password, // Include password only for new user creation, adjust as necessary
          },
          restaurant_permissions: {
            restaurant: data.restaurant || restaurants[0].id, // Include restaurant for subadmin
            roles: rolesWithPermissions,
          },
        };

        let response = null;

        if (user_profile) {
          // Update existing user details
          response = await updateSubUserDetails(user_profile.id, form_data);
          mutate((key) => typeof key === 'string' && key.startsWith(endpoints.subusers.list));
          mutate(endpoints.subusers.retrieve_user(`${user_profile.id}`));
        } else {
          // Create a new user
          response = await createNewSubUser(form_data);
          mutate((key) => typeof key === 'string' && key.startsWith(endpoints.subusers.list));
        }

        if (response) {
          enqueueSnackbar(`Subuser ${user_profile?.id ? 'updated' : 'created'} successfully`, {
            variant: 'success',
          });
          navigate(paths.dashboard.sub_users.list);
          // Refresh data or navigate as necessary
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(error?.detail ? error?.detail : 'Something went wrong', {
          variant: 'error',
        });
      }
    },
    (e) => {
      scrollToFirstError(e);
    }
  );

  const handleDeleteSubUser = async () => {
    if (!user_profile) return;
    try {
      // Delete the user
      await deleteSubUser(user_profile?.id);
      enqueueSnackbar('Subuser deleted successfully', { variant: 'success' });
      mutate((key) => typeof key === 'string' && key.startsWith(endpoints.subusers.list));
      navigate(paths.dashboard.sub_users.list);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.detail || error.response || 'Something went wrong', {
        variant: 'error',
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {/* {Boolean(user_profile) &&
                        <RHFSwitch
                            name="isEdittable"
                            labelPlacement="start"
                            label="Allow Edit"
                            sx={{ mt: 5 }}
                        />} */}
        </Grid>

        <Grid xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
              }}
            >
              <RHFTextField
                type="text"
                disabled={!values.isEdittable}
                name="name"
                label="Name"
                inputProps={{
                  maxLength: 20,
                }}
                InputProps={{
                  autoComplete: 'off',
                }}
              />

              <RHFTextField
                type="text"
                name="phone_number"
                label="Phone Number"
                disabled={!values.isEdittable}
                inputProps={{
                  maxLength: 8,
                }}
                InputProps={{
                  autoComplete: 'off',
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="twemoji:flag-kuwait" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  ),
                }}
              />

              {!user_profile && (
                <RHFTextField
                  disabled={!values.isEdittable}
                  name="password"
                  label="Password"
                  type="password"
                  InputProps={{
                    autoComplete: 'new-password',
                  }}
                />
              )}

              {!user_profile && (
                <RHFTextField
                  disabled={!values.isEdittable || !values.password}
                  name="confirm_password"
                  label="Confirm Password"
                  type="password"
                  InputProps={{
                    autoComplete: 'new-password',
                  }}
                />
              )}

              {user?.type === 'Superuser' && (
                <Autocomplete<IRestaurant | IRestaurantCard, false, false, false>
                  disabled={!values.isEdittable}
                  id="restaurant"
                  options={restaurants}
                  getOptionLabel={(option) => option.name}
                  value={
                    restaurants.find((restaurant) => restaurant.id === values.restaurant) || null
                  }
                  onChange={(_, newValue) => {
                    setValue('restaurant', newValue ? newValue.id : '');
                  }}
                  loading={restaurantsLoading}
                  loadingText="Loading..."
                  renderInput={(params) => (
                    <RHFTextField {...params} name="restaurant" label="Restaurant" />
                  )}
                />
              )}
              {/*  multi select with grouping */}

              <Autocomplete
                multiple
                disabled={!values.isEdittable}
                disableCloseOnSelect
                options={permissionOptions}
                groupBy={(option) => option.roleName}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Roles & Permissions" />}
                value={permissionOptions.filter((option) =>
                  selectedPermissions.some(
                    (selectedOption) =>
                      selectedOption.id === option.id && selectedOption.roleId === option.roleId
                  )
                )}
                renderGroup={renderGroup}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      size="small"
                      checked={selected}
                      onChange={() => {
                        handleSelectPermission(option);
                      }}
                      value={option.id} // The value here is for form handling, but selection is managed via state
                    />
                    <small>{option.name}</small>
                  </li>
                )}
                renderTags={(selected, getTagProps) => {
                  // Create a set of unique role names from the selected permissions
                  const selectedRoleNames = Array.from(
                    new Set(selected.map((option) => option.roleName))
                  );

                  const numRoles = selectedRoleNames.length;
                  const limitTags = 6;

                  return (
                    <>
                      {selectedRoleNames.slice(0, limitTags).map((roleName, index) => (
                        <Chip
                          sx={{ m: 0.2 }}
                          {...getTagProps({ index })}
                          key={roleName}
                          label={roleName}
                          size="small"
                          onDelete={() => handleSelectDeselectRole(roleName)}
                        />
                      ))}
                      {numRoles > limitTags && <span>+{numRoles - limitTags} more</span>}
                    </>
                  );
                }}
                limitTags={6}
              />
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <Grid xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {user_profile && values.isEdittable && (
                  <Button
                    sx={{ mr: 1 }}
                    disabled={!values.isEdittable}
                    color="error"
                    variant="contained"
                    onClick={() => confirm.onTrue()}
                  >
                    Delete Subuser
                  </Button>
                )}

                {values.isEdittable && (
                  <LoadingButton
                    type="submit"
                    disabled={!values.isEdittable}
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {user_profile ? 'Update' : 'Create'}
                  </LoadingButton>
                )}
              </Grid>
            </Stack>
          </Card>
        </Grid>
      </Grid>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={handleDeleteSubUser}>
            Delete
          </Button>
        }
      />
    </FormProvider>
  );
}
