import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { IOrderResult } from 'src/types/order';

type Props = {
  row: IOrderResult;
};

export default function OrderTableRow({
  row
}: Props) {

  return (
    <TableRow hover>
      <TableCell align="left">{row.phone_number ? row.phone_number?.slice(4) : row.phone_number}</TableCell>
      <TableCell align="left">{row.area}</TableCell>
      <TableCell align="left">{row.block}</TableCell>
      <TableCell align="left">{row.street}</TableCell>
      <TableCell align="left">{row.avenue}</TableCell>
      <TableCell align="left">{row.house}</TableCell>
      <TableCell align="left">{row.date}</TableCell>
      <TableCell align="left">{row.delivery_time ?
        `${dayjs(row.delivery_time.start_time, 'HH:mm:ss').format('hh:mm A')} - ${dayjs(row.delivery_time.end_time, 'HH:mm:ss').format('hh:mm A')}` : ''}</TableCell>


    </TableRow>
  );
}
