import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PermissionBasedGuard from 'src/auth/guard/permission-based-guard';
import { useAuthContext } from 'src/auth/hooks';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import PerformanceListView from 'src/sections/performance/view/performance-list-view';
import PerformanceSubadminListView from 'src/sections/performance/view/performance-subadmin-list-view';

export default function PerformanceSummary() {
    const { user } = useAuthContext();
    return (
        <>
            <Helmet>
                <title> Eshterakaat</title>
            </Helmet>
            <Container maxWidth={false}>

                <PermissionBasedGuard permissions={['47']} hasContent>


                    <CustomBreadcrumbs
                        heading="Summary"
                        links={[

                            {
                            },
                        ]}

                        sx={{ mb: { xs: 3, md: 5 } }}
                    />

                    {
                        user?.type === "Superuser"
                            ?
                            <PerformanceListView />
                            :
                            <PerformanceSubadminListView />
                    }

                </PermissionBasedGuard>


            </Container>
        </>
    );
}
