import axios, { AxiosRequestConfig } from 'axios';

import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  restaurants: {
    list: '/partners/restaurants/',
    list_sub: '/partners/my-restaurants/',

    details: (id: string) => `/partners/restaurants/${id}`,
    plan_add: `/partners/plan/create/`,
    plan_update: (id: string) => `/partners/plans/${id}/update/`,
    plan_delete: (id: string) => `/partners/plan/${id}/delete/`,
    plan_duplicate: `/partners/plans/duplicate/`,
    plans: (id: string) => `/partners/restaurants/${id}/plans/`,
    meals: (id: string) => `/partners/restaurants/${id}/meals/`,
    foods: (id: string) => `/partners/v2/restaurants/${id}/foods`,
    food: (id: string) => `/partners/v2/foods/${id}/`,
    food_create: `/partners/v2/foods/`,
    food_delete: (food_id: string) => `/partners/food/${food_id}/delete/`,
    food_update: (food_id: string) => `/partners/v2/foods/${food_id}/`,
    plan: (id: string) => `/partners/plans/${id}/`,
    delete: (id: string) => `/partners/restaurants/${id}/delete/`,
    create: `/partners/restaurant/create/`,
    update: `/partners/restaurant/update/`,
    update_password: `/partners/update-password/`,
    image: `/partners/restaurant/image-upload/`,
    image_food: `/partners/foods/image-upload/`,
    image_restaurant: `/partners/restaurant/image-upload/`,
    delievery_time: (restaurant_slug: string) =>
      `/partners/restaurant/${restaurant_slug}/delivery-times/`,
    select_delivery_time: (delivery_time_id: string) =>
      `/partners/selecteddeliverytimes/${delivery_time_id}/`,
    select_create_delivery_time: (subscription_id: string) =>
      `/partners/subscriptions/${subscription_id}/selected-delivery-times/`,
  },
  cities: '/api/v1/cities/',
  users: {
    sub_admins: '/partners/subadmins/',
    customer_admin: '/partners/customers/',
    retrieve_user: (id: string) => `/partners/users/${id}/`,

    list: '/partners/users/',
    details: (id: string) => `/partners/users/${id}`,
    delete: (id: string) => `/partners/users/${id}`,
    update: (id: string) => `/partners/users/${id}/update/`,
    image: `/partners/users/image-upload/`,
  },
  subusers: {
    retrieve_user: (id: string) => `/partners/subusers/${id}/`,
    list: '/partners/subusers/',
    permissions: '/partners/subusers/permissions/',
    roles: '/partners/subusers/roles/',
    details: (id: string) => `/partners/subusers/${id}`,
    delete: (id: number) => `/partners/subusers/${id}/`,
    create: '/partners/subusers/',
    update: (id: string) => `/partners/subusers/${id}/`,
    image: `/partners/subusers/image-upload/`,
  },
  customers: {
    list: '/partners/customers/',
    profile: (id: string) => `/partners/customers/${id}`,
    update_comment: (id: string) => `/partners/customers/${id}/comment/`,
    update_checkout_note: (subscription_id: string) =>
      `/partners/subscriptions/${subscription_id}/checkout-note/`,
    retrieve_customer: (id: string) => `/partners/customers/${id}/`,
    extend_subscription: (subscription_id: string) =>
      `/partners/subscriptions/${subscription_id}/extend-end-date/`,
  },
  transactions: {
    list: '/partners/transactions/',
  },
  orders: {
    list: '/partners/orders/list/',
    summary: '/partners/orders/summary/',
  },
  foods: {
    ingredientsList: '/ingredients/categories/',
    foodsList: '/partners/selected-foods/',
    foodDetails: (id: string) => `/partners/selected-foods/${id}/`,
    customers: `/partners/selected-foods-customers/`,
    customers_stickers: `/partners/selected-foods-stickers/`,
    stickers: `/partners/stickers/`,
    food_stickers: `/partners/food-stickers/`,
    ingredients: `/partners/selected-ingredients/`,
    selected_food_excel: (date: string) => `/partners/selection-dates/${date}/selected-food/excel/`,
  },

  performance: {
    filters: {
      performance_subs_over_time_period: '/partners/performance/subs-over-time/available-periods/',
      performance_sales_over_time_period:
        '/partners/performance/sales-over-time/available-periods/',
      // performance_subs_sales_over_time_plans: '/partners/performance-summary-detailed-list/plans/',

      performance_restaurant_subs_over_time_period: (restaurant_id: string) =>
        `/partners/restaurants/${restaurant_id}/performance/subs-over-time/available-periods/`,
      performance_restaurant_sales_over_time_period: (restaurant_id: string) =>
        `/partners/restaurants/${restaurant_id}/performance/sales-over-time/available-periods/`,
    },
    // Pie Charts Calls
    performance_subs_per_plan: '/partners/performance/subs-per-plan/',
    performance_restaurant_subs_per_plan: (restaurant_id: string) =>
      `/partners/restaurants/${restaurant_id}/performance/subs-per-plan/`,
    performance_orders_per_food: '/partners/performance/orders-per-food/',
    performance_restaurant_orders_per_food: (restaurant_id: string) =>
      `/partners/restaurants/${restaurant_id}/performance/orders-per-food/`,
    performance_subs_per_period: '/partners/performance/subs-per-period/',
    performance_restaurant_subs_per_period: (restaurant_id: string) =>
      `/partners/restaurants/${restaurant_id}/performance/subs-per-period/`,

    // Line Charts Calls
    performance_subs_over_time: '/partners/performance/subs-over-time/',
    performance_restaurant_subs_over_time: (restaurant_id: string) =>
      `/partners/restaurants/${restaurant_id}/performance/subs-over-time/`,

    performance_sales_over_time: '/partners/performance/sales-over-time/',
    performance_restaurant_sales_over_time: (restaurant_id: string) =>
      `/partners/restaurants/${restaurant_id}/performance/sales-over-time/`,

    performance_subs_sales_over_time: '/partners/performance/subs-sales-over-time/',
    performance_restaurant_subs_sales_over_time: (restaurant_id: string) =>
      `/partners/restaurants/${restaurant_id}/performance/subs-sales-over-time/`,
    performance_subs_sales_over_time_plans: '/partners/performance-summary-detailed-list/plans/',
    performance_restaurant_subs_sales_over_time_plans: (restaurant_id: string) =>
      `/partners/restaurants/${restaurant_id}/performance/subs-sales-over-time/available-plans/`,

    performance_summary_widgets: '/partners/performance-summary-widgets/',
    performance_summary_list: '/partners/performance-summary-list/',
    performance_summary_details: '/partners/performance-summary-detail/',
    performance_summary_list_subadmin: '/partners/performance-summary-detailed-list/',
    performance_summary_details_subadmin: '/partners/subadmin-performance-summary-detail/',
    performance_details_list: '/partners/performance-summary-detailed-list.xlsx/',
  },

  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/partners/me/',
    login: '/partners/login/',
    register: '/api/auth/register',
    refresh: '/api/token/refresh/',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};
