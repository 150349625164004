
export function convertArabicToWestern(str: string): string {
    const arabicNumbers = '٠١٢٣٤٥٦٧٨٩';
    const westernNumbers = '0123456789';
    let convertedValue = '';

    // eslint-disable-next-line no-restricted-syntax
    for (const char of str) {
        const index = arabicNumbers.indexOf(char);
        if (index !== -1) {
            convertedValue += westernNumbers[index];
        } else {
            convertedValue += char; // If it's not an Arabic numeral, keep the original character.
        }
    }

    return convertedValue;
}