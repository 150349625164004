import { Button, Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { useGetSubUser } from 'src/api/account';
import { useGetMyRestaurants, useGetRestaurants, useGetSubuserRoles } from 'src/api/restaurant';
import { useAuthContext } from 'src/auth/hooks';
import Iconify from 'src/components/iconify/iconify';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import SubUserAccountGeneral from 'src/sections/user/sub-user-account-general';


// ----------------------------------------------------------------------



export default function SubUserProfile() {
    const { user } = useAuthContext();

    const params = useParams();

    const { subuser, subuserLoading } = useGetSubUser(params.id as string);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { restaurants, restaurantsLoading } = user?.type === "Superuser" ? useGetRestaurants() : { restaurants: [], restaurantsLoading: false };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { restaurants: restaurantSubadmin, restaurantsLoading: restaurantsLoadingSubadmin } = user?.type === "Subadmin" ? useGetMyRestaurants() : { restaurants: [], restaurantsLoading: false };


    const { roles, rolesLoading } = useGetSubuserRoles();


    return (
        <Container maxWidth={false}>

            <Helmet>
                <title> Dashboard: User Profile</title>
            </Helmet>

            <Button
                size='small'
                variant='soft'
                component={RouterLink}
                href={paths.dashboard.sub_users.list}
                startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
            >
                Back to Sub Users List
            </Button>

            {
                subuserLoading || rolesLoading || (user?.type === "Superuser" ? restaurantsLoading : restaurantsLoadingSubadmin) ? <Typography variant="h5" sx={{ my: 3 }}>
                    Loading...
                </Typography>

                    :
                    <SubUserAccountGeneral
                        restaurants={user?.type === "Superuser" ? restaurants : restaurantSubadmin}
                        restaurantsLoading={restaurantsLoading}
                        roles={roles}
                        rolesLoading={rolesLoading}
                        user_profile={subuser}
                    />
            }

        </Container>
    );
}
