import { m } from 'framer-motion';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { SxProps, Theme } from '@mui/material/styles';


import { ForbiddenIllustration } from 'src/assets/illustrations';

import { MotionContainer, varBounce } from 'src/components/animate';
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type PermissionBasedGuardProp = {
    hasContent?: boolean;
    children: React.ReactNode;
    sx?: SxProps<Theme>;
    permissions: string[];
};

export default function PermissionBasedGuard({ hasContent, permissions, children, sx }: PermissionBasedGuardProp) {
    const { user } = useAuthContext();


    const currentRole = user?.type;

    const hasAllPermissions = permissions?.every(permission => user?.permissionIds?.includes(permission));


    if (currentRole === "Sub-User" && !hasAllPermissions) {
        return hasContent ? (
            <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx }}>
                <m.div variants={varBounce().in}>
                    <Typography variant="h3" sx={{ mb: 2 }}>
                        Permission Denied
                    </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <Typography sx={{ color: 'text.secondary' }}>
                        You do not have permission to access this page
                    </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <ForbiddenIllustration
                        sx={{
                            height: 260,
                            my: { xs: 5, sm: 10 },
                        }}
                    />
                </m.div>
            </Container>
        ) : null;
    }

    return <> {children} </>;
}
