import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import ErrorBoundary from 'src/components/error/ErrorBoundary';
import { LoadingScreen } from 'src/components/loading-screen';
import CustomerProfile from 'src/pages/dashboard/customers/profile';
import SelectedPageCustomers from 'src/pages/dashboard/foods/selected-food-customers';
import ChangePasswordPage from 'src/pages/dashboard/jwt/change-password';
import PageOrders from 'src/pages/dashboard/orders';
import PerformanceDetailList from 'src/pages/dashboard/performance/DetailList';
import PagePerformanceStats from 'src/pages/dashboard/performance/Stats';
import PagePerformanceSummary from 'src/pages/dashboard/performance/Summary';
import RestaurantCreate from 'src/pages/dashboard/restaurants/new';
import SubUserCreateProfile from 'src/pages/dashboard/subusers/create';
import SubUserProfile from 'src/pages/dashboard/subusers/profile';
import PageTransactions from 'src/pages/dashboard/transactions';
import UserProfile from 'src/pages/dashboard/users/profile';
import RestaurantFoodPageView from 'src/sections/restaurant/view/restaurant-food-page-view';
import RestaurantPlanPageView from 'src/sections/restaurant/view/restaurant-plan-page-view';
import RestaurantProfilePageView from 'src/sections/restaurant/view/restaurant-profile-page-view';
import CustomersAdminList from 'src/sections/user/view/customer-admin-list-view';
import PageSubUsersList from 'src/sections/user/view/sub-user-list-view';
import PageUsersList from 'src/sections/user/view/user-list-view';
// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/index'));
const PageRestaurants = lazy(() => import('src/pages/dashboard/restaurants'));

const PagePerformanceDetail = lazy(() => import('src/pages/dashboard/performance/Detail'));

const PageFood = lazy(() => import('src/pages/dashboard/foods'));
const SelectedPageFood = lazy(() => import('src/pages/dashboard/foods/selected-food-food'));


const PageSettings = lazy(() => import('src/pages/dashboard/settings'));
const PageRestaurantProfile = lazy(() => import('src/pages/dashboard/restaurants/[id]'));
const PageRestaurantProfileEditPlan = lazy(() => import('src/pages/dashboard/restaurants/plan/[id]'));
const PageRestaurantProfileAddPlan = lazy(() => import('src/pages/dashboard/restaurants/plan/add'));
const PageRestaurantProfileEditFood = lazy(() => import('src/pages/dashboard/restaurants/food/[id]'));
const PageRestaurantProfileAddFood = lazy(() => import('src/pages/dashboard/restaurants/food/add'));





export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <ErrorBoundary>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'restaurants', element: <PageRestaurants /> },
      { path: 'users', element: <PageUsersList /> },
      { path: 'change-password', element: <ChangePasswordPage /> },
      { path: 'users/:id', element: <UserProfile /> },
      { path: 'subusers', element: <PageSubUsersList /> },
      { path: 'subusers/create', element: <SubUserCreateProfile /> },
      { path: 'subusers/:id', element: <SubUserProfile /> },
      { path: 'customers', element: <CustomersAdminList /> },
      { path: 'customers/:id', element: <CustomerProfile /> },
      { path: 'foods/:id', element: <PageFood /> },
      { path: 'foods', element: <PageFood /> },
      { path: 'foods/selected-food/:id', element: <SelectedPageFood /> },
      { path: 'customers/selected-food/:id', element: <SelectedPageCustomers /> },
      { path: 'settings', element: <PageSettings /> },
      { path: 'restaurants/:id', element: <PageRestaurantProfile /> },
      { path: 'restaurants/create', element: <RestaurantCreate /> },
      { path: 'restaurants/:id/plan', element: <PageRestaurantProfileAddPlan /> },
      { path: 'restaurants/plan/:id', element: <PageRestaurantProfileEditPlan /> },
      { path: 'restaurants/plans', element: <RestaurantPlanPageView /> },
      { path: 'restaurants/food/:id', element: <PageRestaurantProfileEditFood /> },
      { path: 'restaurants/:id/food', element: <PageRestaurantProfileAddFood /> },
      { path: 'restaurants/foods', element: <RestaurantFoodPageView /> },
      { path: 'profile', element: <RestaurantProfilePageView /> },
      { path: 'transactions', element: <PageTransactions /> },
      { path: 'orders', element: <PageOrders /> },
      { path: 'performance-stats', element: <PagePerformanceStats /> },
      { path: 'performance-summary', element: <PagePerformanceSummary /> },
      { path: 'performance-details', element: <PagePerformanceDetail /> },
      {
        path: 'performance-details-list', element: <PerformanceDetailList />

      }


    ],
  },
];
