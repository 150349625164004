import { Box, styled } from '@mui/material';
import { LocalizationProvider, PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import React, { useEffect, useState } from 'react';

dayjs.extend(utc);

const WeeksContainer = styled(Box)`
  display: flex;
  gap: 24.4px;
  position: absolute;
  top: 63px;
  left: 31px;
`;

const StyledDatesContainer = styled(Box)`
  position: relative;
  display: flex;
  .MuiPickersLayout-root .MuiPickersLayout-toolbar {
    display: none;
  }
  .MuiPickersLayout-root .MuiPickersLayout-actionBar {
    display: none;
  }
  .MuiPickersLayout-contentWrapper {
    grid-column: 1;
  }
  overflow: hidden;
`;

const Weeks = styled(Box)`
  font-size: 12px;
`;

const ServerDay: React.FC<any> = (props) => {
  const {
    highlightedDays = [],
    day,
    handleDay,
    outsideCurrentMonth,
    onDateClick,
    ...other
  } = props;
  const cleanedFormattedDate = dayjs(day).format('YYYY-MM-DD');

  useEffect(() => {
    if (handleDay) {
      handleDay(day);
    }
  }, [day, handleDay]);

  const isSelected =
    // !outsideCurrentMonth &&
    highlightedDays.some((highlightedDate: string) => {
      const parsedHighlightedDate = dayjs(highlightedDate).format('YYYY-MM-DD');

      return parsedHighlightedDate === cleanedFormattedDate;
    });

  const handleDayClick = () => {
    if (isSelected) {
      const updatedHighlightedDays = highlightedDays.filter(
        (highlightedDate: string) => highlightedDate !== cleanedFormattedDate
      );
      if (onDateClick) onDateClick(updatedHighlightedDays);
    } else {
      const updatedHighlightedDays = [...highlightedDays, cleanedFormattedDate];

      if (onDateClick) onDateClick(updatedHighlightedDays);
    }
  };

  return (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      selected={isSelected}
      onClick={handleDayClick}
    />
  );
};

interface FoodCalendarProps {
  available_days: any[];
  set_available_days: (value: any[]) => void;
  disabled: boolean;
  occurance: string;
  day: string;
  isEdit?: boolean;
  close?: () => void;
  selectedDays: any[];
  setSelectedDays: (value: any[]) => void;
  selectedOption: 'automatic' | 'custom';
}

export const calculateDatesBasedOnOccurance = (
  dayOfWeek: number,
  frequency: number,
  selectedDates: any
) => {
  const dates: string[] = [];
  let currentDate = selectedDates.clone();
  const endYear = selectedDates.year() + 3; // Set the end year to 3 years after the selected year

  while (currentDate.day() !== dayOfWeek) {
    currentDate = currentDate.add(1, 'day');
  }

  while (currentDate.year() < endYear) {
    // Continue until the current date reaches the end year
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(frequency * 7, 'day');
  }

  return dates;
};

export const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

function FoodCalendar({
  occurance,
  day: selectedUserDay,
  available_days,
  set_available_days,
  disabled,
  isEdit = true,
  close,
  selectedDays,
  setSelectedDays,
  selectedOption,
}: FoodCalendarProps) {
  const [day, setDay] = useState('');

  const findDatesForWeekIndex = (year: number, month: number, weekIndex: number): string[] => {
    const dates: string[] = [];
    const firstDayOfMonth = dayjs()
      .year(month === 0 ? year - 1 : year)
      .month(month === 0 ? 12 - 1 : month - 1)
      .date(1);

    // eslint-disable-next-line no-plusplus
    for (let dayIt = 1; dayIt <= firstDayOfMonth.daysInMonth(); dayIt++) {
      const currentDate = firstDayOfMonth.date(dayIt);

      if (currentDate.day() === weekIndex) {
        dates.push(currentDate.format('YYYY-MM-DD'));
      }
    }

    // eslint-disable-next-line consistent-return
    return dates;
  };
  const handleWeekClick = (weekIndex: number) => {
    if (disabled || selectedOption === 'automatic') {
      return;
    }

    if (day) {
      const selectedDay = dayjs(day);
      const currentYear = selectedDay.year();
      const currentMonthNumber = selectedDay.month(); // month() in dayjs is 0 indexed
      let newAvailableDates = [...available_days];

      // Loop over the next 12 months
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 36; i++) {
        // Calculate the actual month index and year, considering the year change
        const monthIndex = (currentMonthNumber + i) % 12;
        const year = currentYear + Math.floor((currentMonthNumber + i) / 12);
        const datesForWeekIndex = findDatesForWeekIndex(year, monthIndex, weekIndex);

        // Check if the week is present and update the available dates accordingly
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        const isWeekPresent = datesForWeekIndex?.some((date) => newAvailableDates.includes(date));

        if (isWeekPresent) {
          newAvailableDates = newAvailableDates.filter(
            (date) => !datesForWeekIndex?.includes(date)
          );
        } else {
          newAvailableDates = [...newAvailableDates, ...datesForWeekIndex];
        }
      }

      set_available_days(newAvailableDates);
    }
  };

  const handleSelectFirstDay = (dayselect: string) => {
    const selectedDates = dayjs(dayselect);
    if (selectedOption && [selectedUserDay].length > 0 && selectedOption === 'automatic') {
      // Assuming "5" is for "Custom"
      const frequency = parseInt(occurance, 10);
      let allAvailableDays: string[] = [];

      // Iterate over selectedUserDay array
      [selectedUserDay].forEach((sday) => {
        const dayIndex = weekDays.indexOf(sday); // Assuming the first character represents the day
        if (selectedDates) {
          const newAvailableDays = calculateDatesBasedOnOccurance(
            dayIndex,
            frequency,
            selectedDates
          );
          allAvailableDays = [...allAvailableDays, ...newAvailableDays]; // Combine days from each iteration
        }
      });

      // Remove duplicates, if any, and sort if needed
      const uniqueAvailableDays = Array.from(new Set(allAvailableDays)).sort();

      // Update state with the combined list of dates
      setSelectedDays(uniqueAvailableDays);
      set_available_days(uniqueAvailableDays);
    }
  };

  // useEffect(() => {
  //   if (occurance === '1') {
  //     const selectedDates = dayjs();
  //     let allAvailableDays: string[] = [];

  //     // Iterate over each day in selectedUserDay
  //     [selectedUserDay].forEach((sday) => {
  //       const dayIndex = weekDays.indexOf(sday); // Adjust this line if necessary for your weekDays structure
  //       const newAvailableDays = calculateDatesBasedOnOccurance(dayIndex, 1, selectedDates);
  //       allAvailableDays = [...allAvailableDays, ...newAvailableDays];
  //     });

  //     // Remove duplicates, if any, and sort the dates
  //     const uniqueAvailableDays = Array.from(new Set(allAvailableDays)).sort();

  //     // Update state with the combined list of dates
  //     setSelectedDays(uniqueAvailableDays);
  //     set_available_days(uniqueAvailableDays);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [occurance, selectedUserDay]);

  return (
    <StyledDatesContainer>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          sx={{
            '& .MuiDayCalendar-header': { opacity: 0 },
            '& .MuiPickersSlideTransition-root': {
              '&::-webkit-scrollbar': { width: '3px', borderRadius: '3px' },
              '&::-webkit-scrollbar-track': { background: '#ccc' },
              '&::-webkit-scrollbar-thumb': { background: '#888' },
              '&::-webkit-scrollbar-thumb:hover': { background: '#555' },
            },
          }}
          disabled={disabled}
          disablePast
          disableHighlightToday
          fixedWeekNumber={!isEdit ? undefined : 8}
          showDaysOutsideCurrentMonth
          // eslint-disable-next-line @typescript-eslint/no-shadow
          shouldDisableDate={(day) => {
            const dayOfWeek = dayjs(day).day();
            const dayIndexes = [selectedUserDay].map((s) => weekDays.indexOf(s));
            if (selectedOption === 'custom') {
              return false;
            }

            return !dayIndexes.includes(dayOfWeek);
          }}
          slots={{
            day: (props: any) => {
              setDay(props.day);
              return (
                <ServerDay
                  {...props}
                  highlightedDays={selectedOption === 'custom' ? available_days : selectedDays}
                  onDateClick={(array: any) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    selectedOption === 'custom' ? set_available_days(array) : '';
                  }}
                  handleDay={(dayInput: string) => setDay(dayInput)}
                />
              );
            },
          }}
          onChange={(newValue: string | null) => {
            if (newValue && selectedOption === 'automatic') {
              handleSelectFirstDay(newValue);
            }
            return newValue;
          }}
        />
      </LocalizationProvider>

      <WeeksContainer>
        {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((week, index) => (
          <Weeks key={week} onClick={() => handleWeekClick(index)}>
            {week}
          </Weeks>
        ))}
      </WeeksContainer>
    </StyledDatesContainer>
  );
}

export default FoodCalendar;
