import { useCallback, useEffect, useMemo, useReducer } from 'react';

import axios from 'axios';
import axiosInstance, { endpoints } from 'src/utils/axios';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';
const REFRESH_KEY = 'refreshToken';
const allIds = ['11', '12', '21', '22', '41', '42', '43', '44', '45', '46', '47'];

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);
      const refreshToken = localStorage.getItem(REFRESH_KEY);
      if (accessToken && isValidToken(accessToken) && refreshToken) {
        setSession(accessToken, refreshToken);

        const res = await axiosInstance.get(endpoints.auth.me);
        const user = res.data;

        if (user.type !== 'Superuser') {
          // Super user has multiple restaurants
          localStorage.setItem('restaurant_id', user?.restaurant_id || '');
        }

        const ismanager = allIds?.every(
          (permission: string) => user?.permissions?.includes(permission)
        );

        const permissionIds = user?.permissions;

        user.permissionIds = permissionIds;
        user.is_manager = ismanager;

        user.tokens = {
          refresh: refreshToken,
          access: accessToken,
        };

        dispatch({
          type: Types.INITIAL,
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (phoneNumber: string, password: string) => {
    setSession(null, null);

    const data = {
      phone_number: `+965${phoneNumber}`,
      password,
    };

    const res = await axiosInstance.post(endpoints.auth.login, data);

    const { tokens, name, phone_number, is_allowed_to_make_changes, type, permissions } = res.data;

    const ismanager = allIds?.every((permission: string) => permissions.includes(permission));

    const user = {
      name,
      phoneNumber: phone_number,
      phone_number,
      type,
      is_allowed_to_make_changes,
      permissions,
      permissionIds: permissions,
      is_manager: ismanager,
    };

    setSession(tokens.access, tokens.refresh);

    dispatch({
      type: Types.LOGIN,
      payload: {
        user: {
          ...user,
          accessToken: tokens.access,
          refreshToken: tokens.refresh,
        },
      },
    });

    return user.type;
  }, []);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      const res = await axios.post(endpoints.auth.register, data);

      const { accessToken, user } = res.data;

      localStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user: {
            ...user,
            accessToken,
          },
        },
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null, null);
    localStorage.clear();

    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
