/* eslint-disable no-nested-ternary */
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DateRange, DateRangeCalendar } from '@mui/x-date-pickers-pro';


import { useBoolean } from 'src/hooks/use-boolean';


import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  TableSkeleton,
  useTable,
} from 'src/components/table';

import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { Dayjs } from 'dayjs';
import { useGetOrders, useGetOrdersSummary } from 'src/api/order';
import { RoleBasedGuard } from 'src/auth/guard';
import PerformanceWidgetSummary from 'src/sections/performance/performance-widget-summary';
import { IOrderTableFilters } from 'src/types/order';
import { IUserTableFilterValue } from 'src/types/user';
import OrderTableRow from '../order-table-row';
import OrderTableToolbar from '../order-table-toolbar';


const TABLE_HEAD = [
  { id: 'phone_number', label: 'Phone Number' },
  { id: 'area', label: 'Area' },
  { id: 'block', label: 'Block' },
  { id: 'street', label: 'Street' },
  { id: 'avenue', label: 'Avenue' },
  { id: 'house', label: 'House' },
  { id: 'date', label: 'Date' },
  { id: 'delivery_time', label: 'Delivery Time' },

];

const defaultFilters: IOrderTableFilters = {
  area: null,
};


export default function OrderListView() {
  const table = useTable({ defaultRowsPerPage: 25 });
  const [value, setValue] = useState<DateRange<Dayjs>>([null, null]);
  const [filters, setFilters] = useState(defaultFilters);
  const confirm = useBoolean();
  const confirmDateFilter = useBoolean();


  const { orders, ordersLoading, ordersError } = useGetOrders(
    table.page + 1,
    table.rowsPerPage,
    filters.area,
    value[0] && !confirmDateFilter.value ? value[0].format('YYYY-MM-DD') : null,
    value[1] && !confirmDateFilter.value ? value[1].format('YYYY-MM-DD') : null);

  const { ordersSummary, ordersSummaryLoading } = useGetOrdersSummary();



  const [tableData, setTableData] = useState(orders?.results || []);

  const dataFiltered = tableData || [];



  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name: string, _value: IUserTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: _value,
      }));
    },
    [table]
  );

  const handleResetDateFilter = useCallback(() => {
    setValue([null, null]);
    table.onResetPage();
    confirmDateFilter.onFalse();

  }, [confirmDateFilter, table]);


  useEffect(() => {
    if (orders?.results && !ordersLoading) {
      setTableData(orders?.results);
    }
  }, [orders?.results, ordersLoading]);


  useEffect(() => {
    // Retrieve stored filter settings
    const savedFilters = JSON.parse(localStorage.getItem('orderFilters') || '{}');
    const savedPagination = JSON.parse(localStorage.getItem('orderPagination') || '{}');

    // Apply stored filter settings if they exist
    if (Object.keys(savedFilters).length > 0) {
      setFilters(savedFilters);
    }

    // Apply stored date range if it exists

    // Apply stored pagination settings if they exist
    if (savedPagination.page !== undefined && savedPagination.rowsPerPage !== undefined) {
      table.setPage(savedPagination.page);
      table.setRowsPerPage(savedPagination.rowsPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // Update local storage whenever filters change
  useEffect(() => {
    localStorage.setItem('orderFilters', JSON.stringify(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, value]);

  // Update local storage whenever pagination changes
  useEffect(() => {
    localStorage.setItem('orderPagination', JSON.stringify({ page: table.page, rowsPerPage: table.rowsPerPage }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.page, table.rowsPerPage]);

  return (
    <RoleBasedGuard hasContent roles={["Superuser", "Subadmin", "Sub-User"]} sx={{ py: 10 }}>
      {/* // align -right */}
      {ordersSummary && !ordersSummaryLoading && <Grid container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6} >
          <PerformanceWidgetSummary
            title="Orders Last Month"
            percent={0}
            total={ordersSummary.last_month}
            chart={{
              series: [],
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PerformanceWidgetSummary
            title="Orders Current Month"
            percent={0}
            total={ordersSummary.current_month}
            chart={{
              series: [],
            }}
          />
        </Grid>
      </Grid>}
      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '10px' }}>
        <Button
          startIcon={<Iconify icon="mdi:filter" />}
          variant="contained"
          onClick={confirmDateFilter.onTrue}
        >
          Date Filter
        </Button>

      </div>

      {orders && <Grid sx={{ py: 3 }} container>
        <Grid item xs={12} md={12} >
          <PerformanceWidgetSummary
            title={`
            Orders Delivered 
            ${(value[0] && value[1])
                ? `(${value[0].format('DD MMM')} to ${value[1].format('DD MMM')})` : ' Total'}`}
            percent={0}
            total={orders?.count || 0}
            chart={{
              series: [],
            }}
          />
        </Grid>
      </Grid>}

      <Card>

        <Dialog open={confirmDateFilter.value} onClose={confirmDateFilter.onFalse}>
          <DialogContent sx={{ mt: 3 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem label="Filter by Date">
                <DateRangeCalendar
                  disableFuture
                  calendars={1}
                  value={value}
                  onChange={(newValue) => setValue(newValue)}
                />
              </DemoItem>
            </LocalizationProvider>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleResetDateFilter}>Reset</Button>
            <Button
              variant="contained"
              onClick={confirmDateFilter.onFalse}
              disabled={!value[0] || !value[1]}
            >
              Apply
            </Button>
          </DialogActions>
        </Dialog>


        <OrderTableToolbar
          filters={filters}
          onFilters={handleFilters}
        />

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected.length}
            rowCount={tableData.length}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked,
                tableData.map((row: any) => row.id.toString())
              )
            }
            action={
              <Tooltip title="Delete">
                <IconButton color="primary" onClick={confirm.onTrue}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            }
          />

          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
              />

              <TableBody>
                {ordersError
                  ?
                  <h6>Failed to load orders</h6>
                  :
                  ordersLoading
                    ?
                    (
                      [...Array(table.rowsPerPage)].map((i, index) => (
                        <TableSkeleton key={index} sx={{ height: denseHeight }} />
                      ))
                    )
                    :
                    dataFiltered
                      .slice(
                        0,
                        table.rowsPerPage
                      )
                      .map((row, index) => (
                        <OrderTableRow
                          key={index}
                          row={row}
                        />
                      ))}
                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={orders?.count || 0}
          page={table.page}
          rowsPerPageOptions={[25, 50, 100]}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>

    </RoleBasedGuard>

  );
}

// ----------------------------------------------------------------------

