
import { Button } from '@mui/material';
import Container from '@mui/material/Container';
import { useGetMyRestaurants } from 'src/api/restaurant';
import { RoleBasedGuard } from 'src/auth/guard';
import PermissionBasedGuard from 'src/auth/guard/permission-based-guard';
import { useAuthContext } from 'src/auth/hooks';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import RestaurantFoodsListView from './restaurant-foods-list-view';


const TABLES = {
    foods: {
        id: "food",
        header: [
            { id: 'title', label: 'Food', alignRight: true },
            { id: 'meals', label: 'Meals', width: 180 },
            { id: 'availableDates', label: 'Available Dates', width: 180 },
            { id: 'action', label: '',   alignRight: false}
        ],
        data: [

        ]
    },
}

// ----------------------------------------------------------------------

export default function RestaurantFoodPageView() {
    const { user } = useAuthContext();

    const { restaurants, restaurantsLoading } = useGetMyRestaurants();

    if (restaurantsLoading) {
        return <div>Loading...</div>
    }


    return (

        <RoleBasedGuard hasContent roles={["Subadmin", "Sub-User"]} sx={{ py: 10 }}>
            <PermissionBasedGuard permissions={['44']} hasContent>
                <Container maxWidth={false}>

                    <CustomBreadcrumbs
                        heading='Foods'
                        sx={{ mb: 8 }}
                        links={[
                            {}

                        ]}
                        action={
                            ((user?.type === "Sub-User") || (user?.is_allowed_to_make_changes && restaurants.length > 0 && restaurants[0].id))
                            && (
                                <PermissionBasedGuard permissions={['22']}>
                                    <Button
                                        component={RouterLink}
                                        href={paths.dashboard.restaurant.food.add(restaurants[0].id)}
                                        variant="contained"
                                        startIcon={<Iconify icon="mingcute:add-line" />}
                                    >
                                        Add Food
                                    </Button>

                                </PermissionBasedGuard>
                            )
                        }
                    />


                    <RestaurantFoodsListView
                        id={restaurants[0]?.id}
                        tableConfig={TABLES.foods}
                    />

                </Container>

            </PermissionBasedGuard>
        </RoleBasedGuard>
    );
}
