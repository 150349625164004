import { Box, Button, CircularProgress, Container, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { useGetAllCities } from 'src/api/account';
import Iconify from 'src/components/iconify';
import { paths } from 'src/routes/paths';
import { Customer, MealEntry } from 'src/types/selected_food';

import { RouterLink } from 'src/routes/components';


// ----------------------------------------------------------------------

const food_columns: GridColDef[] = [


    {
        field: 'food_title',
        headerName: 'Food Title',
        width: 300
    },
    {
        field: 'title',
        headerName: 'Meal',
        width: 300
    },
]

export default function SelectedFoodCustomer() {

    const location = useLocation();

    const { plan } = location?.state as { plan: Customer };
    const { cities, citiesLoading, citiesError } = useGetAllCities();

    const food_rows = plan?.meals?.map((meal: MealEntry) => ({
        id: meal.id,
        title: meal.meal.title,
        title_arabic: meal.meal.title_arabic,
        food_title: meal.foods[0]?.food?.title || "",
        food_calories: meal.foods[0]?.food?.calories || 0,
        food_carb: meal.foods[0]?.food?.carb || 0,
        food_fat: meal.foods[0]?.food?.fat || 0,
    }));





    if (!plan) {
        return <Typography variant="h6" color="text.secondary" sx={{ py: 3, textAlign: 'center' }}>No Data</Typography>
    }

    return (

        <>
            <Helmet>
                <title> Dashboard: Selected Food</title>
            </Helmet>
            <Container maxWidth={false}>


                <Button
                    size='small'
                    variant='soft'
                    component={RouterLink}
                    href={paths.dashboard.selectedFood.root}
                    startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
                >
                    Back to Selected Food List
                </Button>


                <Box sx={{ mb: 5 }}>
                    <TextField
                        sx={{ mt: 3 }}

                        fullWidth
                        variant="outlined"
                        disabled
                        value={plan.subscription.customer.user.name}
                    />

                    <TextField
                        sx={{ mt: 3 }}
                        fullWidth
                        variant="outlined"
                        disabled
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Iconify icon="twemoji:flag-kuwait" sx={{ color: 'text.disabled' }} />
                                </InputAdornment>
                            ),
                        }}
                        value={plan.subscription.customer.user.phone_number?.slice(4)}
                    />

                    {/*  grid having 4 rows */}
                    <Grid container spacing={3} sx={{ mt: 3 }}
                    >

                        {citiesError &&
                            <Typography
                                variant="h6"
                                color="text.secondary"
                                sx={{ py: 3, textAlign: 'center' }}
                            >
                                Failed to load cities
                            </Typography>
                        }

                        <Grid item xs={12} md={4}>
                            {cities && <TextField
                                fullWidth
                                label="Area"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CircularProgress
                                                style={{ padding: "10px" }}
                                                sx={{
                                                    display:
                                                        citiesLoading ? 'block' : 'none',
                                                }}
                                                size="small" color="inherit" />

                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                disabled
                                value={cities?.find(city => city.id === plan.subscription.customer.user.personal_detail.area)?.name}
                            />}
                        </Grid>
                        <Grid item xs={3} md={2}>
                            <TextField
                                fullWidth
                                label="Avenue"
                                variant="outlined"
                                disabled
                                value={plan.subscription.customer.user.personal_detail.avenue}
                            />
                        </Grid>
                        <Grid item xs={3} md={2}>
                            <TextField
                                fullWidth
                                label="Block"
                                variant="outlined"
                                disabled
                                value={plan.subscription.customer.user.personal_detail.block}
                            />
                        </Grid>
                        <Grid item xs={3} md={2}>
                            <TextField
                                fullWidth
                                label="House"
                                variant="outlined"
                                disabled
                                value={plan.subscription.customer.user.personal_detail.house}
                            />
                        </Grid>
                        <Grid item xs={3} md={2}>
                            <TextField
                                fullWidth
                                label="Street"
                                variant="outlined"
                                disabled
                                value={plan.subscription.customer.user.personal_detail.street}
                            />
                        </Grid>
                    </Grid>



                    <TextField
                        sx={{ mt: 3 }}
                        fullWidth
                        label="Plan"
                        variant="outlined"
                        disabled
                        value={plan.subscription.plan.title}
                    />

                    <TextField
                        multiline
                        rows={3}
                        sx={{ mt: 3 }}
                        fullWidth
                        label="Note"
                        variant="outlined"
                        disabled
                        value={plan.subscription.checkout_notes || "N/A"}
                    />




                    <div style={{ height: 700, marginTop: '30px', width: '100%' }}>

                        {food_rows && <DataGrid

                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 25 },
                                },
                            }}
                            pageSizeOptions={[25, 50, 100]}
                            hideFooterPagination
                            hideFooterSelectedRowCount
                            rows={food_rows}
                            columns={food_columns}
                            checkboxSelection={false}
                        />}

                    </div>

                </Box>

            </Container>

        </>
    );
}
