import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PermissionBasedGuard from 'src/auth/guard/permission-based-guard';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import PerformanceDetailListView from 'src/sections/performance/view/performance-detail-list-view';

export default function PerformanceDetailList() {

    return (
        <>
            <Helmet>
                <title> Eshterakaat</title>
            </Helmet>

            <Container maxWidth={false}>
                <PermissionBasedGuard permissions={['46']} hasContent>
                    <CustomBreadcrumbs
                        sx={{ mb: { xs: 3, md: 5 } }}
                        heading='Subscriptions'
                        links={[
                            {},
                        ]}
                    />
                    <PerformanceDetailListView />
                </PermissionBasedGuard>
            </Container>

        </>
    );
}
