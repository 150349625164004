import { memo } from 'react';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { useRouter } from 'src/routes/hooks';


import Iconify from 'src/components/iconify';


// ----------------------------------------------------------------------

function Searchbar() {

  const router = useRouter();

  // const search = useBoolean();

  // const lgUp = useResponsive('up', 'lg');

  // const [searchQuery, setSearchQuery] = useState('');

  // const navData = useNavData();

  // const handleClose = useCallback(() => {
  //   search.onFalse();
  //   setSearchQuery('');
  // }, [search]);

  // const handleKeyDown = (event: KeyboardEvent) => {
  //   if (event.key === 'k' && event.metaKey) {
  //     search.onToggle();
  //     setSearchQuery('');
  //   }
  // };

  // useEventListener('keydown', handleKeyDown);

  // const handleClick = useCallback(
  //   (path: string) => {
  //     if (path.includes('http')) {
  //       window.open(path);
  //     } else {
  //       router.push(path);
  //     }
  //     handleClose();
  //   },
  //   [handleClose, router]
  // );

  // const handleSearch = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   setSearchQuery(event.target.value);
  // }, []);

  // const dataFiltered = applyFilter({
  //   inputData: getAllItems({ data: navData }),
  //   query: searchQuery,
  // });

  // const notFound = searchQuery && !dataFiltered.length;

  // const renderItems = () => {
  //   const data = groupedData(dataFiltered);

  //   return Object.keys(data)
  //     .sort((a, b) => -b.localeCompare(a))
  //     .map((group, index) => (
  //       <List key={group || index} disablePadding>
  //         {data[group].map((item) => {
  //           const { title, path } = item;

  //           const partsTitle = parse(title, match(title, searchQuery));

  //           const partsPath = parse(path, match(path, searchQuery));

  //           return (
  //             <ResultItem
  //               path={partsPath}
  //               title={partsTitle}
  //               key={`${title}${path}`}
  //               groupLabel={searchQuery && group}
  //               onClickItem={() => handleClick(path)}
  //             />
  //           );
  //         })}
  //       </List>
  //     ));
  // };

  const handleBack = () => {
    router.back();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderButton = (
    <Stack direction="row" mt={0.75} alignItems="center">
      <IconButton title='Back' onClick={handleBack}>
        <Iconify width="30px" height="30px" icon="ep:back" />
      </IconButton>
    </Stack>
  );

  return (
    <>
      {/* {renderButton} */}
    </>
  );
}

export default memo(Searchbar);
