import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import { fData } from 'src/utils/format-number';

import {
  Autocomplete,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { useGetAllCities } from 'src/api/account';
import {
  createNewRestaurant,
  deleteRestaurant,
  updateRestaurantData,
  updateRestaurantPassword,
  uploadRestaurantImage,
  useGetRestaurant,
} from 'src/api/restaurant';
import { scrollToFirstError } from 'src/auth/context/jwt/utils';
import { ConfirmDialog } from 'src/components/custom-dialog';
import FormProvider, { RHFSwitch, RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { PHONE_REGEX_EXPRESSION } from 'src/config-global';
import { useBoolean } from 'src/hooks/use-boolean';
import { paths } from 'src/routes/paths';
import { ARABIC_REGEX, ENGLISH_REGEX } from 'src/utils/regex';

const phoneRegExp = PHONE_REGEX_EXPRESSION;

// ----------------------------------------------------------------------

export default function RestaurantAccountGeneral({ id }: { id: string | undefined }) {
  const { enqueueSnackbar } = useSnackbar();
  const password_current = useBoolean();
  const { restaurant } = useGetRestaurant(id);
  const { cities, citiesLoading, citiesError } = useGetAllCities();
  const [imageFile, setImageFile] = useState<{ logo: any; cover: any }>({
    logo: null,
    cover: null,
  });
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deliveryTimeRanges, setDeliveryTimeRanges] = useState<
    {
      id: number | null;
      start: Date | null;
      end: Date | null;
    }[]
  >([{ id: null, start: null, end: null }]);
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const addTimeRange = () => {
    setDeliveryTimeRanges([...deliveryTimeRanges, { id: null, start: null, end: null }]);
  };

  // Function to update a time range
  const updateTimeRange = (index: number, field: 'start' | 'end', newValue: Date | null) => {
    const updatedRanges = [...deliveryTimeRanges];
    updatedRanges[index][field] = newValue;
    setDeliveryTimeRanges(updatedRanges);
  };

  // Function to remove a time range
  const removeTimeRange = (index: number) => {
    const updatedRanges = [...deliveryTimeRanges];
    updatedRanges.splice(index, 1);
    setDeliveryTimeRanges(updatedRanges);
  };

  const confirmPassword = useBoolean();

  const UpdateRestaurantSchema = Yup.object().shape({
    isEdittable: Yup.boolean(),
    name: Yup.string()
      .required('Name is required')
      .matches(ENGLISH_REGEX.pattern, ENGLISH_REGEX.message),
    name_arabic: Yup.string()
      .required('Arabic Name is required')
      .matches(ARABIC_REGEX.pattern, ARABIC_REGEX.message),
    logo: Yup.mixed<any>().nullable().required('Logo is required'),
    cover: Yup.mixed<any>().nullable().required('Cover is required'),
    contact_number: Yup.string()
      .required('Contact number is required')
      .matches(phoneRegExp, 'Invalid phone number')
      .min(8, 'Phone number is too short')
      .max(8, 'Phone number is too long'),
    commission: Yup.string()
      .required('Commission is required')
      .test('is-numeric', 'Commission must be a numeric value', (value) => {
        const numValue = Number(value);
        return !Number.isNaN(numValue);
      }),
    supplier_code: Yup.string().optional(),
    is_available: Yup.boolean(),
    email: Yup.string().email('Email must be a valid email address').optional(),
    phone_number: Yup.string()
      .required('Phone number is required')
      .matches(phoneRegExp, 'Invalid phone number')
      .min(8, 'Phone number is too short')
      .max(8, 'Phone number is too long'),
    password: Yup.string()
      .min(3, 'Password must be at least 3 characters')
      .required('Password is required'),
    is_allowed_to_make_changes: Yup.boolean(),
    sub_admin_id: Yup.number().optional(),
    sub_admin_name: Yup.string().optional(),
    area: Yup.array().required('Area is required'),
    current_password: confirmPassword.value
      ? Yup.string().required('Current Password is required')
      : Yup.string().nullable(),
    new_password: confirmPassword.value
      ? Yup.string()
          .required('New Password is required')
          .min(3, 'Password must be at least 3 characters')
      : Yup.string().nullable(),
    confirm_password: confirmPassword.value
      ? Yup.string()
          .oneOf([Yup.ref('new_password')], 'Passwords must match')
          .required('Confirm Password is required')
      : Yup.string().nullable(),
  });

  const defaultValues = useMemo(
    () => ({
      isEdittable: !id,
      name: restaurant?.name || '',
      name_arabic: restaurant?.name_arabic || '',
      logo: restaurant?.logo || '',
      is_available: restaurant?.is_available || false,
      cover: restaurant?.cover || '',
      contact_number: restaurant?.contact_number?.slice(4),
      commission: restaurant?.commission || '',
      supplier_code: restaurant?.supplier_code || '',
      email: restaurant?.email || '',
      phone_number: restaurant?.sub_admin?.user?.phone_number?.slice(4) || '',
      password: restaurant.id ? 'sample_password' : '',
      is_allowed_to_make_changes: restaurant?.sub_admin?.is_allowed_to_make_changes || false,
      sub_admin_id: restaurant?.sub_admin?.id || undefined,
      sub_admin_name: restaurant?.sub_admin?.user?.name || '',
      area: [],
      current_password: '',
      new_password: '',
      confirm_password: '',
    }),
    [restaurant, id]
  );

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(UpdateRestaurantSchema),
    defaultValues,
  });

  const [tempSelectedAreas, setTempSelectedAreas] = useState<string[]>([]);

  useEffect(() => {
    // Set the local state with the areas from the restaurant data
    if (restaurant?.areas) {
      setTempSelectedAreas(restaurant.areas.map((area) => area.id.toString()));
    }

    if (restaurant?.deliverytimes) {
      const mappingRanges = restaurant.deliverytimes.map((deliverytime) => {
        // Use moment to parse the start_time and end_time strings
        const startTime = moment(deliverytime.start_time, 'HH:mm:ss').toDate();
        const endTime = moment(deliverytime.end_time, 'HH:mm:ss').toDate();

        return { id: deliverytime.id, start: startTime, end: endTime };
      });

      setDeliveryTimeRanges(
        mappingRanges.length > 0 ? mappingRanges : [{ id: null, start: null, end: null }]
      );
    }
  }, [restaurant]);

  const handleSave = () => {
    methods.setValue('area', tempSelectedAreas);
    setOpen(false);

    // Add any other logic for submitting the form or saving the data
  };

  // Handler for the Cancel button
  const handleCancel = () => {
    setTempSelectedAreas(methods.watch('area'));
    setOpen(false); // Revert to last saved state
  };

  const confirm = useBoolean();

  const handleDeleteRestaurant = async () => {
    try {
      if (id) {
        await deleteRestaurant(id);
        enqueueSnackbar('Restaurant Deleted Successfully!');
        navigate(paths.dashboard.restaurants);
      }
    } catch (error) {
      if (typeof error.detail === 'string') {
        enqueueSnackbar(error.detail, { variant: 'error' });
      } else {
        enqueueSnackbar('Something went wrong!', { variant: 'error' });
      }
      console.error(error);
    } finally {
      confirm.onFalse();
    }
  };

  useEffect(() => {
    methods.reset({ ...defaultValues, isEdittable: true });

    const areas =
      restaurant?.areas?.map((area) => {
        if (typeof area === 'object') {
          return area.id.toString();
        }
        return area;
      }) || [];

    methods.setValue('area', areas);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, methods]);

  const {
    trigger,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    watch,
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(
    async (data) => {
      try {
        const filteredDeliveryTimeRanges: { id: number | null; start: Date; end: Date }[] =
          deliveryTimeRanges.filter((range) => range.start !== null && range.end !== null) as {
            id: number | null;
            start: Date;
            end: Date;
          }[];
        let isValid = true;
        // Validate each delivery time range
        filteredDeliveryTimeRanges.forEach((range, index) => {
          const startMinutes = range.start.getMinutes();
          const endMinutes = range.end.getMinutes();
          const startValid = startMinutes % 10 === 0;
          const endValid = endMinutes % 10 === 0;

          if (!startValid || !endValid) {
            isValid = false;
            enqueueSnackbar('Delivery times must be in 10 minute intervals', { variant: 'error' });
          }
        });

        if (!isValid) {
          return; // Stop form submission if there are validation errors
        }

        let ID = id || null;
        const isEdit = Boolean(ID);

        const formattedDeliveryTimes = deliveryTimeRanges
          .filter((range) => range.start !== null && range.end !== null) // Exclude ranges with null start or end
          .map((range) => ({
            ...(range.id !== null && { id: range.id }),
            start_time: moment(range.start).format('HH:mm:ss'),
            end_time: moment(range.end).format('HH:mm:ss'),
          }));

        const upload_data: any = {
          name: data.name,
          name_arabic: data.name_arabic,
          contact_number: `+965${data.contact_number}`,
          commission: data.commission,
          is_available: data.is_available,
          supplier_code: data.supplier_code,
          email: data.email,
          sub_admin: {
            user: {
              phone_number: `+965${data.phone_number}`,
            },
            is_allowed_to_make_changes: data.is_allowed_to_make_changes,
          },
          deliverytimes: formattedDeliveryTimes,
          areas: data.area.map((area: any) => Number(area)),
        };

        if (isEdit) {
          upload_data.id = id;
          upload_data.sub_admin.id = data.sub_admin_id;
          upload_data.sub_admin.user.name = data.sub_admin_name;
          await updateRestaurantData(upload_data);
          enqueueSnackbar('Update success!');
        } else {
          upload_data.sub_admin.user.password = data.password;
          const response = await createNewRestaurant(upload_data);
          ID = response.id;
          enqueueSnackbar('Create success!');
        }

        if (ID && (imageFile.cover || imageFile.logo)) {
          await uploadRestaurantImage(ID, {
            cover: imageFile.cover,
            logo: imageFile.logo,
          });
        }

        if (ID) {
          navigate(paths.dashboard.restaurant.profile(ID));
        }
      } catch (error) {
        enqueueSnackbar(error.message || error.detail || 'Something went wrong', {
          variant: 'error',
        });
        console.error(error);
      }
    },
    (e) => {
      scrollToFirstError(e);
    }
  );

  const handleDropLogo = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      if (newFile) {
        setValue('logo', newFile, { shouldValidate: true });
        setImageFile({ ...imageFile, logo: newFile });
      }
    },
    [imageFile, setValue]
  );

  const handleDropCover = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      if (newFile) {
        setValue('cover', newFile, { shouldValidate: true });
        setImageFile({ ...imageFile, cover: newFile });
      }
    },
    [setValue, imageFile]
  );

  const handleChangePassword = async () => {
    const isValid = await trigger(['current_password', 'new_password', 'confirm_password']);

    if (!isValid) {
      return; // Stop the password change process if validation fails
    }

    setIsChangingPassword(true); // Start loading

    try {
      if (id) {
        const response = await updateRestaurantPassword({
          current_password: values.current_password,
          new_password: values.new_password,
        });

        if (response) {
          if (response.detail) {
            enqueueSnackbar(response.detail, { variant: 'success' });
          } else {
            enqueueSnackbar('Password changed successfully!', { variant: 'success' });
          }
        }
      }
    } catch (error) {
      if (typeof error.detail === 'string') {
        enqueueSnackbar(error.detail, { variant: 'error' });
      } else {
        enqueueSnackbar('Error changing the password!', { variant: 'error' });
      }
      console.error(error);
    } finally {
      setIsChangingPassword(false); // Stop loading regardless of the outcome
      setValue('current_password', '');
      setValue('new_password', '');
      setValue('confirm_password', '');
      confirmPassword.onFalse();
    }
  };

  const handleClosePasswordDialog = () => {
    setValue('current_password', '');
    setValue('new_password', '');
    setValue('confirm_password', '');
    password_current.onFalse();
    confirmPassword.onFalse();
  };

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {/* <Grid xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {Boolean(id) && (
            <RHFSwitch
              name="isEdittable"
              labelPlacement="start"
              label="Allow Edit"
              sx={{ mt: 5 }}
            />
          )}
        </Grid> */}
        <Grid xs={12} md={4}>
          <Card sx={{ pt: 10, pb: 5, px: 3, textAlign: 'center' }}>
            <RHFUploadAvatar
              name="logo"
              disabled={!values?.isEdittable}
              maxSize={5000000}
              onDrop={handleDropLogo}
              multiple={false}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 3,
                    mb: 10,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                >
                  <strong>Logo</strong>
                  <br />
                  Allowed *.jpeg, *.jpg, *.png, *.gif
                  <br /> max size of {fData(5000000)}
                </Typography>
              }
            />

            <Divider />

            <RHFUploadAvatar
              sx={{ mt: 8 }}
              disabled={!values?.isEdittable}
              name="cover"
              multiple={false}
              maxSize={5000000}
              onDrop={handleDropCover}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 5,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                >
                  Cover Image
                </Typography>
              }
            />

            <RHFSwitch
              disabled={!values?.isEdittable}
              name="is_available"
              labelPlacement="start"
              label="Availablility"
              sx={{ mt: 5 }}
            />
          </Card>
        </Grid>

        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
              }}
            >
              <RHFTextField
                name="phone_number"
                label="Phone Number"
                disabled={!values?.isEdittable}
                sx={{ mt: 3 }}
                InputProps={{
                  autoComplete: 'off',
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="twemoji:flag-kuwait" sx={{ color: 'text.disabled' }} />
                    </InputAdornment>
                  ),
                }}
              />

              {/* Place a button for change password beside the below password */}

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretch', sm: 'center' }}
                spacing={2}
                justifyContent="space-between"
              >
                <RHFTextField
                  fullWidth // Makes the TextField take the full width of the Stack on small screens
                  disabled={Boolean(restaurant.id) || !values?.isEdittable}
                  name="password"
                  type="password"
                  label="Password"
                  sx={{ mt: 0 }}
                  InputProps={{
                    autoComplete: 'new-password',
                  }}
                />

                {/* {Boolean(restaurant.id) && (
                                    <Button
                                        fullWidth // Makes the Button take the full width of the Stack on small screens
                                        size='large'
                                        sx={{ mt: { xs: 0, sm: 0 }, width: { sm: '300px' } }} // Adds margin-top on small screens, and width on small and up
                                        disabled={!values?.isEdittable}
                                        variant="contained"
                                        onClick={() => confirmPassword.onTrue()}
                                        startIcon={<Iconify icon="eva:lock-outline" />}
                                    >
                                        Change Password
                                    </Button>
                                )} */}
              </Stack>

              <RHFTextField
                name="email"
                label="Email"
                sx={{ mt: 0 }}
                disabled={!values?.isEdittable}
              />

              <RHFTextField name="name" label="Name" disabled={!values?.isEdittable} />

              <RHFTextField name="name_arabic" label="Name Ar" disabled={!values?.isEdittable} />

              {!citiesLoading && !citiesError && cities && Array.isArray(cities) && (
                <Autocomplete
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setTempSelectedAreas(methods.watch('area'));
                    setOpen(false);
                  }}
                  fullWidth
                  limitTags={3}
                  loadingText="Loading Area Name..."
                  multiple // Enable multiple selection
                  options={cities?.map((city) => ({
                    id: city.id.toString(),
                    name: city.name,
                  }))}
                  disableCloseOnSelect
                  getOptionLabel={(option) =>
                    typeof option === 'string' ? option : option?.name ?? ''
                  }
                  sx={{ mb: 1 }}
                  loading={citiesLoading}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <Checkbox
                        size="small"
                        disabled={!values?.isEdittable}
                        disableRipple
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Area" placeholder="Select Area" />
                  )}
                  onChange={(event, value, reason) => {
                    setTempSelectedAreas(value.map((v) => v.id));
                  }}
                  isOptionEqualToValue={(option, value) => option.id === value.id.toString()}
                  value={cities
                    .filter((city) => tempSelectedAreas.includes(city?.id?.toString()))
                    .map((city) => ({
                      id: city.id.toString(),
                      name: city.name,
                    }))}
                  renderTags={(selected, getTagProps) => {
                    const numTags = selected.length;
                    const limitTags = 3;
                    return (
                      <>
                        {selected.slice(0, limitTags).map((option, index) => (
                          <Chip
                            sx={{ m: 0.2 }}
                            // {...getTagProps({ index })}
                            key={option.id}
                            label={option.name}
                            size="small"
                          />
                        ))}
                        {numTags > limitTags && ` +${numTags - limitTags}`}
                      </>
                    );
                  }}
                  PaperComponent={({ children }) => (
                    <Paper onMouseDown={(event) => event.preventDefault()}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          mb: 1,
                          borderBottom: '1px solid',
                          borderColor: 'divider',
                          p: 1,
                        }}
                      >
                        <ButtonGroup size="small">
                          <Button
                            size="small"
                            onClick={() =>
                              setTempSelectedAreas(cities.map((city) => city.id.toString()))
                            }
                            disabled={!values?.isEdittable}
                          >
                            Select All
                          </Button>

                          <Button
                            color="primary"
                            onClick={handleSave}
                            disabled={!values?.isEdittable}
                          >
                            Save
                          </Button>
                          <Button
                            color="secondary"
                            onClick={handleCancel}
                            disabled={!values?.isEdittable}
                          >
                            Cancel
                          </Button>
                        </ButtonGroup>
                      </Box>
                      {children}
                    </Paper>
                  )}
                />
              )}
            </Box>

            <RHFTextField
              disabled={!values?.isEdittable}
              name="commission"
              label="Commission"
              sx={{ my: 2 }}
            />

            <Divider />

            <RHFTextField
              disabled={!values?.isEdittable}
              name="contact_number"
              label="Contact Number"
              sx={{ mt: 3 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="twemoji:flag-kuwait" sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                ),
              }}
            />

            <RHFTextField
              name="supplier_code"
              label="Supplier Code"
              sx={{ mt: 3, mb: 3 }}
              disabled={!values?.isEdittable}
            />

            <Divider />

            <Box rowGap={3} sx={{ mt: 2, mb: 1 }}>
              <Typography variant="h6" mx={2}>
                Delivery Time
              </Typography>

              {deliveryTimeRanges.map((range, index) => (
                <Grid
                  container
                  rowSpacing={0.5}
                  key={index}
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Grid xs={4} md={4.5}>
                    <TimePicker
                      readOnly={false}
                      open={false}
                      disabled={!values?.isEdittable}
                      label="Start Time"
                      disableOpenPicker
                      componentsProps={{ actionBar: { actions: [] } }}
                      value={range.start}
                      onChange={(newValue) => updateTimeRange(index, 'start', newValue)}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          margin: 'normal',
                        },
                      }}
                      views={['hours', 'minutes']}
                      format="HH:mm"
                      timeSteps={{ minutes: 10 }}
                      ampm={false}
                      minutesStep={10}
                    />
                  </Grid>
                  <Grid xs={4} md={4.5}>
                    <TimePicker
                      readOnly={false}
                      open={false}
                      componentsProps={{ actionBar: { actions: [] } }}
                      disabled={!values?.isEdittable}
                      label="End Time"
                      disableOpenPicker
                      value={range.end}
                      onChange={(newValue) => updateTimeRange(index, 'end', newValue)}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          margin: 'normal',
                        },
                      }}
                      views={['hours', 'minutes']}
                      format="HH:mm"
                      timeSteps={{ minutes: 10 }}
                      ampm={false}
                      minutesStep={10}
                    />
                  </Grid>
                  {/* Add button only next to the last item and if both times are filled */}

                  <Grid xs={1} md={0.5}>
                    <IconButton
                      onClick={addTimeRange}
                      disabled={
                        !(index === deliveryTimeRanges.length - 1 && range.start && range.end) ||
                        !values?.isEdittable
                      }
                    >
                      <Iconify icon="basil:add-solid" />
                    </IconButton>
                  </Grid>

                  <Grid xs={1} md={0.5}>
                    <IconButton
                      onClick={() => removeTimeRange(index)}
                      disabled={deliveryTimeRanges.length === 1 || !values.isEdittable}
                    >
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Box>

            <RHFSwitch
              disabled={!values?.isEdittable}
              name="is_allowed_to_make_changes"
              labelPlacement="start"
              label="Allowed to make changes"
              sx={{ mt: 5 }}
            />

            {values?.isEdittable && (
              <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                <div style={{ display: 'flex' }}>
                  {Boolean(id) && (
                    <Button
                      sx={{ mr: 1 }}
                      color="error"
                      onClick={() => confirm.onTrue()}
                      variant="contained"
                    >
                      Delete Restaurant
                    </Button>
                  )}

                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    {id ? 'Update' : 'Create'}
                  </LoadingButton>
                </div>
              </Stack>
            )}
          </Card>
        </Grid>
      </Grid>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={handleDeleteRestaurant}>
            Delete
          </Button>
        }
      />

      <ConfirmDialog
        open={confirmPassword.value && Boolean(values?.isEdittable) && Boolean(id)}
        onClose={handleClosePasswordDialog}
        title="Change Password"
        content={
          <>
            <RHFTextField
              disabled={!values?.isEdittable}
              name="current_password"
              type={password_current.value ? 'text' : 'password'}
              label="Current Password"
              sx={{ mb: 2, mt: 2 }}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={password_current.onToggle} edge="end">
                      <Iconify
                        icon={password_current.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <RHFTextField
              disabled={!values?.isEdittable}
              name="new_password"
              type="password"
              label="New Password"
              sx={{ mb: 2 }}
              InputProps={{
                autoComplete: 'new-password',
              }}
            />

            <RHFTextField
              disabled={!values?.isEdittable}
              name="confirm_password"
              type="password"
              label="Confirm Password"
              sx={{ mb: 2 }}
              InputProps={{
                autoComplete: 'new-password',
              }}
            />
          </>
        }
        action={
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={handleChangePassword}
            loading={isChangingPassword}
          >
            Confirm
          </LoadingButton>
        }
      />
    </FormProvider>
  );
}
