import { Helmet } from 'react-helmet-async';
import JWTNewPasswordView from 'src/sections/auth/jwt/jwt-new-password-view';


// ----------------------------------------------------------------------

export default function ChangePasswordPage() {
    return (

        <>
            <Helmet>
                <title> Eshterakat: Change Password</title>
            </Helmet>

            <JWTNewPasswordView />
        </>
    );
}
