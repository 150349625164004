import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import OverviewPerformanceView from 'src/sections/performance/view/overview-performance-view';
import PermissionBasedGuard from '../../../auth/guard/permission-based-guard';

export default function PerformanceStats() {


    return (
        <>

            <Helmet>
                <title> Eshterakaat</title>
            </Helmet>
            <Container maxWidth={false}>

                <PermissionBasedGuard permissions={['43']} hasContent>

                    <CustomBreadcrumbs
                        sx={{ mb: { xs: 3, md: 5 } }}
                        heading='Graphics'
                        links={[

                            {},
                        ]}

                    />
                    <OverviewPerformanceView />

                </PermissionBasedGuard>

            </Container>

        </>
    );
}
