import { _id } from 'src/_mock/assets';

const MOCK_ID = _id[1];

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
      refresh: `${ROOTS.AUTH}/jwt/refresh/`,
      changePassword: `${ROOTS.AUTH}/jwt/change-password`,
    },
  },
  // DASHBOARD
  dashboard: {
    changePassword: `${ROOTS.DASHBOARD}/change-password`,
    root: ROOTS.DASHBOARD,
    profile: `${ROOTS.DASHBOARD}/profile`,
    restaurants: `${ROOTS.DASHBOARD}/restaurants`,
    orders: `${ROOTS.DASHBOARD}/orders`,
    transactions: `${ROOTS.DASHBOARD}/transactions`,
    performance: {
      graphics: `${ROOTS.DASHBOARD}/performance-stats`,
      summary: `${ROOTS.DASHBOARD}/performance-summary`,
      details: (start_date: string, end_date: string) => `${ROOTS.DASHBOARD}/performance-details?start_date=${start_date}&end_date=${end_date}`,
      details_subadmin: `${ROOTS.DASHBOARD}/performance-details-list`,
    },
    foods: `${ROOTS.DASHBOARD}/foods`,
    settings: `${ROOTS.DASHBOARD}/settings`,
    selectedFood: {
      root: `${ROOTS.DASHBOARD}/foods`,
      root_tab: (subscription_id: string) => `${ROOTS.DASHBOARD}/foods/${1}?subscription_id=${subscription_id}`,
      singleFood: (id: string, date: string) => `${ROOTS.DASHBOARD}/foods/selected-food/${id}?date=${date}`,
      singleCustomer: (id: string, date: string) => `${ROOTS.DASHBOARD}/customers/selected-food/${id}?date=${date}`,

    },
    customers: {
      profile: (id: string) => `${ROOTS.DASHBOARD}/customers/${id}`,
      list: `${ROOTS.DASHBOARD}/customers`,
    },
    users: {
      list: `${ROOTS.DASHBOARD}/users`,
      profile: (id: string) => `${ROOTS.DASHBOARD}/users/${id}`,
    },
    sub_users: {
      list: `${ROOTS.DASHBOARD}/subusers`,
      create: `${ROOTS.DASHBOARD}/subusers/create`,
      profile: (id: string) => `${ROOTS.DASHBOARD}/subusers/${id}`,
    },
    restaurant: {
      create: `${ROOTS.DASHBOARD}/restaurants/create`,
      profile: (id: string) => `${ROOTS.DASHBOARD}/restaurants/${id}`,
      plan: {
        plans: `${ROOTS.DASHBOARD}/restaurants/plans`,
        add: (id: string) => `${ROOTS.DASHBOARD}/restaurants/${id}/plan`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/restaurants/plan/${id}`,
      },
      food: {
        foods: `${ROOTS.DASHBOARD}/restaurants/foods`,
        add: (id: string) => `${ROOTS.DASHBOARD}/restaurants/${id}/food`,
        edit: (id: string) => `${ROOTS.DASHBOARD}/restaurants/food/${id}`,
      },

    },
    user: {
      root: `${ROOTS.DASHBOARD}/restaurants`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },

  },
};
