import { LoadingButton } from '@mui/lab';
import { Button, Chip, Dialog, IconButton, MenuItem, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useAuthContext } from 'src/auth/hooks';
import FoodCalendar from 'src/components/calendars/food-calendar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { usePopover } from 'src/components/custom-popover';
import CustomPopover from 'src/components/custom-popover/custom-popover';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { IRestaurantFoodCard, IRestaurantPlanCard } from 'src/types/user';
import { fetcher } from 'src/utils/axios';
import useSWR from 'swr';

// ----------------------------------------------------------------------

type Props = {
  selected: boolean;
  onEditRow: VoidFunction;
  row: IRestaurantPlanCard | IRestaurantFoodCard;
  onDeleteRow: VoidFunction;
  onDuplicatePlan?: VoidFunction;
  id: any;
  table: any;
};

export default function RestaurantTableRow({
  table,
  row,
  selected,
  onEditRow,
  onDuplicatePlan,
  onDeleteRow,
  id,
}: Props) {
  const { user } = useAuthContext();

  const [show, setShow] = useState(false);

  const [swrKey, setSwrKey] = useState<string | null>(null); // Initialize SWR key state with null

  // 10 min cache
  const {
    data: availableDates,
    error,
    isLoading,
    isValidating,
  } = useSWR(swrKey, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 600000,
  });

  const handleViewClick = async (fid: string) => {
    const key = `/partners/foods/${fid}/available-days/`; // Construct the SWR key
    setShow(true);
    setSwrKey(key); // Update the SWR key to trigger data fetching
    // await mutate(key); // Optionally, manually trigger a revalidation for the key
  };

  const confirm = useBoolean();
  const popover = usePopover();
  const loading = useBoolean();

  // const quickEdit = useBoolean();

  const handleViewRestaurant = (uid: number | string) => {
    onEditRow();
  };

  const handleDuplicatePlan = async () => {
    if (onDuplicatePlan) {
      loading.onTrue();
      await onDuplicatePlan();
      loading.onFalse();
      popover.onClose();
    }
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell>
        <Typography
          onClick={() => handleViewRestaurant(id)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          variant="subtitle2"
          noWrap
        >
          {row?.title}
        </Typography>
      </TableCell>
      <TableCell>
        {'total_subscriptions' in row && row.total_subscriptions}
        {'meals' in row && (row.meals as any)}
      </TableCell>
      <TableCell>
        {'active_subscriptions' in row && row.active_subscriptions}

        {'meals' in row &&
          row.availability_patterns.map((chip, index) => (
            <Chip
              size="small"
              key={index}
              label={`Every ${chip.interval} on ${dayjs()
                .day((chip.byweekday + 1) % 7) // Backend Interprets Monday as 0, but dayjs interprets Sunday as 0
                .format('ddd')}`}
              sx={{ mr: 0.5, mb: 0.5 }}
            />
          ))}

        {'meals' in row && row.availability_patterns.length === 0 && (
          <LoadingButton
            disabled={isLoading}
            loading={isLoading || isValidating}
            onClick={() => handleViewClick(id)}
          >
            View
          </LoadingButton>
        )}

        {'meals' in row && (
          <Dialog open={show && !isLoading} onClose={() => setShow(false)} maxWidth="md">
            {error ? (
              <div>Error loading available dates</div>
            ) : (
              <FoodCalendar
                day=""
                selectedDays={[]}
                setSelectedDays={() => {}}
                selectedOption="custom"
                close={() => setShow(false)}
                occurance="5"
                isEdit={false}
                disabled={false}
                available_days={availableDates?.map((day: { date: string }) => day.date) || []}
                set_available_days={() => {}}
              />
            )}
          </Dialog>
        )}
      </TableCell>

      {'meals_count' in row && <TableCell>{row.meals_count}</TableCell>}

      {'packages_count' in row && <TableCell>{row.packages_count}</TableCell>}

      {(user?.type === 'Superuser' || user?.type === 'Subadmin' || user?.is_manager) && (
        <TableCell align="left" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      )}

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {onDuplicatePlan && (
          <LoadingButton
            disableElevation
            disableRipple
            loading={loading.value}
            onClick={handleDuplicatePlan}
            variant="text"
            fullWidth
            sx={{
              px: 0,
              display: 'flex',
              justifyContent: 'center',
              gap: 1, // controls the space between the icon and the text
            }}
            startIcon={<Iconify icon="ion:duplicate" width="16" />}
          >
            <span style={{ color: '#212B36' }}>Duplicate</span>
          </LoadingButton>
        )}

        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
          sx={{ pl: 2, color: '#212B36' }}
        >
          <Iconify icon="solar:pen-bold" />

          <span style={{ color: '#212B36' }}>Edit</span>
        </MenuItem>

        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main', pl: 2 }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </TableRow>
  );
}
