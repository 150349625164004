import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PermissionBasedGuard from 'src/auth/guard/permission-based-guard';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import OrderListView from 'src/sections/orders/view/order-list-view';


// ----------------------------------------------------------------------

export default function Orders() {
    return (

        <>
            <Helmet>
                <title> Dashboard: Orders</title>
            </Helmet>
            <Container maxWidth={false}>

                <PermissionBasedGuard permissions={['42']} hasContent>
                    <CustomBreadcrumbs
                        heading="Orders"
                        links={[

                            {
                            },
                        ]}

                        sx={{ mb: { xs: 3, md: 5 } }}
                    />

                    <OrderListView />
                </PermissionBasedGuard>
            </Container>

        </>
    );
}
