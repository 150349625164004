import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { LoadingButton } from '@mui/lab';
import { Chip, IconButton, Link, MenuItem, Select, TableCell, TextareaAutosize, Tooltip, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { AxiosResponse } from 'axios';
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Link as ReactRouterLink } from 'react-router-dom';
import { updateCustomerCheckoutNote, updateCustomerExtendSubscription } from "src/api/customer";
import { createRestaurantDeliveryTime, getRestaurantDeliveryTime, updateRestaurantDeliveryTime } from "src/api/restaurant";
import PermissionBasedGuard from 'src/auth/guard/permission-based-guard';
import { useAuthContext } from 'src/auth/hooks';
import Iconify from "src/components/iconify";
import { useBoolean } from 'src/hooks/use-boolean';
import { paths } from "src/routes/paths";
import { convertArabicToWestern } from 'src/utils/arabic-to-western';

interface DeliveryTime { id: number, start_time: string, end_time: string }

function CommentModal({
    open,
    handleClose,
    handleSubmit,
    commentValue,
    onChange,
    loadingNotes
}:
    {
        open: boolean,
        handleClose: () => void,
        commentValue?: string,
        handleSubmit: () => void,
        loadingNotes: boolean,
        onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
    }) {

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>Note</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Write a note for this subscription
                </DialogContentText>
                <TextareaAutosize
                    autoFocus
                    value={commentValue || ''}
                    onChange={onChange}
                    minRows={5}
                    style={{ width: '100%', outline: 'none', border: '1px solid #ccc', padding: '10px', borderRadius: '5px', marginTop: '10px' }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <PermissionBasedGuard permissions={['12']}>
                    <LoadingButton loading={loadingNotes} variant='contained' onClick={handleSubmit}>Save Changes</LoadingButton>
                </PermissionBasedGuard>
            </DialogActions>
        </Dialog>
    );
}


export function SubsriptionsTable({ subscriptions }: any) {
    const [open, setOpen] = useState(false);
    const [openExtend, setOpenExtend] = useState({
        open: false,
        id: ''
    });
    const { user } = useAuthContext();
    const [checkoutNote, setCheckoutNote] = useState('');
    const [selectedSubscription, setSelectedSubscription] = useState<any>(null);
    const [extendedDays, setExtendedDays] = useState(1);
    const [deliveryTimeDialogOpen, setDeliveryTimeDialogOpen] = useState(false);
    const [deliveryTimes, setDeliveryTimes] = useState<DeliveryTime[]>([]);
    const [selectedDeliveryTime, setSelectedDeliveryTime] = useState<DeliveryTime | null>(null);
    const [deliveryTimetoUpdate, setDeliveryTimetoUpdate] = useState<DeliveryTime | null>(null);

    const [selectedSubscriptionId, setSubscriptionId] = useState<string | null>(null);

    const loadingNotes = useBoolean();

    const fetchDeliveryTimes = async (restaurantSlug: string, selected_delivery_time_item: DeliveryTime, subscription_id: string) => {
        try {

            if (selected_delivery_time_item) {
                setDeliveryTimetoUpdate(selected_delivery_time_item);
                setSubscriptionId(null);
            } else {
                setSubscriptionId(subscription_id);
                setSelectedDeliveryTime(null);
            }
            const data = await getRestaurantDeliveryTime(restaurantSlug);
            setDeliveryTimes(data);
            setDeliveryTimeDialogOpen(true); // Open the dialog after fetching the times

        } catch (error) {
            enqueueSnackbar('Failed to fetch delivery times. Please try again later.', {
                variant: 'error'
            });
        }

    };

    const submitSelectedDeliveryTime = async () => {

        let response: AxiosResponse<{
            id: number,
            start_time: string,
            end_time: string,
            subscription: string

        }> | null = null;

        try {
            if (selectedDeliveryTime && selectedSubscriptionId) {
                response = await createRestaurantDeliveryTime(selectedSubscriptionId, {
                    original_delivery_time: selectedDeliveryTime.id,
                    start_time: selectedDeliveryTime.start_time,
                    end_time: selectedDeliveryTime.end_time
                });
            }
            else if (selectedDeliveryTime && deliveryTimetoUpdate && deliveryTimetoUpdate.id) {
                response = await updateRestaurantDeliveryTime(deliveryTimetoUpdate.id, {
                    start_time: selectedDeliveryTime.start_time,
                    end_time: selectedDeliveryTime.end_time
                })
            }

            if (response && response !== null && response.status >= 200 && response.status < 300) {
                // Close the dialog and update the table
                setDeliveryTimeDialogOpen(false);
                enqueueSnackbar('Updated Delivery Time successfully', {
                    variant: 'success'
                });

                const subscriptionId = response.data.subscription;

                const updatedRows = rows.map((item: any) => {
                    if (item.id === subscriptionId) {
                        return {
                            ...item, selected_delivery_time: {
                                id: response?.data?.id,
                                start_time: response?.data?.start_time,
                                end_time: response?.data?.end_time
                            }
                        };
                    }
                    return item;
                });

                setRows(updatedRows);

            } else {
                throw new Error('API call was not successful.');
            }
        } catch (error) {
            enqueueSnackbar(error.detail || 'Something went wrong', {
                variant: 'error'
            });
        } finally {
            setSelectedDeliveryTime(null);
            setDeliveryTimetoUpdate(null);
            setSubscriptionId(null);

        }
    };

    const handleCheckoutNote = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCheckoutNote(convertArabicToWestern(event.target.value));
    }




    const [rows, setRows] = useState<any>(subscriptions.map((item: any) => ({
        id: item.id,
        restaurant: item.restaurant.name,
        restaurant_slug: item.restaurant.slug,
        plan: item.plan.title,
        period: item.package.period,
        offDays: item.package.off_days,
        price: item.package.price,
        startDate: item.start_date,
        endDate: item.end_date,
        checkoutNote: item.checkout_notes,
        extendDays: 1,
        status: item.status,
        selected_delivery_time: item.delivery_time,
        // start_time: item.delivery_time.start_time || 'N/A',
        // end_time: item.delivery_time.end_time || 'N/A'
    })));





    useEffect(() => {
        setRows(subscriptions.map((item: any) => ({
            id: item.id,
            restaurant: item.restaurant.name,
            restaurant_slug: item.restaurant.slug,
            plan: item.plan.title,
            period: item.package.period,
            offDays: item.package.off_days,
            price: item.package.price,
            startDate: item.start_date,
            endDate: item.end_date,
            checkoutNote: item.checkout_notes,
            extendDays: 1,
            status: item.status,
            selected_delivery_time: item.delivery_time,

        })))
    }, [subscriptions])

    const handleClickOpen = (value: string, id: string) => {
        setCheckoutNote(value);
        setSelectedSubscription(id);
        setOpen(true);
    };



    const handleClose = () => setOpen(false);


    const handleExtendSubmit = async (sub_id: string, days: number) => {
        try {
            const data = await updateCustomerExtendSubscription(sub_id, days);
            const updatedRows = rows.map((item: any) => {
                if (item.id === data.id) {
                    return { ...item, endDate: data.end_date };
                }
                return item;
            });
            setRows(updatedRows);
            enqueueSnackbar('Subscription extended successfully', {
                variant: 'success'
            });
            setOpenExtend({ open: false, id: '' });
        } catch (err) {
            enqueueSnackbar(err?.detail || 'Something went wrong', {
                variant: 'error'
            });
            setOpenExtend({ open: false, id: '' });
        }
    }

    const handleSubmit = async () => {
        if (selectedSubscription) {
            loadingNotes.onTrue();
            const response = await updateCustomerCheckoutNote(selectedSubscription, checkoutNote || "");
            if (response.detail) {
                const updatedRows = rows.map((item: any) => {
                    if (item.id === selectedSubscription) {
                        return { ...item, checkoutNote };
                    }
                    return item;
                });
                setRows(updatedRows);
            }
            setOpen(false);
        }

        loadingNotes.onFalse();
    }


    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 220,
            renderCell: (params) => (

                ((user?.permissionIds || []).includes('45') || user?.type !== "Sub-User") ? (
                    <Link
                        component={ReactRouterLink}
                        underline="always"
                        color="text.primary"

                        to={paths.dashboard.selectedFood.root_tab(params.value)}
                    >
                        {`${params.value.slice(0, 23)} `}
                    </Link>) :
                    `${params.value.slice(0, 23)} `
            )
        },
        { field: 'restaurant', headerName: 'Restaurant', width: 200 },
        { field: 'plan', headerName: 'Plan', width: 200 },
        { field: 'period', type: 'number', headerName: 'Period', width: 120 },
        { field: 'offDays', headerName: 'Off Days', width: 100 },
        { field: 'price', type: 'number', headerName: 'Price', width: 120 },
        {
            field: 'status', headerName: 'Status', width: 120,
            renderCell: (params) => (
                <TableCell >

                    <Chip
                        label={params.value}
                        // eslint-disable-next-line no-nested-ternary
                        color={params.value === "old" ? "error" : params.value === "cancelled" ? "warning" : "success"}
                        size="small"
                    />
                </TableCell>
            )


        },

        { field: 'startDate', headerName: 'Start Date', width: 200 },
        { field: 'endDate', headerName: 'End Date', width: 200 },
        ((user?.permissionIds || []).includes('11') || user?.type !== "Sub-User") && {
            field: 'extendDays',
            headerName: 'Extend Date',
            width: 200,
            renderCell: (params) => params.row.status !== "old" && (
                <PermissionBasedGuard permissions={['11']}>
                    <Button
                        startIcon={<Iconify icon="eva:plus-circle-outline" />}
                        variant="contained"
                        onClick={() => {
                            setOpenExtend({
                                open: true,
                                id: `${params.id}`
                            });
                        }}
                    >
                        Extend
                    </Button>
                </PermissionBasedGuard>
            )
        },
        {
            field: 'checkoutNote',
            headerName: 'Note',
            width: 200,
            renderCell: (params) => (
                <TableCell
                    style={params.row.status === "old" ? {} : { cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => {
                        if (params.row.status === "old") return;

                        handleClickOpen(params.value, `${params.id}`)
                    }}
                >
                    {params.value || 'N/A'}
                </TableCell>
            )
        },
        {
            field: 'currentDeliveryTime',
            headerName: 'Delivery Time',
            width: 200,
            renderCell: (params) => (
                <TableCell>
                    {params.row.selected_delivery_time ?

                        `${dayjs(params.row.selected_delivery_time.start_time, 'HH:mm:ss').format('hh:mm A')} - ${dayjs(params.row.selected_delivery_time.end_time, 'HH:mm:ss').format('hh:mm A')}`


                        : "N/A"}
                </TableCell>
            )
        },
        ((user?.permissionIds || []).includes('21') || user?.type !== "Sub-User") && {
            field: 'selectDeliveryTime',
            headerName: 'Action',
            width: 120,
            renderCell: (params) => (
                <TableCell>
                    <PermissionBasedGuard permissions={['21']}>
                        {params.row.status === "active" &&
                            <Tooltip title="Update Delivery Time">
                                <IconButton onClick={() => fetchDeliveryTimes(params.row.restaurant_slug, params.row.selected_delivery_time, params.row.id)}>
                                    <AccessTimeIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    </PermissionBasedGuard>
                </TableCell>
            )
        }
    ]

    const showDefaultDeliveryTime = (id: number | null, deliveryTimeOption: { id: number, start_time: string, end_time: string } | null) => {

        if (id) {
            return id;
        }

        if (!deliveryTimeOption) {
            return '';
        }

        const response = deliveryTimes.find(time => time.start_time === deliveryTimeOption.start_time && time.end_time === deliveryTimeOption.end_time);

        if (response && response.id) {
            setSelectedDeliveryTime(response);
            return response.id;
        }

        return '';
    }


    return (
        <>
            <Typography variant="h5" sx={{ my: 3 }}>
                Customer Subscriptions
            </Typography>

            <DataGrid
                hideFooterPagination
                rows={rows}
                columns={columns}
                autoHeight
                hideFooterSelectedRowCount
            />

            <Dialog
                fullWidth
                open={openExtend.open}
                onClose={() => setOpenExtend({ open: false, id: '' })}
            >
                <DialogTitle>Extend Subscription</DialogTitle>

                <DialogContent sx={{ width: '100%', py: 3 }}>
                    {/* make a select for items 1,2,3,4,5,6,7 days */}
                    <DialogContentText sx={{ my: 4 }}>
                        Extend days for this subscription
                    </DialogContentText>
                    <Select
                        fullWidth
                        value={extendedDays}
                        onChange={(event) => setExtendedDays(event.target.value as number)}
                    >
                        <MenuItem value={1}>1 Day</MenuItem>
                        <MenuItem value={2}>2 Days</MenuItem>
                        <MenuItem value={3}>3 Days</MenuItem>
                        <MenuItem value={4}>4 Days</MenuItem>
                        <MenuItem value={5}>5 Days</MenuItem>
                        <MenuItem value={6}>6 Days</MenuItem>
                        <MenuItem value={7}>7 Days</MenuItem>
                    </Select>


                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => setOpenExtend({ open: false, id: '' })}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleExtendSubmit(openExtend.id, extendedDays)}
                    >
                        Extend
                    </Button>
                </DialogActions>




            </Dialog>

            <Dialog
                open={deliveryTimeDialogOpen}
                onClose={() => setDeliveryTimeDialogOpen(false)}
                fullWidth
            >
                <DialogTitle>Select Delivery Time</DialogTitle>
                <DialogContent>
                    {/* Use Select component here */}
                    <Select
                        fullWidth
                        value={showDefaultDeliveryTime(selectedDeliveryTime?.id || null, deliveryTimetoUpdate)}
                        onChange={(event) => {
                            const time = deliveryTimes.find(t => t.id === event.target.value);
                            setSelectedDeliveryTime(time || null);
                        }}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>Select delivery time</MenuItem>
                        {deliveryTimes.map((time) => (
                            <MenuItem key={time.id} value={time.id}>
                                {`Start: ${dayjs(time.start_time, 'HH:mm:ss').format('hh:mm A')} - End: ${dayjs(time.end_time, 'HH:mm:ss').format('hh:mm A')}`}
                            </MenuItem>
                        ))}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeliveryTimeDialogOpen(false)}>Cancel</Button>
                    <Button disabled={!selectedDeliveryTime} onClick={submitSelectedDeliveryTime}>Submit</Button>
                </DialogActions>
            </Dialog>
            <CommentModal
                open={open}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                commentValue={checkoutNote}
                onChange={handleCheckoutNote}
                loadingNotes={loadingNotes.value}
            />
        </>

    );
}
