import { Controller, useFormContext } from 'react-hook-form';

import TextField, { TextFieldProps } from '@mui/material/TextField';
import { convertArabicToWestern } from 'src/utils/arabic-to-western';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  textdirection?: 'ltr' | 'rtl';
};

export default function RHFTextField({ name, helperText, type, textdirection, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={/* type === 'number' && field.value === 0 ? '' : */ field.value}
          type={type === 'password' ? 'password' : undefined}
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(Number(convertArabicToWestern(`${event.target.value}`)));
            } else {
              field.onChange(convertArabicToWestern(event.target.value));
            }
          }}
          // disabled mouse wheel scroll for number input and also keyboard increment/decrement
          InputProps={{
            inputProps: {
              onWheel: (event) => {
                event.currentTarget.blur();
              },
              onKeyDown: (event) => {
                if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                  event.preventDefault();
                }
              },
            },
            style: { direction: textdirection || 'ltr' },
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}
