import { FieldErrors } from 'react-hook-form';
import { paths } from 'src/routes/paths';
import axios, { endpoints } from 'src/utils/axios';

// ----------------------------------------------------------------------

function jwtDecode(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};


export const refreshTokenFunc = async () => {
  try {
    const refreshTokenValue = localStorage.getItem('refreshToken');

    if (!refreshTokenValue) {
      throw new Error("No refresh token found");
    }

    const response = await axios.post(endpoints.auth.refresh, {
      refresh: refreshTokenValue
    });

    const { access, refresh } = response.data;
    // Update session and axios headers
    // this access, refresh token will be used in the next request and they will have more time to expire
    setSession(access, refresh);

  } catch (error) {
    console.error("Failed to refresh token: ", error);

    // Handle error, for example, redirect to login page
  }
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const

  let expiredTimer: any; // Move this to a higher scope to manage it effectively


  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  console.log('Token will be expired in: ', timeLeft / 1000, 's')

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    // Instead of alerting and redirecting, refresh the token
    refreshTokenFunc().catch((error) => {
      console.error("Failed to refresh token after expiry: ", error);
      // Redirect to login or handle error appropriately
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      window.location.href = paths.auth.jwt.login;
    });
  }, timeLeft);
};


const scheduleTokenRefresh = (exp: number) => {

  let expiredTimer: any; // Move this to a higher scope to manage it effectively

  if (expiredTimer) {
    clearTimeout(expiredTimer); // Clear existing timer
  }

  const currentTime = Date.now() / 1000; // Current time in seconds
  const twoDaysInSeconds = 2 * 24 * 60 * 60; // 2 days in seconds
  const timeUntilRefresh = (exp - currentTime - twoDaysInSeconds) * 1000; // Time until refresh in milliseconds

  // Calculate the days and seconds left for a more readable log statement
  const days = Math.floor(timeUntilRefresh / (1000 * 60 * 60 * 24));
  console.log(`Time left until refresh: ${days} days `);

  if (timeUntilRefresh > 0) {
    console.log("No need to refresh yet");
  } else {
    // If less than 2 days are left or the token is already expired, refresh immediately
    console.log("Refreshing token immediately");
    refreshTokenFunc();
  }
};


// ----------------------------------------------------------------------

export const setSession = (accessToken: string | null, refreshToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~44 days by eshterakat server

    scheduleTokenRefresh(exp);

  } else {
    localStorage.removeItem('accessToken');

    delete axios.defaults.headers.common.Authorization;
  }


  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  }

};



export function scrollToFirstError(errors: FieldErrors<any>) {
  // Find the first error field's name
  const firstErrorField = Object.keys(errors)[0];

  if (!firstErrorField) return;

  // Find the DOM element
  const errorElement = document.querySelector(`[name="${firstErrorField}"]`) as HTMLElement;

  if (errorElement) {
    // Scroll to the element
    errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });

    // Set focus on the element
    errorElement.focus();
  }
}


