import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';


import { useBoolean } from 'src/hooks/use-boolean';


import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { ConfirmDialog } from 'src/components/custom-dialog';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {
  getComparator,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  TableSkeleton,
  useTable,
} from 'src/components/table';

import { ISubUserResult, ISubUserTableFilters, IUserTableFilterValue } from 'src/types/user';

import { useNavigate } from 'react-router';
import { useGetAccountAllSubUsers } from 'src/api/account';
import { RoleBasedGuard } from 'src/auth/guard';
import { paths } from 'src/routes/paths';
import UserTableFiltersResult from '../customer-table-filters-result';
import UserTableToolbar from '../customer-table-toolbar';
import SubUserTableRow from '../sub-user-table-row';

// ----------------------------------------------------------------------


const TABLE_HEAD = [
  { id: 'phoneNumber', label: 'Phone', width: 180 },
  { id: 'name', label: 'Name', width: 220 },
  { id: 'role', label: 'Role', width: 200 },
  { id: 'date_created', label: 'Created At', width: 200 },
  { id: 'date_updated', label: 'Updated At', width: 200 },
];

const defaultFilters: ISubUserTableFilters = {
  name: '',
  phone_number: ''
};

// ----------------------------------------------------------------------

export default function SubUserListView() {
  const table = useTable({
    defaultRowsPerPage: 25
  });
  const navigate = useNavigate();
  const confirm = useBoolean();
  const [filters, setFilters] = useState(defaultFilters);

  const { users, usersLoading } = useGetAccountAllSubUsers(table.page + 1, table.rowsPerPage, filters.name);
  const [tableData, setTableData] = useState(users?.results);
  const dataFiltered = applyFilter({
    inputData: tableData as ISubUserResult[],
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    0,
    table.rowsPerPage
  );

  useEffect(() => {
    if (users?.results && !usersLoading) {
      setTableData(users?.results);
    }
  }, [users?.results, usersLoading]);

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback((name: string, value: IUserTableFilterValue) => {
    const newFilters = { ...filters, [name]: value };
    setFilters(newFilters);
    table.setPage(0); // Reset pagination to the first page

    // Save the updated filters and pagination state to Local Storage
    localStorage.setItem('subuserFilters', JSON.stringify(newFilters));
    localStorage.setItem('subuserPagination', JSON.stringify({ page: 0, rowsPerPage: table.rowsPerPage }));
  }, [filters, table]);


  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem('subuserFilters') || '{}');
    const savedPagination = JSON.parse(localStorage.getItem('subuserPagination') || '{}');

    if (Object.keys(savedFilters).length > 0) {
      setFilters(savedFilters);
    }

    if (savedPagination.page !== undefined && savedPagination.rowsPerPage !== undefined) {
      table.setPage(savedPagination.page);
      table.setRowsPerPage(savedPagination.rowsPerPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = useCallback((event: any, newPage: number) => {
    table.setPage(newPage);
    const newPagination = { page: newPage, rowsPerPage: table.rowsPerPage };
    localStorage.setItem('subuserPagination', JSON.stringify(newPagination));
  }, [table]);

  const handleRowsPerPageChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    table.setRowsPerPage(newRowsPerPage);
    const newPagination = { page: table.page, rowsPerPage: newRowsPerPage };
    localStorage.setItem('subuserPagination', JSON.stringify(newPagination));
  }, [table]);



  const handleDeleteRows = useCallback(() => {
    const deleteRows = tableData.filter((row) => !table.selected.includes(row.id.toString()));
    setTableData(deleteRows);

    table.onUpdatePageDeleteRows({
      totalRows: users?.count || 0,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    });
  }, [dataFiltered.length, dataInPage.length, table, tableData, users?.count]);




  const handleEditRow = useCallback(
    (row: any) => {
      if (row) {
        navigate(paths.dashboard.sub_users.profile(row.id));
      }
    },
    [navigate]
  );



  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  return (
    <RoleBasedGuard hasContent roles={["Superuser", "Subadmin"]} sx={{ py: 10 }}>
      <Container maxWidth={false}>


        <CustomBreadcrumbs
          heading='Sub Users'
          links={[
            {}
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
          action={
            <Button
              variant="contained"
              onClick={() => navigate(paths.dashboard.sub_users.create)}
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              New Sub User
            </Button>
          }
        />

        <Card>



          <UserTableToolbar
            filters={filters}
            onFilters={handleFilters}
            roleOptions={[]}
          />

          {canReset && (
            <UserTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              onResetFilters={handleResetFilters}
              results={dataFiltered.length}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id.toString())
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                />
                <TableBody>
                  {usersLoading
                    ?
                    (
                      [...Array(table.rowsPerPage)]?.map((i, index) => (
                        <TableSkeleton key={index} sx={{ height: denseHeight }} />
                      ))
                    )
                    :
                    dataFiltered
                      .slice(
                        0,
                        table.rowsPerPage
                      )?.map((row) => (
                        <SubUserTableRow
                          key={row.id}
                          row={row}
                          id={row.id}
                          selected={table.selected.includes(`${row.id}`)}
                          onEditRow={() => handleEditRow(row)}
                        />
                      ))}

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={users?.count || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}

            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </RoleBasedGuard>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
}: {
  inputData: ISubUserResult[];
  comparator: (a: any, b: any) => number;
  filters: ISubUserTableFilters;
}) {

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);




  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);


  return inputData;
}
