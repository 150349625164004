import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { IPerformanceSummarySubadminResult } from 'src/types/performance';


type Props = {
  row: IPerformanceSummarySubadminResult;
  handleClickNavigate?: () => void;
};

export default function PerformanceTableSubadminRow({
  row,
  handleClickNavigate,
}: Props) {

  return (
    <TableRow hover>

      <TableCell
        sx={{ whiteSpace: 'nowrap', textDecoration: 'underline', cursor: 'pointer' }}
        onClick={handleClickNavigate}

      >
        {moment(row.start_date).format("DD MMM")} - {moment(row.end_date).format("DD MMM")}
      </TableCell>




      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {row?.plans?.length ? row?.plans?.map((plan, index) => (
          <TableRow sx={{ py: 2 }} key={plan.id}>
            {plan?.plan_title}
          </TableRow>
        )) : "-"}
      </TableCell>



      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {
          row?.plans?.length ? row?.plans?.map((plan) =>
            <TableRow sx={{ py: 2 }} key={plan.id}>
              {plan?.packages?.reduce((a, b) => a + Number(b.subscriptions), 0)}
            </TableRow>
          ) : "-"
        }
      </TableCell>


      {/* <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {
          row?.plans?.length ? row?.plans?.map((plan) =>
            <TableRow sx={{ py: 2 }} key={plan.id}>
              {plan?.packages?.reduce((a, b) => a + Number(b.price), 0)}
            </TableRow>
          ) : "-"
        }
      </TableCell> */}


      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {
          row?.plans?.length ? row?.plans?.map((plan) =>
            <TableRow sx={{ py: 2 }} key={plan.id}>

              {plan?.packages?.reduce((a, b) => a + Number(b.sales), 0)}

            </TableRow>
          ) : "-"
        }
      </TableCell>


      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {
          row?.plans?.length ?
            row?.plans?.map((plan) =>
              <TableRow sx={{ py: 2 }} key={plan.id}>

                {plan?.packages?.reduce((a, b) => a + Number(b.income), 0)}
              </TableRow>
            ) : "-"
        }
      </TableCell>



      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {row.total_sales || '-'}
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {row.total_income || '-'}
      </TableCell>

    </TableRow>
  );
}
