import { Button, Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { useGetCustomer } from 'src/api/customer';
import Iconify from 'src/components/iconify/iconify';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import CustomerAccountGeneral from 'src/sections/user/customer-account-general';
import { SubsriptionsTable } from 'src/sections/user/subscriptions-table';


export default function CustomerProfile() {

    const params = useParams();

    const { customer, customerLoading } = useGetCustomer(params.id as string);


    return (
        <Container maxWidth={false}>
            <Helmet>
                <title> Dashboard: Restaurant Profile</title>
            </Helmet>

            <Button
                size='small'
                variant='soft'
                component={RouterLink}
                href={paths.dashboard.customers.list}
                startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
            >
                Back to Customers List
            </Button>


            {
                customerLoading ? <Typography variant="h5" sx={{ my: 3 }}>
                    Loading...
                </Typography>

                    :
                    <>
                        <CustomerAccountGeneral user_profile={customer} />
                        <SubsriptionsTable subscriptions={customer.subscriptions} />
                    </>
            }

        </Container>
    );
}
