import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { IPerformanceSummaryResult } from 'src/types/performance';


type Props = {
  row: IPerformanceSummaryResult;
  handleClickNavigate?: () => void;
};

export default function PerformanceTableRow({
  row,
  handleClickNavigate,
}: Props) {

  return (
    <TableRow hover>

      <TableCell
        sx={{
          whiteSpace: 'nowrap',
          textDecoration: 'underline',
          cursor: 'pointer'
        }}
        onClick={handleClickNavigate}

      >
        {moment(row.start_date).format("DD MMM")} - {moment(row.end_date).format("DD MMM")}
      </TableCell>


      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {row?.restaurants?.map((restaurant, index) => (
          <TableRow key={index}>
            {restaurant?.restaurant}
          </TableRow>
        ))}
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {
          row?.restaurants?.map((restaurant, index) => (
            <TableRow key={index}>
              {restaurant?.summary?.sales}
            </TableRow>
          ))
        }
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {
          row?.restaurants?.map((restaurant, index) => (
            <TableRow key={index}>
              {restaurant?.summary?.income}
            </TableRow>
          ))
        }
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {
          row.restaurants?.map((restaurant, index) => (
            <TableRow key={index}>
              {restaurant?.summary?.commission}
            </TableRow>
          ))
        }
      </TableCell>


      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {
          `${row.restaurants?.reduce((a, b) => a + Number(b.summary.sales), 0).toFixed(3)}`
        }
      </TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {
          `${row.restaurants?.reduce((a, b) => a + Number(b.summary.income), 0).toFixed(3)}`
        }
      </TableCell>


      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {
          `${row.restaurants?.reduce((a, b) => a + Number(b.summary.commission), 0).toFixed(3)}`
        }
      </TableCell>


    </TableRow>
  );
}
