
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import * as Yup from 'yup';


import { InputAdornment, MenuItem, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { updateUserDetails } from 'src/api/account';
import { scrollToFirstError } from 'src/auth/context/jwt/utils';
import { useAuthContext } from 'src/auth/hooks';
import { RHFAutocomplete, RHFSelect, RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import Iconify from 'src/components/iconify';
import { PHONE_REGEX_EXPRESSION } from 'src/config-global';
import { ICityResponse } from 'src/types/user';

// ----------------------------------------------------------------------

const phoneRegExp = PHONE_REGEX_EXPRESSION

export default function UserAccountGeneral(
    { user_profile, cities, citiesLoading }
        :
        {
            user_profile: any | undefined,
            cities: ICityResponse[],
            citiesLoading: boolean
        }) {
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useAuthContext();



    const UpdateRestaurantSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        phone_number: Yup.string().required('Contact number is required')
            .matches(phoneRegExp, 'Invalid phone number')
            .min(8, 'Phone number is too short')
            .max(8, 'Phone number is too long'),
        status: Yup.string().required('Status is required'),
        userId: Yup.string().required('User Id is required'),
        house: Yup.string().required('House is required'),
        street: Yup.string().required('Street is required').max(20, 'Street is too long'),
        area: Yup.number().integer().required('Area is required'),
        block: Yup.string().required('Block is required'),
        avenue: Yup.string().default('').max(20, 'Avenue is too long'),
    });

    const defaultValues = useMemo(() => ({
        name: user_profile.name || '',
        phone_number: user_profile.phone_number && user_profile.phone_number.slice(4) || '',
        status: user_profile.status || 'unverified',
        userId: user_profile.id || '',
        house: user_profile?.personal_detail?.house || '',
        street: user_profile?.personal_detail?.street || '',
        area: user_profile?.personal_detail?.area || 0,
        block: user_profile?.personal_detail?.block || '',
        avenue: (user_profile?.personal_detail?.avenue) || '',
    }), [user_profile])


    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(UpdateRestaurantSchema),
        defaultValues
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        methods.reset(defaultValues);
    }, [defaultValues, methods]);



    const onSubmit = handleSubmit(async (data) => {
        try {

            const form_data = {
                name: data.name,
                phone_number: `+965${data.phone_number}`,
                status: data.status,
                personal_detail: {
                    house: data.house,
                    street: data.street,
                    area: data.area,
                    block: data.block,
                    avenue: data.avenue,
                }
            };

            const response = await updateUserDetails(user_profile.id, form_data);
            if (response) {
                enqueueSnackbar('User details updated successfully', { variant: 'success' });
            }
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    }, (e) => {
        scrollToFirstError(e);
    });



    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>

            <Grid container mt={3} spacing={3}>

                <Grid xs={12} md={12}>
                    <Card sx={{ p: 3 }}>
                        <Typography variant="h5" sx={{ my: 3 }}>General Details</Typography>

                        <Box
                            rowGap={3}
                            columnGap={2}
                            display="grid"
                            gridTemplateColumns={{
                                xs: 'repeat(1, 1fr)',
                                sm: 'repeat(1, 1fr)',
                            }}
                        >
                            <RHFTextField name="name" label="Name" />

                            <RHFTextField
                                name="phone_number"
                                label="Phone Number"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Iconify icon="twemoji:flag-kuwait" sx={{ color: 'text.disabled' }} />
                                        </InputAdornment>
                                    ),
                                }}

                            />

                        </Box>


                        {user?.type === "Superuser" && <RHFSelect
                            name="status"
                            label="Status"
                            sx={{ mt: 3 }}
                        >
                            <MenuItem value="verified">Verified</MenuItem>
                            <MenuItem value="unverified">Unverified</MenuItem>
                            <MenuItem value="inactive">Inactive</MenuItem>
                            <MenuItem value="deleted">Deleted</MenuItem>
                        </RHFSelect>}


                        <Typography
                            variant="h5"
                            sx={{ my: 3 }}
                        >
                            Personal Details
                        </Typography>

                        {
                            user?.type === "Superuser"
                            &&
                            <RHFTextField disabled name="userId" label="User Id" sx={{ mt: 3 }} />

                        }

                        <RHFAutocomplete
                            loadingText="Loading Area Name..."
                            name="area"
                            label="Area"
                            options={cities?.map((city) => ({
                                id: city.id,
                                name: city.name
                            }))}
                            getOptionLabel={(option: any) =>
                                typeof option === "string" ? option : option?.name ?? ''}
                            sx={{ mt: 3 }}
                            isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                            loading={citiesLoading}
                            renderOption={(props: any, option: any) => (
                                <Box component="li" {...props}>
                                    <Typography variant="subtitle2">{option.name}</Typography>
                                </Box>
                            )}
                            onChange={(event: any, value: any) => {
                                // Update Formik state
                                methods.setValue('area',
                                    typeof value === 'string' ? value :
                                        (value as { id: number })?.id ?? null);
                            }}
                            value={cities?.map((city) => ({
                                id: city.id,
                                name: city.name
                            }))?.find((city) => city.id === methods.watch('area'))}
                        />

                        <Box
                            rowGap={3}
                            columnGap={2}
                            display="grid"
                            gridTemplateColumns={{
                                xs: 'repeat(4, 1fr)',
                                sm: 'repeat(4, 1fr)',
                            }}
                        >

                            <RHFTextField name="house" label="House" sx={{ mt: 3 }} />

                            <RHFTextField name="street" label="Street" sx={{ mt: 3 }} />


                            <RHFTextField name="block" label="Block" sx={{ mt: 3 }} />

                            <RHFTextField name="avenue" label="Avenue" sx={{ mt: 3 }} />

                        </Box>


                        <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>

                            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                Update User Details
                            </LoadingButton>
                        </Stack>
                    </Card>
                </Grid>

            </Grid>

        </FormProvider>
    );
}









