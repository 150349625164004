import { useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import useSWR from 'swr';

import {
  GroupedRoles,
  IRestaurant,
  IRestaurantCard,
  IRestaurantDetailCard,
  IRestaurantFoodCard,
  IRestaurantFoodCardResponse,
  IRestaurantPlanCard,
  IRestaurantPlanDetail,
  Meal,
} from 'src/types/user';
import axiosInstance, { endpoints, fetcher } from 'src/utils/axios';

// ----------------------------------------------------------------------

export function useGetRestaurants() {
  const URL = endpoints.restaurants.list;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    dedupingInterval: 300000,
  });

  const memoizedValue = useMemo(
    () => ({
      restaurants: (data as IRestaurantCard[]) || [],
      restaurantsLoading: isLoading,
      restaurantsError: error,
      restaurantsValidating: isValidating,
      restaurantsEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetSubuserPermissions() {
  const URL = endpoints.subusers.permissions;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      permissions: (data?.results as { id: string; name: string }[]) || [],
      permissionsLoading: isLoading,
      permissionsError: error,
      permissionsValidating: isValidating,
      permissionsEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetSubuserRoles() {
  const URL = endpoints.subusers.roles;

  const { data, isLoading, error } = useSWR(URL, fetcher, { dedupingInterval: 300000 });

  const memoizedValue = useMemo(
    () => ({
      roles: (data?.results as GroupedRoles[]) || [],
      rolesLoading: isLoading,
      rolesError: error,
    }),
    [data, error, isLoading]
  );

  return memoizedValue;
}

export function useGetMyRestaurants() {
  const URL = endpoints.restaurants.list_sub;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, { errorRetryCount: 2 });

  localStorage.setItem('restaurant_id', data?.[0]?.id || '');

  const memoizedValue = useMemo(
    () => ({
      restaurants: (data as IRestaurant[]) || [],
      restaurantsLoading: isLoading,
      restaurantsError: error,
      restaurantsValidating: isValidating,
      restaurantsEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetRestaurant(id: string | undefined) {
  const URL = id ? endpoints.restaurants.details(id) : '';

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      restaurant: (data as IRestaurantDetailCard) || [],
      restaurantLoading: isLoading,
      restaurantError: error,
      restaurantValidating: isValidating,
      restaurantEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetRestaurantPlans(id: string | undefined, search: string) {
  const URL = id ? endpoints.restaurants.plans(id) : '';

  const queryParams = new URLSearchParams();

  if (search && search.length > 0) {
    queryParams.set('search', search);
  }

  const URL_WITH_PARAMS = `${URL}?${queryParams.toString()}`;

  const { data, isLoading, error, isValidating } = useSWR(URL_WITH_PARAMS, fetcher);

  const memoizedValue = useMemo(
    () => ({
      restaurantPlans: (data as IRestaurantPlanCard[]) || [],
      restaurantPlansLoading: isLoading as boolean,
      restaurantPlansError: error,
      restaurantPlansValidating: isValidating,
      restaurantPlansEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetRestaurantPlan(id: string | undefined) {
  const URL = id ? endpoints.restaurants.plan(id) : '';

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      restaurantPlan: (data as IRestaurantPlanDetail) || [],
      restaurantPlanLoading: isLoading,
      restaurantPlanError: error,
      restaurantPlanValidating: isValidating,
      restaurantPlanEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetRestaurantFoods(
  id: string | undefined,
  page: number,
  page_size: number,
  search: string
) {
  const URL = id ? endpoints.restaurants.foods(id) : '';

  const queryParams = new URLSearchParams();

  if (search && search.length > 0) {
    queryParams.set('search', search);
  }

  if (page) {
    queryParams.set('page', `${page}`);
  }

  if (page_size) {
    queryParams.set('page_size', `${page_size}`);
  }

  const URL_WITH_PARAMS = `${URL}?${queryParams.toString()}`;

  const { data, isLoading, error, isValidating } = useSWR(URL_WITH_PARAMS, fetcher);

  const memoizedValue = useMemo(
    () => ({
      restaurantFoods: data as IRestaurantFoodCardResponse,
      restaurantFoodsLoading: isLoading,
      restaurantFoodsError: error,
      restaurantFoodsValidating: isValidating,
      restaurantFoodsEmpty: !isLoading && !data?.results.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetRestaurantFood(id: string | undefined) {
  const URL = id ? endpoints.restaurants.food(id) : '';

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      restaurantFood: (data as IRestaurantFoodCard) || [],
      restaurantFoodLoading: isLoading,
      restaurantFoodError: error,
      restaurantFoodValidating: isValidating,
      restaurantFoodEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetRestaurantMeals(id: string | undefined) {
  const URL = id ? endpoints.restaurants.meals(id) : '';
  const INGREDIENT_URL = endpoints.foods.ingredientsList;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  const { data: ingredients, isLoading: ingredientsLoading } = useSWR(INGREDIENT_URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      ingredients: {
        carb_categories: ingredients?.carb_categories || [],
        protein_categories: ingredients?.protein_categories || [],
        ingredientsLoading,
      },
      restaurantMeals: (data as Meal[]) || [],
      mealsLoading: isLoading,
      mealsError: error,
      mealsValidating: isValidating,
      mealsEmpty: !isLoading && !data?.length,
    }),
    [
      data,
      error,
      ingredients?.carb_categories,
      ingredients?.protein_categories,
      ingredientsLoading,
      isLoading,
      isValidating,
    ]
  );

  return memoizedValue;
}

export async function uploadRestaurantImage(id: string | undefined, image_files: any) {
  const URL = endpoints.restaurants.image_restaurant;
  const formData = new FormData();
  if (image_files.logo) {
    formData.append('logo', image_files.logo);
  }
  if (image_files.cover) {
    formData.append('cover', image_files.cover);
  }
  formData.append('id', id || '');
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  const response = await axiosInstance.post(URL, formData, config);
  const response_data = response.data;
  return response_data;
}

export async function uploadFoodImage(id: string, image_file: any) {
  const URL = endpoints.restaurants.image_food;

  const formData = new FormData();
  formData.append('photo', image_file || '');
  formData.append('id', id);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const data = await axiosInstance.post(URL, formData, config);
  return data;
}

export async function updateRestaurantData(data: any) {
  const URL = endpoints.restaurants.update;
  const response = await axiosInstance.post(URL, data);
  if (response.status >= 200 && response.status < 300) {
    const response_data = response.data;
    return response_data;
  }
  throw new Error('Error updating restaurant data');
}

export async function handleDuplicatePlan(id: string) {
  const URL = endpoints.restaurants.plan_duplicate;
  const response = await axiosInstance.post(URL, { plan_to_duplicate: Number(id) });
  if (response.status >= 200 && response.status < 300) {
    const response_data = response.data;
    return response_data;
  }
  throw new Error('Error duplicating plan data');
}

export async function updateRestaurantPassword(data: any) {
  const URL = endpoints.restaurants.update_password;
  const response = await axiosInstance.post(URL, data);

  if (response.status >= 200 && response.status < 300) {
    const response_data = response.data;
    return response_data;
  }

  if (response) {
    throw new Error(response.data);
  }

  throw new Error('Error changing password');
}

export async function deleteRestaurant(id: string) {
  const URL = endpoints.restaurants.delete(id);
  const response = await axiosInstance.delete(URL);
  if (response.status >= 200 && response.status < 300) {
    const response_data = response.data;
    return response_data;
  }
  throw new Error('Error deleting restaurant data');
}

export async function createNewRestaurant(data: any) {
  const URL = endpoints.restaurants.create;

  const response = await axiosInstance.post(URL, data);

  if (response.status >= 200 && response.status < 300) {
    const response_data = response.data;
    return response_data;
  }

  throw new Error('Error creating restaurant data');
}

export async function createNewRestaurantPlan(data: any) {
  const URL = endpoints.restaurants.plan_add;

  const response = await axiosInstance.post(URL, data);

  if (response.status >= 200 && response.status < 300) {
    const response_data = response.data;
    return response_data;
  }

  throw new Error('Error creating new plan');
}

export async function updateRestaurantPlan(data: any) {
  const URL = endpoints.restaurants.plan_update(data.id);
  const response = await axiosInstance.post(URL, data);
  if (response.status >= 200 && response.status < 300) {
    const response_data = response.data;
    return response_data;
  }
  throw new Error('Error creating new plan');
}

export async function deleteRestaurantPlan(id: string) {
  const URL = endpoints.restaurants.plan_delete(id);

  const response = await axiosInstance.delete(URL);
  if (response.status >= 200 && response.status < 300) {
    const response_data = response.data;
    return response_data;
  }

  throw new Error('Error deleting plan');
}

export async function createNewRestaurantFood(data: any) {
  const URL = endpoints.restaurants.food_create;

  const response = await axiosInstance.post(URL, data);
  if (response.status >= 200 && response.status < 300) {
    const response_data = response.data;
    return response_data;
  }

  throw new Error('Error creating new food');
}

export async function updateRestaurantFood(data: any) {
  const URL = endpoints.restaurants.food_update(data.id);

  const response = await axiosInstance.put(URL, data);
  if (response.status >= 200 && response.status < 300) {
    const response_data = response.data;
    return response_data;
  }

  throw new Error('Error updating food');
}

export async function deleteRestaurantFood(id: string) {
  const URL = endpoints.restaurants.food_delete(id);
  try {
    const response = await axiosInstance.delete(URL);
    if (response.status >= 200 && response.status < 300) {
      const response_data = response.data;
      return response_data;
    }
    throw new Error('Error deleting food');
  } catch (error) {
    console.log('Error deleting food', error);
    throw error;
  }
}

export async function getRestaurantDeliveryTime(restaurant_slug: string) {
  try {
    const URL = endpoints.restaurants.delievery_time(restaurant_slug);
    const res = await axiosInstance.get(URL);
    return res.data;
  } catch (error) {
    console.error('Failed to fetch delivery times:', error);
    throw error;
  }
}

export async function updateRestaurantDeliveryTime(
  delivery_time_id: number,
  data: { start_time: string; end_time: string }
) {
  try {
    const URL = endpoints.restaurants.select_delivery_time(delivery_time_id.toString());
    const res = await axiosInstance.put(URL, data);
    return res;
  } catch (error) {
    // Handle error here. Could be due to invalid data, server issues, etc.
    console.error('Failed to update delivery time:', error);
    throw error;
  }
}

export async function createRestaurantDeliveryTime(
  subscription_id: string,
  data: { start_time: string; end_time: string; original_delivery_time: number }
) {
  try {
    const URL = endpoints.restaurants.select_create_delivery_time(subscription_id);
    const res = await axiosInstance.post(URL, data);
    return res;
  } catch (error) {
    // Handle error here. Could be due to invalid data, server issues, etc.
    console.error('Failed to update delivery time:', error);
    throw error;
  }
}

export async function updateRestaurantDeliveryTimeSubadmin(
  restaurant_slug: string,
  data: { id?: number; start_time: string; end_time: string }[]
) {
  try {
    const URL = endpoints.restaurants.delievery_time(restaurant_slug.toString());
    const res = await axiosInstance.post(URL, data);
    return res;
  } catch (error) {
    // Handle error here. Could be due to invalid data, server issues, etc.
    console.error('Failed to post delivery time:', error);
    throw error;
  }
}
