import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { GuestGuard } from 'src/auth/guard';
import AuthClassicLayout from 'src/layouts/auth/classic';

import ErrorBoundary from 'src/components/error/ErrorBoundary';
import { SplashScreen } from 'src/components/loading-screen';
import JwtLoginPage from 'src/pages/auth/jwt/login';
// ----------------------------------------------------------------------

// JWT
// ----------------------------------------------------------------------

const authJwt = {
  path: 'jwt',
  element: (
    <GuestGuard>
      <ErrorBoundary>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </ErrorBoundary>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    }
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authJwt],
  },
];
