import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'overview',
        items: [
          {
            title: 'Selected Food',
            path: paths.dashboard.foods,
            icon: <Iconify icon="ep:select" />,
            roles: ['Superuser', 'Subadmin', 'Sub-User'], // kitchen
            permissions: ['45'],
          },
          {
            title: 'Restaurants',
            path: paths.dashboard.restaurants,
            icon: ICONS.dashboard,
            roles: ['Superuser'],
          },
          {
            title: 'Restaurant Info',
            path: paths.dashboard.profile,
            icon: ICONS.user,
            roles: ['Subadmin', 'Sub-User'],
            permissions: [],
          },
          {
            title: 'Plans',
            path: paths.dashboard.restaurant.plan.plans,
            icon: <Iconify icon="charm:package" />,
            roles: ['Subadmin', 'Sub-User'], // Content Creator
            permissions: ['44'],
          },
          {
            title: 'Foods',
            path: paths.dashboard.restaurant.food.foods,
            icon: <Iconify icon="dashicons:food" />,
            roles: ['Subadmin', 'Sub-User'],
            permissions: ['44'],

            // Content Creator
          },
          {
            title: 'Customers',
            path: paths.dashboard.customers.list,
            icon: <Iconify icon="streamline:information-desk-customer" />,
            roles: ['Superuser', 'Subadmin', 'Sub-User'],
            permissions: ['41'],

            // Customer Support
          },
          {
            title: 'Users',
            path: paths.dashboard.users.list,
            icon: <Iconify icon="streamline:information-desk-customer" />,
            roles: ['Superuser'],
          },
          {
            title: 'Sub Users',
            path: paths.dashboard.sub_users.list,
            icon: <Iconify icon="raphael:user" />,
            roles: ['Superuser', 'Subadmin'],
          },
        ],
      },
      {
        subheader: 'Management',
        icon: ICONS.analytics,
        items: [
          {
            title: 'Transactions',
            path: paths.dashboard.transactions,
            icon: ICONS.banking,
            roles: ['Superuser'],
          },
          {
            title: 'Orders',
            path: paths.dashboard.orders,
            icon: ICONS.order,
            roles: ['Superuser', 'Subadmin', 'Sub-User'],
            permissions: ['42'],
            // Deliveries
          },
          {
            title: 'Graphics',
            path: paths.dashboard.performance.graphics,
            icon: ICONS.analytics,
            roles: ['Superuser', 'Subadmin', 'Sub-User'],
            permissions: ['43'],
            // Performance
          },
          {
            title: 'Summary',
            path: paths.dashboard.performance.summary,
            icon: <Iconify icon="grommet-icons:document-performance" />,
            roles: ['Superuser', 'Subadmin', 'Sub-User'],
            permissions: ['47'],
            // Performance
          },

          {
            title: 'Subscriptions',
            path: paths.dashboard.performance.details_subadmin,
            icon: <Iconify icon="grommet-icons:document-performance" />,
            roles: ['Subadmin', 'Sub-User', 'Superuser'],
            permissions: ['46'],
            // Performance
          },
        ],
      },
    ],
    []
  );

  return data;
}
