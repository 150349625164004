import { useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import useSWR from 'swr';

import { ICityResponse, ICustomerAdminResponse, ISubUserResponse, IUserResponse, SubUserResponseObject } from 'src/types/user';
import axiosInstance, { endpoints, fetcher } from 'src/utils/axios';


// ----------------------------------------------------------------------

// Super Admin Users
// useGetAccountAllUsers with URLSearchParams for query parameters
export function useGetAccountAllUsers(page: number, page_size: number, ordering: "asc" | "desc", search: string | undefined, type: string | undefined) {
  const allowedValuesType = ["verified", "unverified"];

  const URL = endpoints.users.list;
  const queryParams = new URLSearchParams({
    page: page.toString(),
    page_size: page_size.toString(),
    ordering: ordering === "asc" ? "created_at" : "-created_at",
  });

  if (type && allowedValuesType.includes(type)) {
    queryParams.set('type', type);
  }

  if (search && search.length > 0) {
    queryParams.set('search', search);
  }

  const URL_WITH_PARAMS = `${URL}?${queryParams.toString()}`;

  const { data, isLoading, error, isValidating } = useSWR(URL_WITH_PARAMS, fetcher);
  const memoizedValue = useMemo(
    () => ({
      users: (data as IUserResponse) || { results: [] },
      usersLoading: isLoading,
      usersError: error,
      usersValidating: isValidating,
      usersEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}



// Sub Admin Users


// Super Admin Users with URLSearchParams for query parameters
export function useGetAccountAllSubUsers(page: number, page_size: number, search: string | undefined) {
  const URL = endpoints.subusers.list;
  const queryParams = new URLSearchParams({
    page: page.toString(),
    page_size: page_size.toString(),
  });

  if (search && search.length > 0) {
    queryParams.set('q', search); // Assuming 'q' is the query parameter for search
  }

  const URL_WITH_PARAMS = `${URL}?${queryParams.toString()}`;

  const { data, isLoading, error, isValidating } = useSWR(URL_WITH_PARAMS, fetcher, { dedupingInterval: 30000 });
  const memoizedValue = useMemo(
    () => ({
      users: (data as ISubUserResponse) || { results: [] },
      usersLoading: isLoading,
      usersError: error,
      usersValidating: isValidating,
      usersEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}



export function useGetAllCities() {
  const URL = endpoints.cities;

  // const citiesFromLocalStorage = JSON.parse(localStorage.getItem('cities'));
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, { dedupingInterval: 3000000 });
  // localStorage.setItem('cities', JSON.stringify(data));

  // console.log(data)
  const memoizedValue = useMemo(
    () => ({
      cities: (data as ICityResponse[]),
      citiesLoading: isLoading,
      citiesError: error,
      citiesValidating: isValidating,
      citiesEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}


export function useGetUser(user_id: string) {
  const URL = endpoints.users.retrieve_user(user_id);
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);
  const memoizedValue = useMemo(
    () => ({
      user: (data as any) || { results: [], count: 0 },
      userLoading: isLoading,
      userError: error,
      userValidating: isValidating,
      userEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetSubUser(sub_user_id: string) {
  const URL = endpoints.subusers.retrieve_user(sub_user_id);
  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, { dedupingInterval: 30000 });
  const memoizedValue = useMemo(
    () => ({
      subuser: (data as SubUserResponseObject),
      subuserLoading: isLoading,
      subuserError: error,
      subuserValidating: isValidating,
      subuserEmpty: !isLoading && !data,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}



// Super User Sub Admin
export function useGetAccountCustomers() {
  const URL = endpoints.users.customer_admin;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      users: (data as ICustomerAdminResponse) || { results: [] },
      usersLoading: isLoading,
      usersError: error,
      usersValidating: isValidating,
      usersEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export async function updateUserDetails(userId: string, data: any) {
  const URL = endpoints.users.update(userId);
  const response = await axiosInstance.post(URL, data);
  if (response.status !== 200) throw new Error(response.statusText);
  return response.data;
}

export async function updateSubUserDetails(userId: number, data: any) {
  const URL = endpoints.subusers.update(`${userId}`);
  const response = await axiosInstance.put(URL, data);
  if (response.status !== 200) throw new Error(response.statusText);
  return response.data;
}

export async function createNewSubUser(data: any) {
  const URL = endpoints.subusers.create;
  const response = await axiosInstance.post(URL, data);
  if (response.status !== 201) throw new Error(response.statusText);
  return response.data;
}

export async function deleteSubUser(id: number) {
  const URL = endpoints.subusers.delete(id);
  const response = await axiosInstance.delete(URL);
  if (response.status !== 204) throw new Error(response.statusText);
  return response.data;
}