/* eslint-disable no-nested-ternary */
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';

import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';


import { useBoolean } from 'src/hooks/use-boolean';


import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  TableSkeleton,
  useTable,
} from 'src/components/table';

import { IUserTableFilters } from 'src/types/user';

import { useNavigate } from 'react-router';
import { useGetPerformanceSubadminList } from 'src/api/performance';
import { RoleBasedGuard } from 'src/auth/guard';
import { paths } from 'src/routes/paths';
import PerformanceTableSubadminRow from '../performance-table-subadmin-row';

// ----------------------------------------------------------------------


// const FILTER_OPTIONS = [
//   { value: 'all', label: 'All' },
//   // { value: 'old', label: 'Old' },
//   // { value: 'active', label: 'Active' },
// ];


const TABLE_HEAD = [
  { id: 'week', label: 'Week', width: 200 },
  { id: 'plan', label: 'Plan', width: 220 },
  { id: 'subscription', label: 'Subscriptions', width: 200 },
  // { id: 'price', label: 'Price', width: 200 },
  { id: 'sales', label: 'Sales', width: 200 },
  { id: 'income', label: 'Income', width: 200 },
  { id: 'total_sales', label: 'Total Sales', width: 200 },
  { id: 'total_income', label: 'Total Income', width: 200 },
];



const defaultFilters: IUserTableFilters = {
  name: undefined,
  status: 'all',
};

// ----------------------------------------------------------------------

export default function PerformanceSubadminListView() {
  const table = useTable({
    defaultRowsPerPage: 25
  });

  const navigate = useNavigate();
  const [filters] = useState(defaultFilters);
  const { performances, performancesLoading, performancesError } = useGetPerformanceSubadminList(table.page + 1, table.rowsPerPage);

  const confirm = useBoolean();

  const [tableData, setTableData] = useState(performances?.results || []);

  const dataFiltered = tableData || [];

  const denseHeight = table.dense ? 52 : 102;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;



  const onNavigate = (startDate: string, endDate: string) => {
    navigate(paths.dashboard.performance.details(startDate, endDate));
  }


  useEffect(() => {
    if (performances?.results && !performancesLoading) {
      setTableData(performances?.results);
    }
  }, [performances?.results, performancesLoading]);

  return (
    <RoleBasedGuard hasContent roles={["Subadmin", "Sub-User"]} sx={{ py: 10 }}>
      <Card>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected.length}
            rowCount={tableData.length}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked,
                tableData.map((row: any) => row.id.toString())
              )
            }
            action={
              <Tooltip title="Delete">
                <IconButton color="primary" onClick={confirm.onTrue}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            }
          />

          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={table.selected.length}
                onSort={table.onSort}

              />

              <TableBody>
                {performancesError ? <h6>Failed to load performance stats</h6> :
                  performancesLoading
                    ?
                    (
                      [...Array(table.rowsPerPage)].map((_i, index) => (
                        <TableSkeleton key={index} sx={{ height: denseHeight }} />
                      ))
                    )
                    :
                    dataFiltered
                      .slice(
                        0,
                        table.rowsPerPage
                      )
                      .map((row, index) => (
                        <PerformanceTableSubadminRow
                          key={index}
                          row={row}
                          handleClickNavigate={() => onNavigate(row.start_date, row.end_date)}
                        />
                      ))}
                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom

          count={performances?.count || 0}
          page={table.page}
          rowsPerPageOptions={[25, 50, 100]}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>
    </RoleBasedGuard>
  );
}

// ----------------------------------------------------------------------

